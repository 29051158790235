<template>
  <section id="main-container">
    <RouterView />
  </section>
</template>

<script>
export default{
  updated(){}
}
</script>
<style>
@import '/assets/css/style.css';
/* @import '/assets/css/animate.css';
@import '/assets/css/style.css'; */
</style>