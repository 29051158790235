<template>
    <header>
        <div class="header-content header-mobile">
            <div class="header-top container-fluid ">
                <div class="module container width-5">
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="section-title mb-0">
                            <button class="button button-text">
                                <h5 class="mb-0 d-flex">
                                    <div class="icon mr-3">
                                        <svg width="25" version="1.0" viewBox="0 0 470 342" xmlns="http://www.w3.org/2000/svg">
                                            <g transform="translate(0 342) scale(.1 -.1)">
                                                <path d="m1630 3407c-27-8-212-187-813-786-523-522-784-789-797-817-26-53-26-135 0-188 28-58 1551-1579 1602-1601 52-21 140-19 186 4 106 55 152 182 103 286-13 27-205 225-590 610l-571 570 3795 10 45 25c24 14 58 45 75 68 27 40 30 51 30 122s-3 82-30 122c-17 23-51 54-75 68l-45 25-3795 10 566 565c311 311 573 581 583 600 46 89 33 178-38 250-63 66-140 85-231 57z"></path>
                                            </g>
                                        </svg>
                                    </div> Price Alerts
                                </h5>
                            </button>
                        </div>
                        <ul class="he-to-ctrl">
                            <li>
                                <a class="ht-ct-icon" href="#">
                                    <svg width="18" version="1.0" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                                        <g transform="translate(0 512) scale(.1 -.1)">
                                            <path d="m3750 5104c-30-8-93-33-140-55l-85-41-1663-1662c-1782-1780-1734-1729-1801-1898-60-149-62-170-59-754l3-531 30-49c19-30 49-60 79-79l49-30 531-3c578-3 606-1 744 55 160 63 325 193 359 281 37 97 14 210-57 279-47 45-96 66-167 71-73 5-132-20-228-96-98-77-118-81-505-84l-335-3 2 340c2 371 3 382 63 475 16 25 628 643 1360 1375l1330 1330 765-765-1016-1017c-845-846-1019-1025-1029-1058-18-55-15-142 5-193 23-56 101-125 159-142 56-15 139-9 186 13 21 10 572 555 1356 1340 1237 1238 1323 1327 1358 1394 103 202 103 458-1 657-33 62-79 112-378 411-375 376-399 394-561 435-93 24-270 26-354 4zm265-517c43-23 572-557 584-590 17-44 13-114-8-155-11-20-60-77-110-127l-91-90-383 383-382 383 106 103c90 89 112 105 150 113 49 10 89 4 134-20z"></path>
                                            <path d="m2514 486c-46-20-102-74-127-120-31-58-30-174 0-232 28-52 95-111 143-125 23-6 435-9 1231-7l1196 3 49 30c30 19 60 49 79 79 27 45 30 57 30 136s-3 91-30 136c-19 30-49 60-79 79l-49 30-1206 2c-993 2-1211 0-1237-11z"></path>
                                        </g>
                                    </svg>
                                </a>
                            </li>
                            <li>
                                <a class="ht-ct-icon" data-popup href="#popup-create-price-alert">
                                    <svg width="18" version="1.0" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                                        <g transform="translate(0 512) scale(.1 -.1)">
                                            <path d="m2442 5095c-29-15-64-41-77-59-58-76-55-12-55-1166v-1060l-1073-2-1074-3-49-30c-30-19-60-49-79-79-27-45-30-57-30-136s3-91 30-136c19-30 49-60 79-79l49-30 1074-3 1073-2 2-1073 3-1074 30-49c19-30 49-60 79-79 45-27 57-30 136-30s91 3 136 30c30 19 60 49 79 79l30 49 3 1074 2 1073 1073 2 1074 3 49 30c30 19 60 49 79 79 27 45 30 57 30 136s-3 91-30 136c-19 30-49 60-79 79l-49 30-1074 3-1073 2-2 1073-3 1074-30 49c-46 74-109 108-204 112-66 3-83 0-129-23z" />
                                        </g>
                                    </svg>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <section id="main-wrapper" style="padding-top: 50px; padding-bottom: 124px;">
        <div class="container-fluid section-small">
            <div class="module container width-5">
                <ul class="row-list-mb15">
                    <li>
                        <div class="tr-bo-set set-list">
                            <div>
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="tb-se-title">
                                        <div class="icon"></div>
                                        <div class="section-par">
                                            <h6>Apple</h6>
                                        </div>
                                    </div>
                                    <div>
                                        <ul class="he-to-ctrl">
                                            <li>
                                                <a class="ht-ct-icon" href="#">
                                                    <svg width="23" version="1.0" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                                                        <g transform="translate(0 512) scale(.1 -.1)">
                                                            <path d="m2442 5095c-29-15-64-41-77-59-58-76-55-12-55-1166v-1060l-1073-2-1074-3-49-30c-30-19-60-49-79-79-27-45-30-57-30-136s3-91 30-136c19-30 49-60 79-79l49-30 1074-3 1073-2 2-1073 3-1074 30-49c19-30 49-60 79-79 45-27 57-30 136-30s91 3 136 30c30 19 60 49 79 79l30 49 3 1074 2 1073 1073 2 1074 3 49 30c30 19 60 49 79 79 27 45 30 57 30 136s-3 91-30 136c-19 30-49 60-79 79l-49 30-1074 3-1073 2-2 1073-3 1074-30 49c-46 74-109 108-204 112-66 3-83 0-129-23z" />
                                                        </g>
                                                    </svg>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <ul class="pr-al-list">
                                    <li>
                                        <div class="d-flex align-items-center justify-content-between">
                                            <div>
                                                <div class="tb-se-ctrl">
                                                    <div><a href="#" class="icon"><svg width="17" version="1.0" viewBox="0 0 384 470" xmlns="http://www.w3.org/2000/svg">
                                                                <g transform="translate(0 470) scale(.1 -.1)">
                                                                    <path d="m1840 4689c-45-19-90-60-112-102-19-36-23-63-28-186l-5-144-92-18c-507-103-935-487-1104-990-72-216-72-209-76-974l-4-690-194-195c-106-107-200-209-209-227-23-46-21-144 4-192 24-48 79-95 129-110 55-16 3487-16 3542 0 50 15 105 62 129 110 25 48 27 146 4 192-9 18-103 120-209 227l-194 195-4 690c-4 765-4 758-76 974-169 503-597 887-1104 990l-92 18-5 144c-5 130-8 148-30 189-14 24-44 58-68 74-36 25-54 31-110 33-37 2-78-2-92-8zm335-884c382-96 673-389 778-780 20-77 21-105 26-835 7-844 1-781 79-862l36-38h-1174-1174l36 38c78 81 72 18 79 862 5 730 6 758 26 835 56 208 163 390 314 529 154 143 372 246 581 276 104 15 282 3 393-25z" />
                                                                    <path d="m1490 396c0-57 37-159 80-219 169-235 531-235 700 0 43 60 80 162 80 219v34h-430-430v-34z" />
                                                                </g>
                                                            </svg></a></div>
                                                    <div class="pl-0 pr-0 section-par par-blue-horizon">
                                                        <p class="mb-0">Moves below</p>
                                                        <div class="tb-se-tag tag-green-2"><i class="fas fa-caret-up mr-1"></i> 19.0</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div class="tb-se-ctrl">
                                                    <div class="section-par par-blue-horizon text-right">
                                                        <p class="mb-0">Current Value</p>
                                                        <div class="tb-se-tag tag-blue-horizon-2">18.05</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="tr-bo-set set-list">
                            <div>
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="tb-se-title">
                                        <div class="icon"></div>
                                        <div class="section-par">
                                            <h6>Apple</h6>
                                        </div>
                                    </div>
                                    <div>
                                        <ul class="he-to-ctrl">
                                            <li><a class="ht-ct-icon" href="#"><svg width="23" version="1.0" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                                                        <g transform="translate(0 512) scale(.1 -.1)">
                                                            <path d="m2442 5095c-29-15-64-41-77-59-58-76-55-12-55-1166v-1060l-1073-2-1074-3-49-30c-30-19-60-49-79-79-27-45-30-57-30-136s3-91 30-136c19-30 49-60 79-79l49-30 1074-3 1073-2 2-1073 3-1074 30-49c19-30 49-60 79-79 45-27 57-30 136-30s91 3 136 30c30 19 60 49 79 79l30 49 3 1074 2 1073 1073 2 1074 3 49 30c30 19 60 49 79 79 27 45 30 57 30 136s-3 91-30 136c-19 30-49 60-79 79l-49 30-1074 3-1073 2-2 1073-3 1074-30 49c-46 74-109 108-204 112-66 3-83 0-129-23z" />
                                                        </g>
                                                    </svg></a> </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <ul class="pr-al-list">
                                    <li>
                                        <div class="d-flex align-items-center justify-content-between">
                                            <div>
                                                <div class="tb-se-ctrl">
                                                    <div><a href="#" class="icon"><svg width="17" version="1.0" viewBox="0 0 384 470" xmlns="http://www.w3.org/2000/svg">
                                                                <g transform="translate(0 470) scale(.1 -.1)">
                                                                    <path d="m1840 4689c-45-19-90-60-112-102-19-36-23-63-28-186l-5-144-92-18c-507-103-935-487-1104-990-72-216-72-209-76-974l-4-690-194-195c-106-107-200-209-209-227-23-46-21-144 4-192 24-48 79-95 129-110 55-16 3487-16 3542 0 50 15 105 62 129 110 25 48 27 146 4 192-9 18-103 120-209 227l-194 195-4 690c-4 765-4 758-76 974-169 503-597 887-1104 990l-92 18-5 144c-5 130-8 148-30 189-14 24-44 58-68 74-36 25-54 31-110 33-37 2-78-2-92-8zm335-884c382-96 673-389 778-780 20-77 21-105 26-835 7-844 1-781 79-862l36-38h-1174-1174l36 38c78 81 72 18 79 862 5 730 6 758 26 835 56 208 163 390 314 529 154 143 372 246 581 276 104 15 282 3 393-25z" />
                                                                    <path d="m1490 396c0-57 37-159 80-219 169-235 531-235 700 0 43 60 80 162 80 219v34h-430-430v-34z" />
                                                                </g>
                                                            </svg></a></div>
                                                    <div class="pl-0 pr-0 section-par par-blue-horizon">
                                                        <p class="mb-0">Moves below</p>
                                                        <div class="tb-se-tag tag-red-2"><i class="fas fa-caret-down mr-1"></i> 17.0</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div class="tb-se-ctrl">
                                                    <div class="section-par par-blue-horizon text-right">
                                                        <p class="mb-0">Current Value</p>
                                                        <div class="tb-se-tag tag-blue-horizon-2">18.05</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="d-flex align-items-center justify-content-between">
                                            <div>
                                                <div class="tb-se-ctrl">
                                                    <div><a href="#" class="icon"><svg width="17" version="1.0" viewBox="0 0 384 470" xmlns="http://www.w3.org/2000/svg">
                                                                <g transform="translate(0 470) scale(.1 -.1)">
                                                                    <path d="m1840 4689c-45-19-90-60-112-102-19-36-23-63-28-186l-5-144-92-18c-507-103-935-487-1104-990-72-216-72-209-76-974l-4-690-194-195c-106-107-200-209-209-227-23-46-21-144 4-192 24-48 79-95 129-110 55-16 3487-16 3542 0 50 15 105 62 129 110 25 48 27 146 4 192-9 18-103 120-209 227l-194 195-4 690c-4 765-4 758-76 974-169 503-597 887-1104 990l-92 18-5 144c-5 130-8 148-30 189-14 24-44 58-68 74-36 25-54 31-110 33-37 2-78-2-92-8zm335-884c382-96 673-389 778-780 20-77 21-105 26-835 7-844 1-781 79-862l36-38h-1174-1174l36 38c78 81 72 18 79 862 5 730 6 758 26 835 56 208 163 390 314 529 154 143 372 246 581 276 104 15 282 3 393-25z" />
                                                                    <path d="m1490 396c0-57 37-159 80-219 169-235 531-235 700 0 43 60 80 162 80 219v34h-430-430v-34z" />
                                                                </g>
                                                            </svg></a></div>
                                                    <div class="pl-0 pr-0 section-par par-blue-horizon">
                                                        <p class="mb-0">Moves below</p>
                                                        <div class="tb-se-tag tag-red-2"><i class="fas fa-caret-down mr-1"></i> 17.0</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div class="tb-se-ctrl">
                                                    <div class="section-par par-blue-horizon text-right">
                                                        <p class="mb-0">Current Value</p>
                                                        <div class="tb-se-tag tag-blue-horizon-2">18.05</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li>
                </ul>
                <div class="fc-su-box box-medium ml-0 mr-0">
                    <div class="section-title par-blue-horizon text-center mb-4">
                        <h6 class="mb-1">No Price Alert</h6>
                        <p>You have currently no active price alets.</p>
                    </div>
                    <div><a class="button button-quaternary button-wide button-purple" data-popup href="#popup-create-price-alert"><i class="fal fa-plus mr-2"></i> Create price Alert</a></div>
                </div>
            </div>
        </div>
        <div style="display: none;">
            <div id="popup-create-price-alert" class="popup-content container-fluid">
                <div class="container width-5">
                    <div class="section-title mb40">
                        <h6 class="fw-600">Create a New price alert</h6>
                    </div>
                    <div class="row-list-mb25">
                        <div class="form-group">
                            <div class="com_select-box select-box-v2">
                                <select name="" id="">
                                    <option value="default">Select Symbol</option>
                                    <option value="">Sample 1</option>
                                    <option value="">Sample 2</option>
                                    <option value="">Sample 3</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group">
                            <button class="button button-quaternary button-wide button-purple"><i class="fal fa-plus mr-2"></i> Create Alert</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>