<template>
    <section id="main-wrapper">
        <div class="container-fluid section-small">
            <div class="module container width-5">
                <div class="row-list-mb15">
                    <div class="se-co-box">
                        <ul class="sc-bo-list">
                            <li>
                                <div class="sc-bo-set">
                                    <div>Profile Photo</div>
                                    <button class="button button-text text-purple">Edit</button>
                                </div>
                            </li>
                            <li>
                                <div class="sc-bo-set justify-content-center">
                                    <div class="sp-ba-icon"><svg width="33" version="1.0" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                                            <g transform="translate(0 512) scale(.1 -.1)">
                                                <path d="m2320 5105c-166-28-298-73-450-151-384-199-661-562-762-998-30-131-37-402-13-551 51-329 238-671 479-875 64-54 72-70 36-70-10 0-74-16-142-35-542-155-1006-431-1234-734-109-146-183-317-219-502-22-118-22-655 1-742 53-206 225-378 430-431 56-14 273-16 2114-16 1832 0 2059 2 2113 16 208 54 377 223 431 431 23 87 23 611 1 739-43 246-172 485-355 658-142 134-366 283-569 379-98 46-108 49-171 45-127-7-196-86-188-212 6-103 31-130 185-208 217-110 363-209 474-319 71-71 111-130 159-232 64-138 74-206 75-492 0-237-1-252-21-290-25-46-45-65-94-91-34-18-100-19-2040-19s-2006 1-2040 19c-49 26-69 45-94 91-20 38-21 53-21 290 1 328 22 425 131 597 151 237 565 487 1024 618 286 82 514 116 875 130 272 11 369 21 487 51 557 140 973 577 1095 1149 26 121 26 459 0 580-78 367-278 681-574 904-172 129-426 236-643 271-115 18-374 18-480 0zm439-411c424-76 774-425 855-854 20-105 20-295 0-400-81-429-425-772-855-855-108-20-290-20-398 0-430 83-774 426-855 855-20 106-20 295 0 400 88 467 490 831 959 868 110 9 185 5 294-14z"></path>
                                            </g>
                                        </svg></div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="se-co-box">
                        <ul class="sc-bo-list">
                            <li>
                                <div class="sc-bo-set">
                                    <div>Cove Photo</div>
                                    <button class="button button-text text-purple">Edit</button>
                                </div>
                            </li>
                            <li>
                                <div class="sc-bo-banner">
                                    <div class="sb-ba-center">
                                        <!-- <?xml version="1.0" encoding="UTF-8"?> -->
                                        <svg width="50" version="1.0" viewBox="0 0 448 448" xmlns="http://www.w3.org/2000/svg">
                                            <g transform="translate(0 448) scale(.1 -.1)">
                                                <path d="m328 4461c-145-47-269-174-312-320-14-49-16-217-16-1582 0-1471 1-1529 19-1588 46-147 172-272 320-315 48-14 174-16 1085-16 1011 0 1032 0 1064 20 95 58 99 216 5 277-26 17-85 18-1038 23-996 5-1010 5-1045 26-48 28-78 75-85 137-3 29-5 696-3 1482 3 1409 3 1430 23 1464 26 44 62 70 111 81 52 12 2876 12 2928 0 49-11 85-37 111-81 20-33 20-57 25-1044 5-953 6-1012 23-1038 29-45 75-67 137-67 65 0 110 24 140 72 20 32 20 53 20 1065 0 984-1 1034-19 1092-46 147-172 272-320 315-49 14-217 16-1585 15-1457 0-1534-1-1588-18z" />
                                                <path d="m1313 3826c-101-33-190-113-241-216-25-50-27-65-27-170 0-107 2-119 29-175 38-77 114-153 191-191 56-27 68-29 175-29s119 2 175 29c77 38 153 114 191 191 27 56 29 68 29 175s-2 119-29 175c-38 77-114 153-191 190-52 26-72 30-160 32-64 2-115-2-142-11z" />
                                                <path d="m2346 3035c-31-11-137-111-473-447l-433-433-197 197c-167 166-205 198-240 207-79 21-113 4-246-127l-117-116v-518-518h1280 1280v518 517l-357 357c-302 300-364 358-398 367-49 13-51 13-99-4z" />
                                                <path d="m3592 1580c-18-11-41-34-52-52-19-31-20-51-20-300v-268h-267c-250 0-270-1-301-20-48-30-72-75-72-140s24-110 72-140c31-19 51-20 301-20h267v-267c0-250 1-270 20-301 30-48 75-72 140-72s110 24 140 72c19 31 20 51 20 301v267h268c249 0 269 1 300 20 48 30 72 75 72 140s-24 110-72 140c-31 19-51 20-300 20h-268v268c0 249-1 269-20 300-11 18-34 41-52 52-45 27-131 27-176 0z" />
                                            </g>
                                        </svg>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="se-co-box">
                        <ul class="sc-bo-list">
                            <li>
                                <div class="sc-bo-set">
                                    <div>User Name</div>
                                    <div class="sb-se-title">{{((profile.namef || '')+' '+(profile.namel || '')).trim()}}</div>
                                </div>
                            </li>
                            <li>
                                <div class="sc-bo-set align-items-start">
                                    <div>Email</div>
                                    <div class="text-right">
                                        <p class="mb-0 co-blue-horizon">{{store.user.email}}</p>
                                        <button class="button button-text text-purple">Want to change your email?</button>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="sc-bo-set align-items-start">
                                    <div>Phone Number</div>
                                    <div class="text-right">
                                        <p class="mb-0 co-blue-horizon">{{store.user.mobile_no}}</p>
                                        <div class="co-green-2">Verified <i class="fa fa-check-circle"></i></div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="se-co-box">
                        <ul class="sc-bo-list">
                            <li>
                                <div class="sc-bo-set">About Me</div>
                            </li>
                            <li>
                                <textarea class="sc-bo-input" name="" id="" cols="0" rows="0" placeholder="Write something about your self"></textarea>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { useMainStore } from '@/store/index'
export default{
    setup(){
        return { store: useMainStore() }
    },
    computed:{
        profile(){
            return (this.store.user.profile || {})
        }
    }
};
</script>