<template>
    <header>
        <div class="header-content header-mobile">
            <div class="header-top container-fluid ">
                <div class="module container width-5">
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="section-title mb-0">
                            <button class="button button-text">
                                <h5 class="d-flex">
                                    <div class="icon mr-3"><svg width="25" version="1.0" viewBox="0 0 470 342" xmlns="http://www.w3.org/2000/svg">
                                            <g transform="translate(0 342) scale(.1 -.1)">
                                                <path d="m1630 3407c-27-8-212-187-813-786-523-522-784-789-797-817-26-53-26-135 0-188 28-58 1551-1579 1602-1601 52-21 140-19 186 4 106 55 152 182 103 286-13 27-205 225-590 610l-571 570 3795 10 45 25c24 14 58 45 75 68 27 40 30 51 30 122s-3 82-30 122c-17 23-51 54-75 68l-45 25-3795 10 566 565c311 311 573 581 583 600 46 89 33 178-38 250-63 66-140 85-231 57z"></path>
                                            </g>
                                        </svg></div> Crypto Wallet
                                </h5>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="module container width-5">
                    <div class="he-mo-nav">
                        <div class="com_draggable">
                            <div class="com_draggable-main">
                                <ul class="pr-nav-list list-wide no-wrap">
                                    <li class="item active"><a href="#">Buy /Sell</a></li>
                                    <li class="item"><a href="#">Received</a></li>
                                    <li class="item"><router-link to="crypto-wallet-recent-transactions">Transaction History</router-link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <section id="main-wrapper" style="padding-top: 100px; padding-bottom: 67px;">
        <div class="container-fluid section-small">
            <div class="module container width-5">
                <div class="row-list-mb3">
                    <div>
                        <div class="section-title mb30">
                            <h3 class="co-blue-horizon">My Portfolio</h3>
                        </div>
                        <ul class="cr-wa-list">
                            <li>
                                <label>Total Balance</label>
                                <h4>$0.00</h4>
                            </li>
                            <li>
                                <label>Total Balance</label>
                                <h4>0.00%($0.00)</h4>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <ul class="tr-bo-list">
                            <li>
                                <div class="tr-bo-set">
                                    <div>
                                        <div class="tb-se-title">
                                            <div class="icon" style="background-image: url('/assets/img/icon_bitcoin.png');"></div>
                                            <div class="section-par">
                                                <h6 class="mb-0">Apple</h6>
                                                <div class="tb-se-tag mt-1 tag-line tag-red">-3.17%</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="text-right pr-2">
                                        <div class="section-par">
                                            <h6 class="mb-0 text-uppercase">0 BTC</h6>
                                            <p class="co-blue-horizon">0.00</p>
                                        </div>
                                    </div>
                                    <div class="set-auto">
                                        <div class="com_dropdown">
                                            <button class="button button-text text-blue-horizon cr-wa-more com_dropdown-title">
                                                <div class="far fa-ellipsis-v"></div>
                                            </button>
                                            <div class="com_dropdown-content cr-wa-sub">
                                                <ul class="cw-su-list">
                                                    <li>
                                                        <a href="#">
                                                            <div class="icon"><img src="/assets/img/icons/icon_buy_sell.png" alt=""></div>
                                                            <div class="section-par">
                                                                <p> Buy / Sell BCH <br>
                                                                    <span class="co-blue-horizon">Buy or sell crypto with your trading account</span>
                                                                </p>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <div class="icon"><img src="/assets/img/icons/icon_receive.png" alt=""></div>
                                                            <div class="section-par">
                                                                <p> Receive<br>
                                                                    <span class="co-blue-horizon">Receive crypto from another wallet</span>
                                                                </p>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <div class="icon"><img src="/assets/img/icons/icon_send_crypto.png" alt=""></div>
                                                            <div class="section-par">
                                                                <p> Send Crypto<br>
                                                                    <span class="co-blue-horizon">Send crypto to an address, email or NAGA User</span>
                                                                </p>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <div class="icon"><img src="/assets/img/icons/icon_send_exchange.png" alt=""></div>
                                                            <div class="section-par">
                                                                <p> Send to Exchange<br>
                                                                    <span class="co-blue-horizon">Send crypto to NAGA Exchange</span>
                                                                </p>
                                                            </div>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="tr-bo-set">
                                    <div>
                                        <div class="tb-se-title">
                                            <div class="icon" style="background-image: url('/assets/img/badge_ethusd.png');"></div>
                                            <div class="section-par">
                                                <h6 class="mb-0">Etherium</h6>
                                                <div class="tb-se-tag mt-1 tag-line">-3.17%</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="text-right pr-2">
                                        <div class="section-par">
                                            <h6 class="mb-0 text-uppercase">0 eth</h6>
                                            <p class="co-blue-horizon">0.00</p>
                                        </div>
                                    </div>
                                    <div class="set-auto">
                                        <div class="com_dropdown">
                                            <button class="button button-text text-blue-horizon cr-wa-more com_dropdown-title">
                                                <div class="far fa-ellipsis-v"></div>
                                            </button>
                                            <div class="com_dropdown-content cr-wa-sub">
                                                <ul class="cw-su-list">
                                                    <li>
                                                        <a href="#">
                                                            <div class="icon"><img src="/assets/img/icons/icon_buy_sell.png" alt=""></div>
                                                            <div class="section-par">
                                                                <p> Buy / Sell BCH <br>
                                                                    <span class="co-blue-horizon">Buy or sell crypto with your trading account</span>
                                                                </p>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <div class="icon"><img src="/assets/img/icons/icon_receive.png" alt=""></div>
                                                            <div class="section-par">
                                                                <p> Receive<br>
                                                                    <span class="co-blue-horizon">Receive crypto from another wallet</span>
                                                                </p>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <div class="icon"><img src="/assets/img/icons/icon_send_crypto.png" alt=""></div>
                                                            <div class="section-par">
                                                                <p> Send Crypto<br>
                                                                    <span class="co-blue-horizon">Send crypto to an address, email or NAGA User</span>
                                                                </p>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <div class="icon"><img src="/assets/img/icons/icon_send_exchange.png" alt=""></div>
                                                            <div class="section-par">
                                                                <p> Send to Exchange<br>
                                                                    <span class="co-blue-horizon">Send crypto to NAGA Exchange</span>
                                                                </p>
                                                            </div>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="tr-bo-set">
                                    <div>
                                        <div class="tb-se-title">
                                            <div class="icon" style="background-image: url('/assets/img/badge_solusd.png');"></div>
                                            <div class="section-par">
                                                <h6 class="mb-0">Solana</h6>
                                                <div class="tb-se-tag mt-1 tag-line">-3.17%</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="text-right pr-2">
                                        <div class="section-par">
                                            <h6 class="mb-0 text-uppercase">0 Sol</h6>
                                            <p class="co-blue-horizon">0.00</p>
                                        </div>
                                    </div>
                                    <div class="set-auto">
                                        <div class="com_dropdown">
                                            <button class="button button-text text-blue-horizon cr-wa-more com_dropdown-title">
                                                <div class="far fa-ellipsis-v"></div>
                                            </button>
                                            <div class="com_dropdown-content cr-wa-sub">
                                                <ul class="cw-su-list">
                                                    <li>
                                                        <a href="#">
                                                            <div class="icon"><img src="/assets/img/icons/icon_buy_sell.png" alt=""></div>
                                                            <div class="section-par">
                                                                <p> Buy / Sell BCH <br>
                                                                    <span class="co-blue-horizon">Buy or sell crypto with your trading account</span>
                                                                </p>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <div class="icon"><img src="/assets/img/icons/icon_receive.png" alt=""></div>
                                                            <div class="section-par">
                                                                <p> Receive<br>
                                                                    <span class="co-blue-horizon">Receive crypto from another wallet</span>
                                                                </p>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <div class="icon"><img src="/assets/img/icons/icon_send_crypto.png" alt=""></div>
                                                            <div class="section-par">
                                                                <p> Send Crypto<br>
                                                                    <span class="co-blue-horizon">Send crypto to an address, email or NAGA User</span>
                                                                </p>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <div class="icon"><img src="/assets/img/icons/icon_send_exchange.png" alt=""></div>
                                                            <div class="section-par">
                                                                <p> Send to Exchange<br>
                                                                    <span class="co-blue-horizon">Send crypto to NAGA Exchange</span>
                                                                </p>
                                                            </div>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default{};
</script>