<template>
    <header>
        <div class="header-content header-mobile">
            <div class="header-top container-fluid ">
                <div class="module container width-5">
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="section-title mb-0">
                            <button class="button button-text">
                                <h5 class="mb-0 d-flex">
                                    <div class="icon mr-3"><svg width="25" version="1.0" viewBox="0 0 470 342" xmlns="http://www.w3.org/2000/svg">
                                            <g transform="translate(0 342) scale(.1 -.1)">
                                                <path d="m1630 3407c-27-8-212-187-813-786-523-522-784-789-797-817-26-53-26-135 0-188 28-58 1551-1579 1602-1601 52-21 140-19 186 4 106 55 152 182 103 286-13 27-205 225-590 610l-571 570 3795 10 45 25c24 14 58 45 75 68 27 40 30 51 30 122s-3 82-30 122c-17 23-51 54-75 68l-45 25-3795 10 566 565c311 311 573 581 583 600 46 89 33 178-38 250-63 66-140 85-231 57z"></path>
                                            </g>
                                        </svg></div> Manage Funds
                                </h5>
                            </button>
                        </div>
                        <ul class="he-to-ctrl">
                            <li><a class="ht-ct-icon" href="#"><svg width="18" version="1.0" viewBox="0 0 480 448" xmlns="http://www.w3.org/2000/svg">
                                        <g transform="translate(0 448) scale(.1 -.1)">
                                            <path d="m328 4461c-145-47-269-174-312-320-14-49-16-203-16-1422 0-1314 1-1370 19-1428 46-147 172-272 320-315 44-13 109-16 338-16h283v-427c0-409 1-429 20-461 29-48 75-72 138-72 29 0 63 5 75 11 12 7 286 223 608 480l587 469h1010c963 0 1013 1 1071 19 147 46 272 172 315 320 14 49 16 203 16 1421s-2 1372-16 1421c-43 148-168 274-315 320-59 18-125 19-2072 18-1923 0-2015-1-2069-18zm4016-311c49-11 85-37 111-81 20-34 20-52 20-1349s0-1315-20-1349c-26-44-62-70-111-81-22-6-483-10-1060-10h-1021l-35-24c-19-13-238-186-486-385-249-198-454-361-457-361s-5 150-5 333c0 311-1 334-20 364-41 69-34 67-445 73-350 5-372 6-405 26-48 28-78 75-85 137-3 29-5 624-3 1322 3 1249 3 1271 23 1304 25 42 62 71 106 81 48 11 3845 12 3893 0z" />
                                            <path d="m1032 3180c-48-30-72-75-72-140s24-110 72-140c32-20 53-20 1368-20s1336 0 1368 20c48 30 72 75 72 140s-24 110-72 140c-32 20-53 20-1368 20s-1336 0-1368-20z" />
                                            <path d="m1032 2540c-48-30-72-75-72-140s24-110 72-140c32-19 52-20 728-20s696 1 728 20c48 30 72 75 72 140s-24 110-72 140c-32 19-52 20-728 20s-696-1-728-20z" />
                                        </g>
                                    </svg></a></li>
                            <li><a class="ht-ct-icon" href="#"><svg width="18" version="1.0" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                                        <g transform="translate(0 512) scale(.1 -.1)">
                                            <path d="m2442 5095c-29-15-64-41-77-59-58-76-55-12-55-1166v-1060l-1073-2-1074-3-49-30c-30-19-60-49-79-79-27-45-30-57-30-136s3-91 30-136c19-30 49-60 79-79l49-30 1074-3 1073-2 2-1073 3-1074 30-49c19-30 49-60 79-79 45-27 57-30 136-30s91 3 136 30c30 19 60 49 79 79l30 49 3 1074 2 1073 1073 2 1074 3 49 30c30 19 60 49 79 79 27 45 30 57 30 136s-3 91-30 136c-19 30-49 60-79 79l-49 30-1074 3-1073 2-2 1073-3 1074-30 49c-46 74-109 108-204 112-66 3-83 0-129-23z" />
                                        </g>
                                    </svg></a></li>
                        </ul>
                    </div>
                </div>
                <div class="module container width-5">
                    <div class="he-mo-nav">
                        <div class="com_draggable com_tab">
                            <div class="com_draggable-main">
                                <ul class="pr-nav-list list-wide com_tab-nav no-wrap">
                                    <li class="item"><a href="#account-list">Account List</a></li>
                                    <li class="item"><a href="#deposit">Deposit</a></li>
                                    <li class="item"><a href="#withdraw">Withdraw</a></li>
                                    <li class="item"><a href="#transfer">Transfer</a></li>
                                    <li class="item"><a href="#history">History</a></li>
                                    <li class="item"><a href="#payment-methods">Payment Methods</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <section id="main-wrapper" style="padding-top: 100px; padding-bottom: 67px;">
        <div class="container-fluid section-small" tab-parent>
            <div class="module container width-5">
                <div id="account-list" class="com_tab-content" scroll-diff="[tab-parent]" style="display: block;">
                    <div class="tr-bo-set pt-3 pb-3">
                        <div>
                            <div class="section-par par-blue-horizon">
                                <p>Currently Logged In</p>
                            </div>
                            <div class="row mt-3 mb-4">
                                <div class="col">
                                    <div class="tb-se-title align-items-start">
                                        <div class="icon bg-dark-blue" style="background-image: url('/assets/img/icon_copyrack.png');"></div>
                                        <div class="section-par">
                                            <h6 class="mb-1">Copytrade USD</h6>
                                            <h5 class="mb-1 fw-500 co-pure-apple">10,000.00 USD</h5>
                                            <div class="d-flex">
                                                <div class="tb-se-tags align-items-center">
                                                    <h6 class="mb-0 mr-2 co-blue-horizon">#1325685</h6>
                                                    <div class="tb-se-tag tag-v2">MT5</div>
                                                    <div class="tb-se-tag tag-v2 tag-blue-horizon">Live</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-auto">
                                    <button class="button button-text text-blue-horizon cr-wa-more"><i class="far fa-ellipsis-v"></i></button>
                                </div>
                            </div>
                            <div class="row row-list-mb2">
                                <div class="col-6 section-par">
                                    <h6 class="mb-1 co-blue-horizon">Withdrawable</h6>
                                    <h6>$0.00</h6>
                                </div>
                                <div class="col-6 section-par text-right">
                                    <h6 class="mb-1 co-blue-horizon">Blocked Bonus</h6>
                                    <h6>$0.00</h6>
                                </div>
                                <div class="col-6 section-par">
                                    <h6 class="mb-1 co-blue-horizon">Bonus</h6>
                                    <h6>$0.00</h6>
                                </div>
                                <div class="col-6 section-par text-right">
                                    <h6 class="mb-1 co-blue-horizon">Amount</h6>
                                    <h6>$10,000.00</h6>
                                </div>
                                <div class="col-12 d-flex justify-content-center">
                                    <button class="button button-text text-blue">
                                        <h6 class="mb-0">Change account</h6>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="deposit" class="com_tab-content" scroll-diff="[tab-parent]">
                    <div data-sub-page-parent>
                        <div class="row-list-mb2">
                            <div class="tr-bo-caption">
                                <p>We do not accept third-party payments. Please ensure that you are using a credit/debit card, bank account, or e-wallet account under your name.</p>
                            </div>
                            <div class="d-flex align-items-center justify-content-between">
                                <h6 class="mb-0 co-blue-horizon">Deposit method</h6>
                                <button class="button button-text text-blue">
                                    <h6 class="mb-0">Transaction History</h6>
                                </button>
                            </div>
                            <div class="ma-fu-subs">
                                <div class="tr-bo-box com_accordion" open-first="true" close-others="false">
                                    <div class="com_accordion-set">
                                        <h5 class="fw-500 com_accordion-title">Crypto Currencies</h5>
                                        <div class="com_accordion-content">
                                            <div class="tr-bo-set set-small set-title align-items-end">
                                                <div>Method</div>
                                            </div>
                                            <ul class="tr-bo-list list-v2">
                                                <li>
                                                    <div class="tr-bo-set set-small shadow-none">
                                                        <div>
                                                            <div class="tb-se-title">
                                                                <div class="icon icon-small" style="background-image: url('/assets/img/icon_bitcoin.png');"></div>
                                                                <div class="section-par">
                                                                    <h6 class="mb-0">Bitcoin</h6>
                                                                    <p class="mb-0 co-blue-horizon">BTC</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex align-items-center justify-content-end pr-2">
                                                            <button class="button button-secondary button-line width-3">Deposit</button>
                                                        </div>
                                                        <div class="set-auto">
                                                            <div class="com_dropdown">
                                                                <button class="button button-text text-blue-horizon cr-wa-more com_dropdown-title"><i class="far fa-ellipsis-v"></i></button>
                                                                <div class="com_dropdown-content">
                                                                    <ul>
                                                                        <li><a data-sub-page href="#deposit-details">Show Details</a></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="tr-bo-set set-small shadow-none">
                                                        <div>
                                                            <div class="tb-se-title">
                                                                <div class="icon icon-small" style="background-image: url('/assets/img/icon_etherium.png');"></div>
                                                                <div class="section-par">
                                                                    <h6 class="mb-0">Etherium</h6>
                                                                    <p class="mb-0 co-blue-horizon">ETH</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex align-items-center justify-content-end pr-2">
                                                            <button class="button button-secondary button-line width-3">Deposit</button>
                                                        </div>
                                                        <div class="set-auto">
                                                            <div class="com_dropdown">
                                                                <button class="button button-text text-blue-horizon cr-wa-more com_dropdown-title"><i class="far fa-ellipsis-v"></i></button>
                                                                <div class="com_dropdown-content">
                                                                    <ul>
                                                                        <li><a data-sub-page href="#deposit-details">Show Details</a></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="tr-bo-set set-small shadow-none">
                                                        <div>
                                                            <div class="tb-se-title">
                                                                <div class="icon icon-small" style="background-image: url('/assets/img/icon_dashcoin.png');"></div>
                                                                <div class="section-par">
                                                                    <h6 class="mb-0">Dashcoin</h6>
                                                                    <p class="mb-0 co-blue-horizon">Dash</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex align-items-center justify-content-end pr-2">
                                                            <button class="button button-secondary button-line width-3">Deposit</button>
                                                        </div>
                                                        <div class="set-auto">
                                                            <div class="com_dropdown">
                                                                <button class="button button-text text-blue-horizon cr-wa-more com_dropdown-title"><i class="far fa-ellipsis-v"></i></button>
                                                                <div class="com_dropdown-content">
                                                                    <ul>
                                                                        <li><a data-sub-page href="#deposit-details">Show Details</a></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="tr-bo-set set-small shadow-none">
                                                        <div>
                                                            <div class="tb-se-title">
                                                                <div class="icon icon-small" style="background-image: url('/assets/img/icon_litecoin.png');"></div>
                                                                <div class="section-par">
                                                                    <h6 class="mb-0">Litecoin</h6>
                                                                    <p class="mb-0 co-blue-horizon">LTC</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex align-items-center justify-content-end pr-2">
                                                            <button class="button button-secondary button-line width-3">Deposit</button>
                                                        </div>
                                                        <div class="set-auto">
                                                            <div class="com_dropdown">
                                                                <button class="button button-text text-blue-horizon cr-wa-more com_dropdown-title"><i class="far fa-ellipsis-v"></i></button>
                                                                <div class="com_dropdown-content">
                                                                    <ul>
                                                                        <li><a data-sub-page href="#deposit-details">Show Details</a></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="tr-bo-set set-small shadow-none">
                                                        <div>
                                                            <div class="tb-se-title">
                                                                <div class="icon icon-small" style="background-image: url('/assets/img/icon_bitcoin_cash.png');"></div>
                                                                <div class="section-par">
                                                                    <h6 class="mb-0">Bitcoin Cash</h6>
                                                                    <p class="mb-0 co-blue-horizon">BCH</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex align-items-center justify-content-end pr-2">
                                                            <button class="button button-secondary button-line width-3">Deposit</button>
                                                        </div>
                                                        <div class="set-auto">
                                                            <div class="com_dropdown">
                                                                <button class="button button-text text-blue-horizon cr-wa-more com_dropdown-title"><i class="far fa-ellipsis-v"></i></button>
                                                                <div class="com_dropdown-content">
                                                                    <ul>
                                                                        <li><a data-sub-page href="#deposit-details">Show Details</a></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="tr-bo-set set-small shadow-none">
                                                        <div>
                                                            <div class="tb-se-title">
                                                                <div class="icon icon-small" style="background-image: url('/assets/img/icon_copyrack_v2.png');"></div>
                                                                <div class="section-par">
                                                                    <h6 class="mb-0">Copycrack</h6>
                                                                    <p class="mb-0 co-blue-horizon">CPC</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex align-items-center justify-content-end pr-2">
                                                            <button class="button button-secondary button-line width-3">Deposit</button>
                                                        </div>
                                                        <div class="set-auto">
                                                            <div class="com_dropdown">
                                                                <button class="button button-text text-blue-horizon cr-wa-more com_dropdown-title"><i class="far fa-ellipsis-v"></i></button>
                                                                <div class="com_dropdown-content">
                                                                    <ul>
                                                                        <li><a data-sub-page href="#deposit-details">Show Details</a></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tr-bo-caption">
                                <p>Important information:</p>
                                <p>* Days are for indicative purposes and Copycrack shall not be held liable for any delay of processing if that delay is out of the control of Copcycrack.</p>
                                <p>* * Additional delays may occur to users who have been requested to provide additional information/documents to release their next deposit.</p>
                            </div>
                        </div>
                    </div>
                    <div id="deposit-details" class="com_sub-page">
                        <div class="tr-bo-set pt-3 pb-3">
                            <div>
                                <div class="tb-se-title mb-4">
                                    <div class="icon icon-small" style="background-image: url('/assets/img/icon_bitcoin.png');"></div>
                                    <div class="section-par">
                                        <h6 class="mb-0">Bitcoin</h6>
                                        <p class="co-blue-horizon">BTC</p>
                                    </div>
                                </div>
                                <div class="row row-list-mb2">
                                    <div class="col-12 section-par">
                                        <h6 class="mb-1 co-blue-horizon">Currencies</h6>
                                        <h6>-</h6>
                                    </div>
                                    <div class="col-12 section-par">
                                        <h6 class="mb-1 co-blue-horizon">Fees</h6>
                                        <h6>Covered by Copycrack</h6>
                                    </div>
                                    <div class="col-12 section-par">
                                        <h6 class="mb-1 co-blue-horizon">Min. Deposit </h6>
                                        <h6>$250 or equivalent in other currency</h6>
                                    </div>
                                    <div class="col-12 section-par">
                                        <h6 class="mb-1 co-blue-horizon">Processing Time</h6>
                                        <h6>10 minutes</h6>
                                    </div>
                                    <div class="col-12 d-flex justify-content-center pt-3 pb-3">
                                        <button class="button button-text text-purple">
                                            <h6 class="mb-0">Deposit</h6>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="withdraw" class="com_tab-content" scroll-diff="[tab-parent]">
                    <div class="row ma-fu-form">
                        <div class="col-12 form-group">
                            <label for="">Currency</label>
                            <div class="com_select-box">
                                <select name="" id="">
                                    <option value="">Bitcoin BTC</option>
                                    <option value="">sample 1</option>
                                    <option value="">sample 2</option>
                                    <option value="">sample 3</option>
                                </select>
                            </div>
                            <label class="mt-2" for="">Balance: 0.0023232 (99.9)</label>
                        </div>
                        <div class="col-12 form-group">
                            <label for="">Destination BTC Address</label>
                            <div class="com_select-box">
                                <select name="" id="">
                                    <option value="">External Address</option>
                                    <option value="">sample 1</option>
                                    <option value="">sample 2</option>
                                    <option value="">sample 3</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-12 form-group">
                            <label for="">Amount</label>
                            <div class="com_input-group">
                                <span class="input-tag top-right">BTC</span>
                                <input class="form-control tr-bo-input" type="text" name="" value="0.0023232" placeholder="0000">
                            </div>
                        </div>
                        <div class="col-12 form-group">
                            <button class="button button-text text-blue">Withdraw Max</button>
                            <div class="com_input-group">
                                <span class="input-tag top-right">USD</span>
                                <input class="form-control tr-bo-input" type="text" name="" value="8.1" placeholder="0000">
                            </div>
                        </div>
                        <div class="col-12 form-group">
                            <label for="">Two Factor Authentication Code</label>
                            <div class="com_input-group">
                                <span class="input-tag top-right">BTC</span>
                                <input class="form-control tr-bo-input" type="text" name="" value="" placeholder="0000">
                            </div>
                        </div>
                        <div class="col-12 form-group">
                            <button class="button button-secondary width-3">Withdraw</button>
                        </div>
                    </div>
                </div>
                <div id="transfer" class="com_tab-content" scroll-diff="[tab-parent]">
                    <div class="row-list-mb2">
                        <div class="tr-bo-caption">
                            <p>We do not accept third-party payments. Please ensure that you are using a credit/debit card, bank account, or e-wallet account under your name.</p>
                        </div>
                        <div class="row ma-fu-form">
                            <div class="col-12 form-group">
                                <label for="">Transferring from</label>
                                <div class="com_select-box">
                                    <select name="" id="">
                                        <option value="">Select Account Source</option>
                                        <option value="">sample 1</option>
                                        <option value="">sample 2</option>
                                        <option value="">sample 3</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-12 form-group">
                                <label for="">Transferring to</label>
                                <div class="com_select-box">
                                    <select name="" id="">
                                        <option value="">Select Account Source</option>
                                        <option value="">sample 1</option>
                                        <option value="">sample 2</option>
                                        <option value="">sample 3</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-12 form-group">
                                <button class="button button-secondary width-3">Transfer</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="history" class="com_tab-content" scroll-diff="[tab-parent]">
                    <div data-sub-page-parent>
                        <div class="row-list-mb2">
                            <div class="tr-bo-caption">
                                <p>We do not accept third-party payments. Please ensure that you are using a credit/debit card, bank account, or e-wallet account under your name.</p>
                            </div>
                            <div class="com_tab row-list-mb2">
                                <div>
                                    <div class="com_tab-nav tr-bo-tab-sub-nav">
                                        <ul>
                                            <li class="item"><a href="#deposit-history">Deposit History</a></li>
                                            <li class="item"><a href="#withdrawal-history">Withdrawal History</a></li>
                                            <li class="item"><a href="#transfer-history">Transfer History</a></li>
                                        </ul>
                                    </div>
                                    <div class="com_tab-nav-select d-block d-md-none"></div>
                                </div>
                                <div id="deposit-history" class="com_tab-content">
                                    <div class="tr-bo-box">
                                        <ul class="tr-bo-list list-v2">
                                            <li>
                                                <div class="tr-bo-set set-small shadow-none">
                                                    <div class="set-30">
                                                        <div class="section-par">
                                                            <p class="mb-1 co-blue-horizon">Method</p>
                                                            <p>Bitcoin BTC</p>
                                                        </div>
                                                    </div>
                                                    <div class="set-33 set-md-35">
                                                        <div class="section-par">
                                                            <p class="mb-1 co-blue-horizon">Date</p>
                                                            <p>June 29, 2022</p>
                                                        </div>
                                                    </div>
                                                    <div class="set-30">
                                                        <div class="section-par">
                                                            <p class="mb-1 co-blue-horizon">Status</p>
                                                            <p>Success</p>
                                                        </div>
                                                    </div>
                                                    <div class="set-auto">
                                                        <div class="com_dropdown">
                                                            <button class="button button-text text-blue-horizon cr-wa-more com_dropdown-title"><i class="far fa-ellipsis-v"></i></button>
                                                            <div class="com_dropdown-content">
                                                                <ul>
                                                                    <li><a data-sub-page href="#history-details">Show Details</a></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div id="withdrawal-history" class="com_tab-content">
                                    <div>
                                        <div class="tr-bo-box">
                                            <ul class="tr-bo-list list-v2">
                                                <li>
                                                    <div class="tr-bo-set set-small shadow-none">
                                                        <div class="set-30">
                                                            <div class="section-par">
                                                                <p class="mb-1 co-blue-horizon">Method</p>
                                                                <p>Bitcoin BTC</p>
                                                            </div>
                                                        </div>
                                                        <div class="set-33 set-md-35">
                                                            <div class="section-par">
                                                                <p class="mb-1 co-blue-horizon">Date</p>
                                                                <p>June 29, 2022</p>
                                                            </div>
                                                        </div>
                                                        <div class="set-30">
                                                            <div class="section-par">
                                                                <p class="mb-1 co-blue-horizon">Status</p>
                                                                <p>Success</p>
                                                            </div>
                                                        </div>
                                                        <div class="set-auto">
                                                            <div class="com_dropdown">
                                                                <button class="button button-text text-blue-horizon cr-wa-more com_dropdown-title"><i class="far fa-ellipsis-v"></i></button>
                                                                <div class="com_dropdown-content">
                                                                    <ul>
                                                                        <li><a data-sub-page href="#history-details">Show Details</a></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="tr-bo-set set-small shadow-none">
                                                        <div class="set-30">
                                                            <div class="section-par">
                                                                <p class="mb-1 co-blue-horizon">Method</p>
                                                                <p>Bitcoin BTC</p>
                                                            </div>
                                                        </div>
                                                        <div class="set-33 set-md-35">
                                                            <div class="section-par">
                                                                <p class="mb-1 co-blue-horizon">Date</p>
                                                                <p>June 29, 2022</p>
                                                            </div>
                                                        </div>
                                                        <div class="set-30">
                                                            <div class="section-par">
                                                                <p class="mb-1 co-blue-horizon">Status</p>
                                                                <p>Success</p>
                                                            </div>
                                                        </div>
                                                        <div class="set-auto">
                                                            <div class="com_dropdown">
                                                                <button class="button button-text text-blue-horizon cr-wa-more com_dropdown-title"><i class="far fa-ellipsis-v"></i></button>
                                                                <div class="com_dropdown-content">
                                                                    <ul>
                                                                        <li><a data-sub-page href="#history-details">Show Details</a></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div id="transfer-history" class="com_tab-content">
                                    <div class="tr-bo-box">
                                        <ul class="tr-bo-list list-v2">
                                            <li>
                                                <div class="tr-bo-set set-small shadow-none">
                                                    <div class="set-30">
                                                        <div class="section-par">
                                                            <p class="mb-1 co-blue-horizon">Method</p>
                                                            <p>Bitcoin BTC</p>
                                                        </div>
                                                    </div>
                                                    <div class="set-33 set-md-35">
                                                        <div class="section-par">
                                                            <p class="mb-1 co-blue-horizon">Date</p>
                                                            <p>June 29, 2022</p>
                                                        </div>
                                                    </div>
                                                    <div class="set-30">
                                                        <div class="section-par">
                                                            <p class="mb-1 co-blue-horizon">Status</p>
                                                            <p>Success</p>
                                                        </div>
                                                    </div>
                                                    <div class="set-auto">
                                                        <div class="com_dropdown">
                                                            <button class="button button-text text-blue-horizon cr-wa-more com_dropdown-title"><i class="far fa-ellipsis-v"></i></button>
                                                            <div class="com_dropdown-content">
                                                                <ul>
                                                                    <li><a data-sub-page href="#history-details">Show Details</a></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="tr-bo-set set-small shadow-none">
                                                    <div class="set-30">
                                                        <div class="section-par">
                                                            <p class="mb-1 co-blue-horizon">Method</p>
                                                            <p>Bitcoin BTC</p>
                                                        </div>
                                                    </div>
                                                    <div class="set-33 set-md-35">
                                                        <div class="section-par">
                                                            <p class="mb-1 co-blue-horizon">Date</p>
                                                            <p>June 29, 2022</p>
                                                        </div>
                                                    </div>
                                                    <div class="set-30">
                                                        <div class="section-par">
                                                            <p class="mb-1 co-blue-horizon">Status</p>
                                                            <p>Success</p>
                                                        </div>
                                                    </div>
                                                    <div class="set-auto">
                                                        <div class="com_dropdown">
                                                            <button class="button button-text text-blue-horizon cr-wa-more com_dropdown-title"><i class="far fa-ellipsis-v"></i></button>
                                                            <div class="com_dropdown-content">
                                                                <ul>
                                                                    <li><a data-sub-page href="#history-details">Show Details</a></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="tr-bo-set set-small shadow-none">
                                                    <div class="set-30">
                                                        <div class="section-par">
                                                            <p class="mb-1 co-blue-horizon">Method</p>
                                                            <p>Bitcoin BTC</p>
                                                        </div>
                                                    </div>
                                                    <div class="set-33 set-md-35">
                                                        <div class="section-par">
                                                            <p class="mb-1 co-blue-horizon">Date</p>
                                                            <p>June 29, 2022</p>
                                                        </div>
                                                    </div>
                                                    <div class="set-30">
                                                        <div class="section-par">
                                                            <p class="mb-1 co-blue-horizon">Status</p>
                                                            <p>Success</p>
                                                        </div>
                                                    </div>
                                                    <div class="set-auto">
                                                        <div class="com_dropdown">
                                                            <button class="button button-text text-blue-horizon cr-wa-more com_dropdown-title"><i class="far fa-ellipsis-v"></i></button>
                                                            <div class="com_dropdown-content">
                                                                <ul>
                                                                    <li><a data-sub-page href="#history-details">Show Details</a></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="history-details" class="com_sub-page">
                        <div class="row row-list-mb2">
                            <div class="col-12 section-par">
                                <h6 class="mb-1 co-blue-horizon">Method</h6>
                                <h6>Bitcoin BTC</h6>
                            </div>
                            <div class="col-12 section-par">
                                <h6 class="mb-1 co-blue-horizon">Address</h6>
                                <h6>xxsaw124i.4029</h6>
                            </div>
                            <div class="col-12 section-par">
                                <h6 class="mb-1 co-blue-horizon">Fees</h6>
                                <h6>Covered by Copycrack</h6>
                            </div>
                            <div class="col-12 section-par">
                                <h6 class="mb-1 co-blue-horizon">Amount</h6>
                                <h6>$250 or equivalent in other currency</h6>
                            </div>
                            <div class="col-12 section-par">
                                <h6 class="mb-1 co-blue-horizon">Date</h6>
                                <h6>10 minutes</h6>
                            </div>
                            <div class="col-12 section-par">
                                <h6 class="mb-1 co-blue-horizon">Status</h6>
                                <h6>Success</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="payment-methods" class="com_tab-content" scroll-diff="[tab-parent]">
                    <div class="row-list-mb2">
                        <div class="tr-bo-caption">
                            <p>We do not accept third-party payments. Please ensure that you are using a credit/debit card, bank account, or e-wallet account under your name.</p>
                        </div>
                        <div class="d-flex align-items-center justify-content-between">
                            <h6 class="mb-0 co-blue-horizon">Crypto Currency Payment</h6>
                        </div>
                        <div class="ma-fu-subs">
                            <div class="tr-bo-box">
                                <div class="tr-bo-set set-small set-title align-items-end justify-content-between">
                                    <div>Method</div>
                                    <div class="text-right pr-3">Processing Time</div>
                                    <div class="set-auto"></div>
                                </div>
                                <ul class="tr-bo-list list-v2">
                                    <li>
                                        <div class="tr-bo-set set-small shadow-none">
                                            <div>
                                                <div class="tb-se-title">
                                                    <div class="icon icon-small" style="background-image: url('/assets/img/icon_bitcoin.png');"></div>
                                                    <div class="section-par">
                                                        <h6 class="mb-0">Bitcoin</h6>
                                                        <p class="mb-0 co-blue-horizon">BTC</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="d-flex align-items-center justify-content-end pr-3">
                                                <p class="mb-0">10 min</p>
                                            </div>
                                            <div class="set-auto">
                                                <div class="com_dropdown">
                                                    <button class="button button-text text-blue-horizon cr-wa-more com_dropdown-title"><i class="far fa-ellipsis-v"></i></button>
                                                    <div class="com_dropdown-content">
                                                        <ul>
                                                            <li><a data-sub-page href="#payment-method-details">Show Details</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="tr-bo-set set-small shadow-none">
                                            <div>
                                                <div class="tb-se-title">
                                                    <div class="icon icon-small" style="background-image: url('/assets/img/icon_etherium.png');"></div>
                                                    <div class="section-par">
                                                        <h6 class="mb-0">Etherium</h6>
                                                        <p class="mb-0 co-blue-horizon">ETH</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="d-flex align-items-center justify-content-end pr-3">
                                                <p class="mb-0">10 min</p>
                                            </div>
                                            <div class="set-auto">
                                                <div class="com_dropdown">
                                                    <button class="button button-text text-blue-horizon cr-wa-more com_dropdown-title"><i class="far fa-ellipsis-v"></i></button>
                                                    <div class="com_dropdown-content">
                                                        <ul>
                                                            <li><a data-sub-page href="#payment-method-details">Show Details</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="tr-bo-set set-small shadow-none">
                                            <div>
                                                <div class="tb-se-title">
                                                    <div class="icon icon-small" style="background-image: url('/assets/img/icon_dashcoin.png');"></div>
                                                    <div class="section-par">
                                                        <h6 class="mb-0">Dashcoin</h6>
                                                        <p class="mb-0 co-blue-horizon">Dash</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="d-flex align-items-center justify-content-end pr-3">
                                                <p class="mb-0">10 min</p>
                                            </div>
                                            <div class="set-auto">
                                                <div class="com_dropdown">
                                                    <button class="button button-text text-blue-horizon cr-wa-more com_dropdown-title"><i class="far fa-ellipsis-v"></i></button>
                                                    <div class="com_dropdown-content">
                                                        <ul>
                                                            <li><a data-sub-page href="#payment-method-details">Show Details</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="tr-bo-set set-small shadow-none">
                                            <div>
                                                <div class="tb-se-title">
                                                    <div class="icon icon-small" style="background-image: url('/assets/img/icon_litecoin.png');"></div>
                                                    <div class="section-par">
                                                        <h6 class="mb-0">Litecoin</h6>
                                                        <p class="mb-0 co-blue-horizon">LTC</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="d-flex align-items-center justify-content-end pr-3">
                                                <p class="mb-0">10 min</p>
                                            </div>
                                            <div class="set-auto">
                                                <div class="com_dropdown">
                                                    <button class="button button-text text-blue-horizon cr-wa-more com_dropdown-title"><i class="far fa-ellipsis-v"></i></button>
                                                    <div class="com_dropdown-content">
                                                        <ul>
                                                            <li><a data-sub-page href="#payment-method-details">Show Details</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="tr-bo-set set-small shadow-none">
                                            <div>
                                                <div class="tb-se-title">
                                                    <div class="icon icon-small" style="background-image: url('/assets/img/icon_bitcoin_cash.png');"></div>
                                                    <div class="section-par">
                                                        <h6 class="mb-0">Bitcoin Cash</h6>
                                                        <p class="mb-0 co-blue-horizon">BCH</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="d-flex align-items-center justify-content-end pr-3">
                                                <p class="mb-0">10 min</p>
                                            </div>
                                            <div class="set-auto">
                                                <div class="com_dropdown">
                                                    <button class="button button-text text-blue-horizon cr-wa-more com_dropdown-title"><i class="far fa-ellipsis-v"></i></button>
                                                    <div class="com_dropdown-content">
                                                        <ul>
                                                            <li><a data-sub-page href="#payment-method-details">Show Details</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="tr-bo-set set-small shadow-none">
                                            <div>
                                                <div class="tb-se-title">
                                                    <div class="icon icon-small" style="background-image: url('/assets/img/icon_copyrack_v2.png');"></div>
                                                    <div class="section-par">
                                                        <h6 class="mb-0">Copycrack</h6>
                                                        <p class="mb-0 co-blue-horizon">CPC</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="d-flex align-items-center justify-content-end pr-3">
                                                <p class="mb-0">10 min</p>
                                            </div>
                                            <div class="set-auto">
                                                <div class="com_dropdown">
                                                    <button class="button button-text text-blue-horizon cr-wa-more com_dropdown-title"><i class="far fa-ellipsis-v"></i></button>
                                                    <div class="com_dropdown-content">
                                                        <ul>
                                                            <li><a data-sub-page href="#payment-method-details">Show Details</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default{};
</script>