import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import Toast from "vue-toastification";
import staticVars from '@/staticVars'
import * as COMMON_METHODS from './commonFunctions'
import vSelect from "vue-select";
import { defineRule, configure } from 'vee-validate';
import AllRules from '@vee-validate/rules';
import VPagination from "@hennge/vue3-pagination";
import VueClipboard from 'vue3-clipboard'
//import Datepicker from 'vue3-date-time-picker';
import VueFeather from 'vue-feather';
import vuex_store from '@/store/vuex_store';
import createPopper from '@popperjs/core';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import appLoader from './components/loader.vue';
import norecord from './components/no-record-found.vue';
import '../node_modules/bootstrap/dist/js/bootstrap.min.js'
import "vue-toastification/dist/index.css";
import 'vue3-marquee/dist/style.css'

import PerfectScrollbar from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'

import '../public/assets/js/custom.js'
//full screen 
import VueFullscreen from 'vue-fullscreen'
import Vue from 'vue'
import slider from "vue3-slider"
import "vue-select/dist/vue-select.css";

configure({ validateOnBlur: false, validateOnChange: true, validateOnInput: true, validateOnModelUpdate: false });
Object.keys(AllRules).forEach(rule => {
  defineRule(rule, AllRules[rule]);
});
defineRule('required', (value, t, ctx) => {
  if (!value || (value + '').trim() == '') {
    return ctx.field + ' field is required';
  }
  return true;
});
defineRule('decimal', (value, [limit]:any, ctx) => {
  var split = value.toString().split('.');
  if (split[1] && (split[1].length > 2 || !Number(value))){
    return `${ctx.field} must be numeric and may contain ${limit} decimal points`;
  }
  return true;
});
const app = createApp(App)

//Create global variables
app.config.globalProperties.static_vars = staticVars
app.config.globalProperties.COMMON = COMMON_METHODS

//Pinia store with persisted state
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

//Components for global use
app.use(vuex_store)
app.use(pinia)
app.use(PerfectScrollbar)
app.use(router)
app.use(Toast, {
  position: 'top-right',
  timeout: 4000,
  hideProgressBar: true,
  /*filterToasts : static_var.filterToasts,*/
  filterBeforeCreate:(toast, toasts) => {
    if (toasts.filter(t => t.type === toast.type).length !== 0) {
      return false;
    }
    return toast;
  },
  type: 'success',
  transition: "Vue-Toastification__bounce",
  maxToasts: 5,
  newestOnTop: true
});

app.component('v-select', vSelect)
app.component('v-paginate', VPagination)
app.component('app-loader', appLoader)
//app.component('date-picker', Datepicker);
app.component(VueFeather.name, VueFeather);
app.component('no-record', norecord);
app.component('Datepicker', Datepicker);
app.component('vue3-slider', slider);
app.use(VueClipboard, {
  autoSetContainer: true,
  appendToBody: true,
})
// app.use(''VueFullscreen);
//App mounted
app.mount('#app')
