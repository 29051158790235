<template>
    <section id="main-wrapper">
        <div class="container-fluid section-small">
            <div class="module container width-5">
                <div class="row-list-mb15">
                    <div class="ac-ar-banner banner-medium mb-3" style="background-image: url('/assets/img/dummy/banner_academy3.jpg');"></div>
                    <div class="section-par co-blue-horizon">
                        <h6 class="mb-2 fw-600 co-dark-blue">Lorem ipsum dolor sit amet adipisicing elit.</h6>
                        <p class="fw-600">22 June 2022 • 22:15 - 22:45</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut lconsequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non .</p>
                    </div>
                    <div class="fs-bo-set">
                        <div class="d-flex align-items-center co-blue-horizon">
                            <div class="icon"><svg width="18" version="1.0" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                                    <g transform="translate(0 512) scale(.1 -.1)">
                                        <path d="m2320 5105c-166-28-298-73-450-151-384-199-661-562-762-998-30-131-37-402-13-551 51-329 238-671 479-875 64-54 72-70 36-70-10 0-74-16-142-35-542-155-1006-431-1234-734-109-146-183-317-219-502-22-118-22-655 1-742 53-206 225-378 430-431 56-14 273-16 2114-16 1832 0 2059 2 2113 16 208 54 377 223 431 431 23 87 23 611 1 739-43 246-172 485-355 658-142 134-366 283-569 379-98 46-108 49-171 45-127-7-196-86-188-212 6-103 31-130 185-208 217-110 363-209 474-319 71-71 111-130 159-232 64-138 74-206 75-492 0-237-1-252-21-290-25-46-45-65-94-91-34-18-100-19-2040-19s-2006 1-2040 19c-49 26-69 45-94 91-20 38-21 53-21 290 1 328 22 425 131 597 151 237 565 487 1024 618 286 82 514 116 875 130 272 11 369 21 487 51 557 140 973 577 1095 1149 26 121 26 459 0 580-78 367-278 681-574 904-172 129-426 236-643 271-115 18-374 18-480 0zm439-411c424-76 774-425 855-854 20-105 20-295 0-400-81-429-425-772-855-855-108-20-290-20-398 0-430 83-774 426-855 855-20 106-20 295 0 400 88 467 490 831 959 868 110 9 185 5 294-14z"></path>
                                    </g>
                                </svg></div>
                            <div class="d-flex align-items-center">
                                <h6 class="mb-0">Persons Name</h6>
                                <span class="pl-2 pr-2">•</span>
                                <p class="mb-0">Attendees (100)</p>
                            </div>
                        </div>
                    </div>
                    <div class="mt-4"><button class="button button-secondary width-6">Register</button></div>
                    <div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default{};
</script>