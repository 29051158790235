import { ISeeder } from "../../../../lib/seeder/iseeder.ifc"
import { ICacheable } from "../../../../lib/cache/icacheable.ifc"
import { Process } from "../../../../lib/process/process.cls"
import { WsConnector } from "../../../../lib/ws/ws-connector.cls"
import { IProvider } from "../../../../lib/provider/iprovider.ifc";

// import { Const } from '../../../../commonAll/provider/lib/const.cls'

const Buffer = require('buffer').Buffer;
const zlib = require('zlib');

export class PriceFeedSeeder extends Process implements ISeeder {

  private static TAG = 'PriceFeedSeeder'

  /**
   * HTML5 WebSocket wrapper
   */
  private wsConnector:WsConnector = null

  constructor(iProvider:IProvider) {
    super(iProvider)   
  }

  public sendMessage(jsonMsg:any) {
    try {
      const obj = Object.keys(jsonMsg)[0]
      if(jsonMsg[obj].localParams != undefined){
        const localParams = jsonMsg[obj].localParams
        delete jsonMsg[obj].localParams
        this.wsConnector.send(JSON.stringify(jsonMsg),localParams)
      }else{
        this.wsConnector.send(JSON.stringify(jsonMsg),undefined)
      }
    } catch (ex) {
      this.log(PriceFeedSeeder.TAG, 'sendMessage EXC', {jsonMsg, ex:ex.message})
    }
  }

  /**
   * @Override
   * authenticate using the credentials inside params
   * @param params 
   */
  public auth(params:any) {
    try {
      this.sendMessage(params)
    } catch (ex) {
      this.log(PriceFeedSeeder.TAG, 'auth [ex]', ex.message)
    }
  }

  /**
   * @Override
   * subscribe tp a resource
   * @param ids either an array of ids or a key -> objecr mapping
   */
  public watchlist(ids:any):void {
    // ids = (ids instanceof Array) ? ids : this.mapping2keys(ids)
    this.sendMessage({watchlist:ids})
    this.iProvider.heartbeat()
  }
  public subscribe(ids:any):void {
    // ids = (ids instanceof Array) ? ids : this.mapping2keys(ids)
    this.sendMessage({subscribe:ids})
    // this.iProvider.heartbeat()
  }

  /**
   * @Override
   * authenticate using the credentials inside params
   * @param params 
   */
  public unsubscribe(ids:any) {
    // ids = (ids instanceof Array) ? ids : this.mapping2keys(ids)
    this.sendMessage({unsubscribe:ids})
    // this.iProvider.patchItems(ids, true)
  }


  /**
   * @Override
   * authenticate using the credentials inside params
   * @param params 
   */
  public addFavorite(ids:any) {
    this.sendMessage({addFavorite:ids})
    // this.iProvider.patchItems(ids, true)
  }
  public removeFavorite(ids:any) {
    this.sendMessage({removeFavorite:ids})
    // this.iProvider.patchItems(ids, true)
  }
  public placeOrder(ids:any) {
    this.sendMessage({placeOrder:ids})
    // this.iProvider.patchItems(ids, true)
  }
  public placepending(ids:any) {
    this.sendMessage({placePending:ids})
    // this.iProvider.patchItems(ids, true)
  }
  public cancelOrder(ids:any) {
    this.sendMessage({cancelOrder:ids})
    // this.iProvider.patchItems(ids, true)
  }
  public closeTrade(ids:any) {
    this.sendMessage({closeTrade:ids})
    // this.iProvider.patchItems(ids, true)
  }

  public modifyOrder(ids:any) {
    this.sendMessage({modifyOrder:ids})
    // this.iProvider.patchItems(ids, true)
  }
  public placestop(ids:any) {
    this.sendMessage({placeStop:ids})
    // this.iProvider.patchItems(ids, true)
  }
  public placeTrail(ids:any) {
    this.sendMessage({placeTrail:ids})
    // this.iProvider.patchItems(ids, true)
  }
  public placeLimit(ids:any) {
    this.sendMessage({placeLimit:ids})
    // this.iProvider.patchItems(ids, true)
  }
  public orderBook(ids:any) {
    this.sendMessage({orderBook:ids})
    // this.iProvider.patchItems(ids, true)
  }
  public createPriceAlert(ids:any) {
    this.sendMessage({createPriceAlert:ids})
    // this.iProvider.patchItems(ids, true)
  }
  public modifyPriceAlert(ids:any) {
    this.sendMessage({modifyPriceAlert:ids})
    // this.iProvider.patchItems(ids, true)
  }
  public removePriceAlert(ids:any) {
    this.sendMessage({removePriceAlert:ids})
    // this.iProvider.patchItems(ids, true)
  }


  

  public onopen = (ev:Event) => {
 
    // this.log(PriceFeedSeeder.TAG, 'open connection at url' + 
    //   this.settings.ENDPOINT)
      try { 
        // const _that = this
        // const vuexStore = _that.iProvider.getStore()
        // vuexStore.commit(Const.SET_WS_STATUS, 1)  // 0:'yellow',1:'green',2:'orange',3:'red' 
        this.auth(
          {auth:(this.iProvider.settings.token || ''), 
            defaults:this.settings.defaultIsinIds})
      } catch (ex) {
        console.log(ex)
      }
  }

  /**
   * handle message from webocket
   */
  heartbeat:number = 0
  public onmessage = (ev:MessageEvent) => { 
    try {
      const _that = this
      const vuexStore = _that.iProvider.getStore()
      // vuexStore.commit(Const.SET_WS_STATUS, 1)  // 0:'yellow',1:'green',2:'orange',3:'red'
      const responseBuffer = new Buffer(ev.data, 'base64');
      // var responseBuffer = new Buffer(ev.data, 'binary');
      // var responseBuffer = new Buffer(ev.data, 'binary');
      zlib.unzip(responseBuffer, function(err, buffer) {
        if (!err) {
          const jsonMsg = JSON.parse(buffer.toString())
          
          

          if(Object.keys(jsonMsg).length !=0) {
              // console.log('--->',jsonMsg);
              // this.log(PriceFeedSeeder.TAG, 'onmessage', jsonMsg)
              
              // if('orderbook' in jsonMsg){
              //     // console.log('orderbook-------------->',jsonMsg.orderbook)
              //     // const orderbook = vuexStore.getters[Const.GET_ORDER_BOOK]
              //     // console.log('1---------------------->',vuexStore.getters[Const.GET_ORDER_BOOK])
              //     for(const key in jsonMsg.orderbook){
              //       if(!orderbook[jsonMsg.orderbook[key].m]){
              //         orderbook[jsonMsg.orderbook[key].m] = jsonMsg.orderbook[key]
              //       }else{
              //         for(const buy_sell of ['buy','sell']){
              //           // console.log('========================>',buy_sell)
              //           if(orderbook[jsonMsg.orderbook[key].m][buy_sell]){
              //             if(orderbook[jsonMsg.orderbook[key].m][buy_sell].length){
              //               for(let i=0; i < jsonMsg.orderbook[key][buy_sell].length; i++){
              //                 const item_i = jsonMsg.orderbook[key][buy_sell][i];
              //                 // console.log('item_i------------------>',item_i)
              //                 let change = false;
              //                 for(let j=0; j < orderbook[jsonMsg.orderbook[key].m][buy_sell].length; j++){
              //                   const item_j = orderbook[jsonMsg.orderbook[key].m][buy_sell][j];
              //                   // console.log('item_j------------------>',item_j)
              //                   if(parseFloat(item_j.Rate) == parseFloat(item_i.Rate)){
              //                     orderbook[jsonMsg.orderbook[key].m][buy_sell][j]['Quantity'] = item_i['Quantity']
              //                     orderbook[jsonMsg.orderbook[key].m][buy_sell][j]['Partial'] = item_i['Partial']
              //                     change = true;
              //                   }
              //                   /*if(item_j['Quantity'] == item_i['Quantity']){
              //                     change = true;
              //                   }*/
              //                 }
              //                 if(!change){
              //                   orderbook[jsonMsg.orderbook[key].m][buy_sell].unshift(item_i)
              //                 }
              //               }
              //             }
              //           }else{
              //             orderbook[jsonMsg.orderbook[key].m][buy_sell] = jsonMsg.orderbook[key][buy_sell]
              //           }
              //           // orderbook[jsonMsg.orderbook[key].m][buy_sell] = orderbook[jsonMsg.orderbook[key].m][buy_sell].slice(0, 6)
              //           orderbook[jsonMsg.orderbook[key].m][buy_sell].sort((a, b) => {
              //             if (b.Rate > a.Rate) return 1
              //             if (b.Rate < a.Rate) return -1
              //             return 0
              //           })
              //           if(buy_sell == 'sell'){
              //             orderbook[jsonMsg.orderbook[key].m][buy_sell].reverse()
              //           }
              //         }
              //       }
              //     }
              //     // vuexStore.commit(Const.SET_ORDER_BOOK, orderbook)
              //     // console.log('2---------------------->',orderbook)
              //     // console.log('---------------------------------------------------------------------------')

              // }
              // if('message' in jsonMsg && Object.keys(jsonMsg.message).length > 0){
              //   console.log('message_______________',jsonMsg.message)
              //   //fecth order id
              //   if('resp_msg' in jsonMsg.message){
              //     // console.log('resp_msg ---------------->' , jsonMsg.message)
              //     if(jsonMsg.message.tempOrderId){// update new message by id
              //       const ws_order = {...vuexStore.getters[Const.GET_WS_ORDER]}
              //       // console.log('before',vuexStore.getters[Const.GET_WS_ORDER])
              //       ws_order[jsonMsg.message.tempOrderId]  = jsonMsg.message
              //       vuexStore.commit(Const.SET_WS_ORDER, ws_order)
              //       // console.log('after',vuexStore.getters[Const.GET_WS_ORDER])
              //     }
              //   }
              //   if('create_alert' in jsonMsg.message){
              //       const price_alert = jsonMsg.message.create_alert
              //       price_alert['msg_type'] = 'I'
              //       const active_notification = vuexStore.getters[Const.GET_PRICE_ALERT_NOTIFICATON]
              //       active_notification.push(price_alert)
              //       vuexStore.commit(Const.SET_PRICE_ALERT_NOTIFICATON, active_notification)
              //   }
              //   if('update_alert' in jsonMsg.message){
              //       // console.log('--------------------------->',jsonMsg.message.update_alert)
              //       const price_alert = jsonMsg.message.update_alert
              //       price_alert['msg_type'] = 'U'
              //       const active_notification = vuexStore.getters[Const.GET_PRICE_ALERT_NOTIFICATON]
              //       active_notification.push(price_alert)
              //       vuexStore.commit(Const.SET_PRICE_ALERT_NOTIFICATON, active_notification)
              //   }
              //   if('remove_alert' in jsonMsg.message){
              //       const upData = jsonMsg.message.remove_alert
              //       if(upData.type == 'active'){
              //         const create_alert = vuexStore.getters[Const.GET_ACTIVE_PRICE_ALERT]
              //         const newList = create_alert.filter(val=>val.AlertID != upData.AlertID)
              //         // console.log('active',newList)
              //         vuexStore.commit(Const.SET_ACTIVE_PRICE_ALERT, newList)
              //       }else if(upData.type == 'triggered'){
              //         const create_alert = vuexStore.getters[Const.GET_TRIGGERED_PRICE_ALERT]
              //         const newList = create_alert.filter(val=>val.AlertID != upData.AlertID)
              //         // console.log('triggered',newList)
              //         vuexStore.commit(Const.SET_TRIGGERED_PRICE_ALERT, newList)
              //       }
              //   }
              //   if('orderupdate' in jsonMsg.message && Object.keys(jsonMsg.message.orderupdate).length > 0){
              //     // console.log('--------orderupdate--------->',jsonMsg.message.orderupdate)
              //     const order = {...jsonMsg.message.orderupdate}
              //     order['OrderId'] = order.OrderID
              //     order['Opened'] = order.OpenTime
              //     delete order.OrderID
              //     delete order.OpenTime
              //     const openorderList = vuexStore.getters[Const.GET_PENDING_ORDER]
              //     const newList = openorderList.filter(val=>val.OrderId != order.OrderId)
              //     const delList = openorderList.filter(val=>val.OrderId == order.OrderId)
              //     if('Status' in jsonMsg.message.orderupdate && jsonMsg.message.orderupdate.Status == "CREATED"){
              //       delete order.Status
              //       newList.unshift(order)
              //       vuexStore.commit(Const.SET_PENDING_ORDER, newList)
              //     }
              //     else if('Status' in jsonMsg.message.orderupdate && jsonMsg.message.orderupdate.Status == "CANCELED"){
              //       vuexStore.commit(Const.SET_PENDING_ORDER, newList)
              //       /*let inputData = { 
              //         'globalParams' : {
              //           'FROM_DATE' : new Date(Date.now() - 30*24 * 3600 * 1000),
              //           'TO_DATE' : new Date()
              //         }
              //         ,'localParams' : { parameter:{pageLoader: false},'from' : 'ws' }
              //       }
              //       if(delList.length > 0 && delList[0].type == 'C'){
              //         // vuexStore.dispatch(Const.RUN_GET_TRADE_HISTORY, inputData)
              //       }else{
              //         vuexStore.dispatch(Const.RUN_GET_REMOVED_TRADE_LIST, inputData)
              //       }*/
              //     }
              //     else if('Status' in jsonMsg.message.orderupdate && jsonMsg.message.orderupdate.Status == "U"){
              //       if(order.Trail || order.Price){
              //         openorderList.forEach((val,index) => {
              //           if(openorderList[index].OrderID == order.OrderID){
              //             if(order.Trail && openorderList[index].Trail != order.Trail){
              //               openorderList[index].Trail = order.Trail
              //             }
              //             if(order.Price && openorderList[index].Price != order.Price){
              //               openorderList[index].Price = order.Price
              //             }
              //           }
              //         })
              //         vuexStore.commit(Const.SET_PENDING_ORDER, openorderList)
              //       }
              //     }
              //   }
                
              // }
              // if ('price_alert' in jsonMsg) { // on auth, su
              //   const price_alert = jsonMsg.price_alert
              //   price_alert['msg_type'] = 'T'
              //   const active_notification = vuexStore.getters[Const.GET_PRICE_ALERT_NOTIFICATON]
              //   active_notification.push(price_alert)
              //   vuexStore.commit(Const.SET_PRICE_ALERT_NOTIFICATON, active_notification)
              // }
              // if ('isins' in jsonMsg) { // on auth, su
              //   if ('subscribe' in jsonMsg) {
              //     // add the array of isin-ids to the cache
              //     _that.iProvider.addItems(jsonMsg)
              //     return
              //   }
              //   else if ('unsubscribe' in jsonMsg) {
              //     // remove the array of isin-ids from the cache
              //     _that.iProvider.patchItems(jsonMsg, false)
              //   }
              //   return
              // }
              if(!('orderbook' in jsonMsg) && !('message' in jsonMsg) && !('isins' in jsonMsg) && !('price_alert' in jsonMsg)) {
                _that.iProvider.patchItems(jsonMsg)
              }
          }
        }
      })
    } catch (exo) {
      console.log('ws-error ',exo);
      this.log(PriceFeedSeeder.TAG, 'onmessage EXC', exo)
    }
  }

  private closeWs(params?:any):void {
    try {
      // const vuexStore = this.iProvider.getStore()
      // vuexStore.commit(Const.SET_WS_STATUS, 2)  // 0:'yellow',1:'green',2:'orange',3:'red'
      this.wsConnector.stop(params)
    } catch (ex) {
      this.log('closing websocket', ex.message)
    }
  } 

  public onclose = (ev:CloseEvent) => { 
    // const vuexStore = this.iProvider.getStore()
    // vuexStore.commit(Const.SET_WS_STATUS, 3)  // 0:'yellow',1:'green',2:'orange',3:'red'
    this.log(PriceFeedSeeder.TAG, 'onclose...', ev)
  }
  public onerror = (ev:Error) => { 
    // const vuexStore = this.iProvider.getStore()
    // vuexStore.commit(Const.SET_WS_STATUS, 2)  // 0:'yellow',1:'green',2:'orange',3:'red'
    this.log(PriceFeedSeeder.TAG, 'onerror...', ev)
  }
  public onconnecting = () => { 
    // const vuexStore = this.iProvider.getStore()
    // vuexStore.commit(Const.SET_WS_STATUS, 0)  // 0:'yellow',1:'green',2:'orange',3:'red'
    this.log(PriceFeedSeeder.TAG, 'onconnecting...')
  }

  /**
   * start process thread
   * used by caches and providers and mains to start all 
   * registered providers and caches
   * @param params 
   */
  public start(params:any):void {
    this.settings = params
    try {
      // const vuexStore = this.iProvider.getStore()
      // vuexStore.commit(Const.SET_WS_STATUS, 1)  // 0:'yellow',1:'green',2:'orange',3:'red'
      this.settings.onopen = this.onopen
      this.settings.onclose = this.onclose
      this.settings.onconnecting = this.onconnecting
      this.settings.onmessage = this.onmessage
      this.wsConnector = new WsConnector()
      this.wsConnector.start(this.settings)
      
    } catch (ex) {
      this.log(PriceFeedSeeder.TAG, 'start [ex]', ex)
    }
  }
  
  /**
   * stop process thread
   * used by caches and providers and mains to stop all 
   * registered providers and caches
   * @param params 
   */
  public stop(params?:any):void {
    try {
      // const vuexStore = this.iProvider.getStore()
      // vuexStore.commit(Const.SET_WS_STATUS, 2)  // 0:'yellow',1:'green',2:'orange',3:'red'
      this.closeWs(params)      
    } catch (ex) {
      this.log(PriceFeedSeeder.TAG, "stop [ex]", ex)
    }
  }

}