export default {
  loc:(v)=>{
    let num = new Number(v || 0)
    return isNaN(num)?0:num.toLocaleString();
  },
  cryptoCurrency: {
    "BTC": "BTC",
    "LTC": "LTC",
    "ETH": "ETH",
    "BCC": "BCC",
    "USD": "USD"
  },
  currencyList:{
    "USD": "USD",
    "EUR": "EUR"
  },
  classifications: [
    "Trend Analysis",
    "Technical Indicator",
    "Chart Pattern",
    "Wave Analysis",
    "Gann",
    "Fibonacci",
    "Harmonic Pattern",
    "Candlestick Pattern"
  ],
  tradeIdeasFilter: [
    "Currencies",
    "Crypto",
    "Commodities",
    "Indices",
    "Stocks"
  ],
  isinids: {
    home: {
      forex: [
        { label: "EURUSD", value: "EURUSD" },
        { label: "USDCAD", value: "USDCAD" },
        { label: "GBPUSD", value: "GBPUSD" },
        { label: "USDJPY", value: "USDJPY" },
        { label: "EURGBP", value: "EURGBP" },
        { label: "GBPJPY", value: "GBPJPY" },
        { label: "EURJPY", value: "EURJPY" },
        { label: "USDCHF", value: "USDCHF" },
        { label: "AUDUSD", value: "AUDUSD" },
        { label: "NZDUSD", value: "NZDUSD" },
        { label: "USDMXN", value: "USDMXN" },
      ],
      crypto: [
        { label: "BTC-USD", value: "BTC-USD" },
        { label: "ETH-USD", value: "ETH-USD" },
        { label: "LTC-USD", value: "LTC-USD" },
        { label: "XRP-USD", value: "XRP-USD" },
        { label: "ETC-USD", value: "ETC-USD" },
        { label: "ADA-USD", value: "ADA-USD" },
        { label: "NEO-USD", value: "NEO-USD" },
        { label: "XLM-USD", value: "XLM-USD" },
        { label: "XEM-USDT", value: "XEM-USDT" },
      ],
      commodities: [
        { label: "XAUUSD", value: "XAUUSD" },
        { label: "USOUSD", value: "USOUSD" },
        { label: "XAGUSD", value: "XAGUSD" },
        { label: "XNGUSD", value: "XNGUSD" },
        { label: "XAUAUD", value: "XAUAUD" },
        { label: "XAUEUR", value: "XAUEUR" },
        { label: "XAGAUD", value: "XAGAUD" },
        { label: "UKOUSD", value: "UKOUSD" },
      ],
      indices: [
        {
          label: "100GBP", value: "UK100"
        },
        { label: "F40EUR", value: "FCHI" },
        {
          label: "D30EUR", value: "GDAXIM"
        },
        {
          label: "U30USD", value: "WS30M"
        },
        {
          label: "SPXUSD", value: "SPXM"
        },
        {
          label: "NASUSD", value: "NDXM"
        },
        {
          label: "225JPY", value: "J225"
        },
        {
          label: "200AUD", value: "AUS200"
        },
        {
          label: "E50EUR", value: "STOXX50E"
        },
        { label: "E35EUR", value: "E35EUR" },
      ],
      stocks: [
        { label: "AAPL", value: "AAPL" },
        { label: "AMZN", value: "AMZN" },
        { label: "NVDA", value: "NVDA" },
        { label: "ABBV", value: "ABBV" },
        { label: "AVGO", value: "AVGO" },
        { label: "BDX", value: "BDX" },
        { label: "AMWL", value: "AMWL" },
        { label: "ATVI", value: "ATVI" },
        { label: "META", value: "META" },
        { label: "MSFT", value: "MSFT" },
      ],
    }
  },

  crossPairs: [//exotic list
    'USDHKD',
    'EURHKD',
    'NZDSGD',
    'USDRUB',
    'GBPSGD',
    'GBPHKD',
    'EURSGD',
    'USDZAR',
    'USDTRY',
    'USDSGD',
    'USDSEK',
    'USDPLN',
    'USDNOK',
    'EURCZK',
    'EURDKK',
    'USDCZK',
    'USDCNH',
    'EURNOK',
    'EURPLN',
    'EURSEK',
    'EURTRY',
    'NOKSEK',
    'GBPZAR',
    'GBPTRY',
    'GBPDKK',
    'GBPSEK',
    'GBPPLN',
    'GBPNOK',
    'USDDKK',
    'EURMXN',
    'EURZAR',
    'USDMXN',
    'USDHUF',
    'EURHUF',
    'GBPHUF',
    'EURRUB',
    'USDRON',
    'EURRON',
    'USDTHB',
    'USDILS',
    'GBPCZK',
    'GBPMXN'
  ],
  timezones: [
    { value: "-12:00", label: '(GMT -12:00) Eniwetok, Kwajalein' },
    { value: "-11:00", label: '(GMT -11:00) Midway Island, Samoa' },
    { value: "-10:00", label: '(GMT -10:00) Hawaii' },
    { value: "-09:50", label: '(GMT -9:30) Taiohae' },
    { value: "-09:00", label: '(GMT -9:00) Alaska' },
    { value: "-08:00", label: '(GMT -8:00) Pacific Time (US &amp; Canada)' },
    { value: "-07:00", label: '(GMT -7:00) Mountain Time (US &amp; Canada)' },
    { value: "-06:00", label: '(GMT -6:00) Central Time (US &amp; Canada), Mexico City' },
    { value: "-05:00", label: '(GMT -5:00) Eastern Time (US &amp; Canada), Bogota, Lima' },
    { value: "-04:50", label: '(GMT -4:30) Caracas' },
    { value: "-04:00", label: '(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz' },
    { value: "-03:50", label: '(GMT -3:30) Newfoundland' },
    { value: "-03:00", label: '(GMT -3:00) Brazil, Buenos Aires, Georgetown' },
    { value: "-02:00", label: '(GMT -2:00) Mid-Atlantic' },
    { value: "-01:00", label: '(GMT -1:00) Azores, Cape Verde Islands' },
    { value: "+00:00", label: '(GMT) Western Europe Time, London, Lisbon, Casablanca' },
    { value: "+01:00", label: '(GMT +1:00) Brussels, Copenhagen, Madrid, Paris' },
    { value: "+02:00", label: '(GMT +2:00) Kaliningrad, South Africa' },
    { value: "+03:00", label: '(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg' },
    { value: "+03:50", label: '(GMT +3:30) Tehran' },
    { value: "+04:00", label: '(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi' },
    { value: "+04:50", label: '(GMT +4:30) Kabul' },
    { value: "+05:00", label: '(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent' },
    { value: "+05:50", label: '(GMT +5:30) Bombay, Calcutta, Madras, New Delhi' },
    { value: "+05:75", label: '(GMT +5:45) Kathmandu, Pokhara' },
    { value: "+06:00", label: '(GMT +6:00) Almaty, Dhaka, Colombo' },
    { value: "+06:50", label: '(GMT +6:30) Yangon, Mandalay' },
    { value: "+07:00", label: '(GMT +7:00) Bangkok, Hanoi, Jakarta' },
    { value: "+08:00", label: '(GMT +8:00) Beijing, Perth, Singapore, Hong Kong' },
    { value: "+08:75", label: '(GMT +8:45) Eucla' },
    { value: "+09:00", label: '(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk' },
    { value: "+09:50", label: '(GMT +9:30) Adelaide, Darwin' },
    { value: "+10:00", label: '(GMT +10:00) Eastern Australia, Guam, Vladivostok' },
    { value: "+10:50", label: '(GMT +10:30) Lord Howe Island' },
    { value: "+11:00", label: '(GMT +11:00) Magadan, Solomon Islands, New Caledonia' },
    { value: "+11:50", label: '(GMT +11:30) Norfolk Island' },
    { value: "+12:00", label: '(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka' },
    { value: "+12:75", label: '(GMT +12:45) Chatham Islands' },
    { value: "+13:00", label: '(GMT +13:00) Apia, Nukualofa' },
    { value: "+14:00", label: '(GMT +14:00) Line Islands, Tokelau' },
  ],
  timezonesCalendar: [
    { label: "System Default", value: 0 },
    { label: "European Central Time(GMT +1)", value: 60 },
    { label: "Eastern European Time(GMT +2)", value: 120 },
    { label: "Eastern African Time(GMT +3)", value: 180 },
    { label: "Middle East Time(GMT +3:30)", value: 210 },
    { label: "Near East Time(GMT +4)", value: 240 },
    { label: "Pakistan Lahore Time(GMT +5)", value: 300 },
    { label: "India Standard Time(GMT +5:30)", value: 330 },
    { label: "Bangladesh Standard Time(GMT +6)", value: 360 },
    { label: "Vietnam Standard Time(GMT +7)", value: 420 },
    { label: "China Taiwan Time(GMT +8)", value: 480 },
    { label: "Japan Standard Time(GMT +9)", value: 540 },
    { label: "Australia Central Time(GMT +9:30)", value: 570 },
    { label: "Australia Eastern Time(GMT +10)", value: 600 },
    { label: "Solomon Standard Time(GMT +11)", value: 660 },
    { label: "New Zealand Standard Time(GMT +12)", value: 720 },
    { label: "Midway Islands Time(GMT -11)", value: -660 },
    { label: "Hawaii Standard Time(GMT -10)", value: -600 },
    { label: "Alaska Standard Time(GMT -9)", value: -540 },
    { label: "Pacific Standard Time(GMT -8)", value: -480 },
    { label: "Phoenix Standard Time(GMT -7)", value: -420 },
    { label: "Central Standard Time(GMT -6)", value: -360 },
    { label: "Eastern Standard Time(GMT -5)", value: -300 },
    { label: "Puerto Rico and US Virgin Islands Time(GMT -4)", value: -240 },
    { label: "Canada Newfoundland Time(GMT -3:30)", value: -210 },
    { label: "Brazil Eastern Time(GMT -3)", value: -180 },
    { label: "Central African Time(GMT -1)", value: -60 }
  ],
  economicCalendarCountries: {
    australia: [
      "Australia",
      "Fiji",
      "Kiribati",
      "New Caledonia",
      "New Zealand",
      "Papua New Guinea",
      "Samoa",
      "Solomon Islands",
      "Tonga",
      "Vanuatu",
    ],
    europe: [
      "Albania",
      "Andorra",
      "Austria",
      "Belarus",
      "Belgium",
      "Bosnia and Herzegovina",
      "Bulgaria",
      "Croatia",
      "Cyprus",
      "Czech Republic",
      "Denmark",
      "Estonia",
      "Euro area",
      "Faroe Islands",
      "Finland",
      "France",
      "Germany",
      "Greece",
      "Hungary",
      "Iceland",
      "Ireland",
      "Isle of Man",
      "Italy",
      "Kosovo",
      "Latvia",
      "Liechtenstein",
      "Lithuania",
      "Luxembourg",
      "Macedonia",
      "Malta",
      "Moldova",
      "Monaco",
      "Montenegro",
      "Netherlands",
      "Norway",
      "Poland",
      "Portugal",
      "Romania",
      "Russia",
      "Serbia",
      "Slovakia",
      "Slovenia",
      "Spain",
      "Sweden",
      "Switzerland",
      "Turkey",
      "Ukraine",
      "United Kingdom",
    ],
    america: [
      "Antigua and Barbuda",
      "Argentina",
      "Aruba",
      "Bahamas",
      "Barbados",
      "Belize",
      "Bermuda",
      "Bolivia",
      "Brazil",
      "Canada",
      "Cayman Islands",
      "Chile",
      "Colombia",
      "Costa Rica",
      "Cuba",
      "Dominica",
      "Dominican Republic",
      "Ecuador",
      "El Salvador",
      "Grenada",
      "Guatemala",
      "Guyana",
      "Haiti",
      "Honduras",
      "Jamaica",
      "Mexico",
      "Nicaragua",
      "Panama",
      "Paraguay",
      "Peru",
      "Puerto Rico",
      "Suriname",
      "Trinidad and Tobago",
      "United States",
      "Uruguay",
      "Venezuela",
    ],
    africa: [
      "Algeria",
      "Angola",
      "Benin",
      "Botswana",
      "Burkina Faso",
      "Burundi",
      "Cameroon",
      "Cape Verde",
      "Central African Republic",
      "Chad",
      "Comoros",
      "Congo",
      "Djibouti",
      "Egypt",
      "Equatorial Guinea",
      "Eritrea",
      "Ethiopia",
      "Gabon",
      "Gambia",
      "Ghana",
      "Guinea",
      "Guinea Bissau",
      "Ivory Coast",
      "Kenya",
      "Lesotho",
      "Liberia",
      "Libya",
      "Madagascar",
      "Malawi",
      "Mali",
      "Mauritania",
      "Mauritius",
      "Morocco",
      "Mozambique",
      "Namibia",
      "Niger",
      "Nigeria",
      "Republic of the Congo",
      "Rwanda",
      "Sao Tome and Principe",
      "Senegal",
      "Seychelles",
      "Sierra Leone",
      "Somalia",
      "South Africa",
      "South Sudan",
      "Sudan",
      "Swaziland",
      "Tanzania",
      "Togo",
      "Tunisia",
      "Uganda",
      "Zambia",
      "Zimbabwe",
    ],
    asia: [
      "Afghanistan",
      "Armenia",
      "Azerbaijan",
      "Bahrain",
      "Bangladesh",
      "Bhutan",
      "Brunei",
      "Cambodia",
      "China",
      "East Timor",
      "Georgia",
      "Hong Kong",
      "India",
      "Indonesia",
      "Iran",
      "Iraq",
      "Israel",
      "Japan",
      "Jordan",
      "Kazakhstan",
      "Kuwait",
      "Kyrgyzstan",
      "Laos",
      "Lebanon",
      "Macao",
      "Malaysia",
      "Maldives",
      "Mongolia",
      "Myanmar",
      "Nepal",
      "North Korea",
      "Oman",
      "Palestine",
      "Pakistan",
      "Philippines",
      "Qatar",
      "Saudi Arabia",
      "Singapore",
      "South Korea",
      "Sri Lanka",
      "Syria",
      "Taiwan",
      "Tajikistan",
      "Thailand",
      "Turkmenistan",
      "United Arab Emirates",
      "Uzbekistan",
      "Vietnam",
      "Yemen",
    ],
    major: [
      "United States",
      "Euro Area",
      "China",
      "Japan",
      "Germany",
      "United Kingdom",
      "France",
      "India",
      "Italy",
      "Brazil",
      "Canada",
      "South Korea",
      "Russia",
      "Spain",
      "Australia",
      "Mexico",
      "Indonesia",
      "Turkey",
      "Netherlands",
      "Switzerland",
      "Saudi Arabia",
      "Argentina",
      "South Africa",
      "Singapore",
    ]
  },
  economicOverviewContinents: {
    '1': 'World', '2': 'Europe', '3': 'America', '4': 'Asia', '5': 'Africa', '6': 'Australia', '7': 'G20'
  },
  economyOverviewIndicators: {
    'GDP': 'GDP', 'Growth': 'GDP Annual Growth Rate', 'Rate': 'Interest Rate', 'Inflation': 'Inflation Rate', 'Markets': 'Stock Market', 'Currencies': 'Currency', 'Tax': 'Corporate Tax Rate','COVID-19':'Coronavirus Cases'
  },
  economyIndicatorsWithoutGDP :{
    'Growth': 'GDP Annual Growth Rate', 'Rate': 'Interest Rate', 'Inflation': 'Inflation Rate', 'Markets': 'Stock Market', 'Currencies': 'Currency', 'Tax': 'Corporate Tax Rate','COVID-19':'Coronavirus Cases'
  },
  countriesList : [
    { "name": "Afghanistan", "code": "AF" },
    { "name": "Albania", "code": "AL" },
    { "name": "Algeria", "code": "DZ" },
    { "name": "American Samoa", "code": "AS" },
    { "name": "AndorrA", "code": "AD" },
    { "name": "Angola", "code": "AO" },
    { "name": "Anguilla", "code": "AI" },
    { "name": "Antarctica", "code": "AQ" },
    { "name": "Antigua and Barbuda", "code": "AG" },
    { "name": "Argentina", "code": "AR" },
    { "name": "Armenia", "code": "AM" },
    { "name": "Aruba", "code": "AW" },
    { "name": "Australia", "code": "AU" },
    { "name": "Austria", "code": "AT" },
    { "name": "Azerbaijan", "code": "AZ" },
    { "name": "Bahamas", "code": "BS" },
    { "name": "Bahrain", "code": "BH" },
    { "name": "Bangladesh", "code": "BD" },
    { "name": "Barbados", "code": "BB" },
    { "name": "Belarus", "code": "BY" },
    { "name": "Belgium", "code": "BE" },
    { "name": "Belize", "code": "BZ" },
    { "name": "Benin", "code": "BJ" },
    { "name": "Bermuda", "code": "BM" },
    { "name": "Bhutan", "code": "BT" },
    { "name": "Bolivia", "code": "BO" },
    { "name": "Bosnia and Herzegovina", "code": "BA" },
    { "name": "Botswana", "code": "BW" },
    { "name": "Bouvet Island", "code": "BV" },
    { "name": "Brazil", "code": "BR" },
    { "name": "British Indian Ocean Territory", "code": "IO" },
    { "name": "Brunei Darussalam", "code": "BN" },
    { "name": "Bulgaria", "code": "BG" },
    { "name": "Burkina Faso", "code": "BF" },
    { "name": "Burundi", "code": "BI" },
    { "name": "Cambodia", "code": "KH" },
    { "name": "Cameroon", "code": "CM" },
    { "name": "Canada", "code": "CA" },
    { "name": "Cape Verde", "code": "CV" },
    { "name": "Cayman Islands", "code": "KY" },
    { "name": "Central African Republic", "code": "CF" },
    { "name": "Chad", "code": "TD" },
    { "name": "Chile", "code": "CL" },
    { "name": "China", "code": "CN" },
    { "name": "Christmas Island", "code": "CX" },
    { "name": "Cocos (Keeling) Islands", "code": "CC" },
    { "name": "Colombia", "code": "CO" },
    { "name": "Comoros", "code": "KM" },
    { "name": "Congo", "code": "CG" },
    { "name": "Congo, The Democratic Republic of the", "code": "CD" },
    { "name": "Cook Islands", "code": "CK" },
    { "name": "Costa Rica", "code": "CR" },
    { "name": "Cote DIvoire", "code": "CI" },
    { "name": "Croatia", "code": "HR" },
    { "name": "Cuba", "code": "CU" },
    { "name": "Cyprus", "code": "CY" },
    { "name": "Czech Republic", "code": "CZ" },
    { "name": "Denmark", "code": "DK" },
    { "name": "Djibouti", "code": "DJ" },
    { "name": "Dominica", "code": "DM" },
    { "name": "Dominican Republic", "code": "DO" },
    { "name": "Ecuador", "code": "EC" },
    { "name": "Egypt", "code": "EG" },
    { "name": "El Salvador", "code": "SV" },
    { "name": "Equatorial Guinea", "code": "GQ" },
    { "name": "Eritrea", "code": "ER" },
    { "name": "Estonia", "code": "EE" },
    { "name": "Ethiopia", "code": "ET" },
    { "name": "Falkland Islands (Malvinas)", "code": "FK" },
    { "name": "Faroe Islands", "code": "FO" },
    { "name": "Fiji", "code": "FJ" },
    { "name": "Finland", "code": "FI" },
    { "name": "France", "code": "FR" },
    { "name": "French Guiana", "code": "GF" },
    { "name": "French Polynesia", "code": "PF" },
    { "name": "French Southern Territories", "code": "TF" },
    { "name": "Gabon", "code": "GA" },
    { "name": "Gambia", "code": "GM" },
    { "name": "Georgia", "code": "GE" },
    { "name": "Germany", "code": "DE" },
    { "name": "Ghana", "code": "GH" },
    { "name": "Gibraltar", "code": "GI" },
    { "name": "Greece", "code": "GR" },
    { "name": "Greenland", "code": "GL" },
    { "name": "Grenada", "code": "GD" },
    { "name": "Guadeloupe", "code": "GP" },
    { "name": "Guam", "code": "GU" },
    { "name": "Guatemala", "code": "GT" },
    { "name": "Guernsey", "code": "GG" },
    { "name": "Guinea", "code": "GN" },
    { "name": "Guinea-Bissau", "code": "GW" },
    { "name": "Guyana", "code": "GY" },
    { "name": "Haiti", "code": "HT" },
    { "name": "Heard Island and Mcdonald Islands", "code": "HM" },
    { "name": "Holy See (Vatican City State)", "code": "VA" },
    { "name": "Honduras", "code": "HN" },
    { "name": "Hong Kong", "code": "HK" },
    { "name": "Hungary", "code": "HU" },
    { "name": "Iceland", "code": "IS" },
    { "name": "India", "code": "IN" },
    { "name": "Indonesia", "code": "ID" },
    { "name": "Iran, Islamic Republic Of", "code": "IR" },
    { "name": "Iraq", "code": "IQ" },
    { "name": "Ireland", "code": "IE" },
    { "name": "Isle of Man", "code": "IM" },
    { "name": "Israel", "code": "IL" },
    { "name": "Italy", "code": "IT" },
    { "name": "Jamaica", "code": "JM" },
    { "name": "Japan", "code": "JP" },
    { "name": "Jersey", "code": "JE" },
    { "name": "Jordan", "code": "JO" },
    { "name": "Kazakhstan", "code": "KZ" },
    { "name": "Kenya", "code": "KE" },
    { "name": "Kiribati", "code": "KI" },
    { "name": "Korea, Democratic PeopleS Republic of", "code": "KP" },
    { "name": "Korea, Republic of", "code": "KR" },
    { "name": "Kuwait", "code": "KW" },
    { "name": "Kyrgyzstan", "code": "KG" },
    { "name": "Lao PeopleS Democratic Republic", "code": "LA" },
    { "name": "Latvia", "code": "LV" },
    { "name": "Lebanon", "code": "LB" },
    { "name": "Lesotho", "code": "LS" },
    { "name": "Liberia", "code": "LR" },
    { "name": "Libyan Arab Jamahiriya", "code": "LY" },
    { "name": "Liechtenstein", "code": "LI" },
    { "name": "Lithuania", "code": "LT" },
    { "name": "Luxembourg", "code": "LU" },
    { "name": "Macao", "code": "MO" },
    { "name": "Macedonia, The Former Yugoslav Republic of", "code": "MK" },
    { "name": "Madagascar", "code": "MG" },
    { "name": "Malawi", "code": "MW" },
    { "name": "Malaysia", "code": "MY" },
    { "name": "Maldives", "code": "MV" },
    { "name": "Mali", "code": "ML" },
    { "name": "Malta", "code": "MT" },
    { "name": "Marshall Islands", "code": "MH" },
    { "name": "Martinique", "code": "MQ" },
    { "name": "Mauritania", "code": "MR" },
    { "name": "Mauritius", "code": "MU" },
    { "name": "Mayotte", "code": "YT" },
    { "name": "Mexico", "code": "MX" },
    { "name": "Micronesia, Federated States of", "code": "FM" },
    { "name": "Moldova, Republic of", "code": "MD" },
    { "name": "Monaco", "code": "MC" },
    { "name": "Mongolia", "code": "MN" },
    { "name": "Montserrat", "code": "MS" },
    { "name": "Morocco", "code": "MA" },
    { "name": "Mozambique", "code": "MZ" },
    { "name": "Myanmar", "code": "MM" },
    { "name": "Namibia", "code": "NA" },
    { "name": "Nauru", "code": "NR" },
    { "name": "Nepal", "code": "NP" },
    { "name": "Netherlands", "code": "NL" },
    { "name": "Netherlands Antilles", "code": "AN" },
    { "name": "New Caledonia", "code": "NC" },
    { "name": "New Zealand", "code": "NZ" },
    { "name": "Nicaragua", "code": "NI" },
    { "name": "Niger", "code": "NE" },
    { "name": "Nigeria", "code": "NG" },
    { "name": "Niue", "code": "NU" },
    { "name": "Norfolk Island", "code": "NF" },
    { "name": "Northern Mariana Islands", "code": "MP" },
    { "name": "Norway", "code": "NO" },
    { "name": "Oman", "code": "OM" },
    { "name": "Pakistan", "code": "PK" },
    { "name": "Palau", "code": "PW" },
    { "name": "Palestinian Territory, Occupied", "code": "PS" },
    { "name": "Panama", "code": "PA" },
    { "name": "Papua New Guinea", "code": "PG" },
    { "name": "Paraguay", "code": "PY" },
    { "name": "Peru", "code": "PE" },
    { "name": "Philippines", "code": "PH" },
    { "name": "Pitcairn", "code": "PN" },
    { "name": "Poland", "code": "PL" },
    { "name": "Portugal", "code": "PT" },
    { "name": "Puerto Rico", "code": "PR" },
    { "name": "Qatar", "code": "QA" },
    { "name": "Reunion", "code": "RE" },
    { "name": "Romania", "code": "RO" },
    { "name": "Russian Federation", "code": "RU" },
    { "name": "RWANDA", "code": "RW" },
    { "name": "Saint Helena", "code": "SH" },
    { "name": "Saint Kitts and Nevis", "code": "KN" },
    { "name": "Saint Lucia", "code": "LC" },
    { "name": "Saint Pierre and Miquelon", "code": "PM" },
    { "name": "Saint Vincent and the Grenadines", "code": "VC" },
    { "name": "Samoa", "code": "WS" },
    { "name": "San Marino", "code": "SM" },
    { "name": "Sao Tome and Principe", "code": "ST" },
    { "name": "Saudi Arabia", "code": "SA" },
    { "name": "Senegal", "code": "SN" },
    { "name": "Serbia and Montenegro", "code": "CS" },
    { "name": "Seychelles", "code": "SC" },
    { "name": "Sierra Leone", "code": "SL" },
    { "name": "Singapore", "code": "SG" },
    { "name": "Slovakia", "code": "SK" },
    { "name": "Slovenia", "code": "SI" },
    { "name": "Solomon Islands", "code": "SB" },
    { "name": "Somalia", "code": "SO" },
    { "name": "South Africa", "code": "ZA" },
    { "name": "South Georgia and the South Sandwich Islands", "code": "GS" },
    { "name": "Spain", "code": "ES" },
    { "name": "Sri Lanka", "code": "LK" },
    { "name": "Sudan", "code": "SD" },
    { "name": "Suriname", "code": "SR" },
    { "name": "Svalbard and Jan Mayen", "code": "SJ" },
    { "name": "Swaziland", "code": "SZ" },
    { "name": "Sweden", "code": "SE" },
    { "name": "Switzerland", "code": "CH" },
    { "name": "Syrian Arab Republic", "code": "SY" },
    { "name": "Taiwan, Province of China", "code": "TW" },
    { "name": "Tajikistan", "code": "TJ" },
    { "name": "Tanzania, United Republic of", "code": "TZ" },
    { "name": "Thailand", "code": "TH" },
    { "name": "Timor-Leste", "code": "TL" },
    { "name": "Togo", "code": "TG" },
    { "name": "Tokelau", "code": "TK" },
    { "name": "Tonga", "code": "TO" },
    { "name": "Trinidad and Tobago", "code": "TT" },
    { "name": "Tunisia", "code": "TN" },
    { "name": "Turkey", "code": "TR" },
    { "name": "Turkmenistan", "code": "TM" },
    { "name": "Turks and Caicos Islands", "code": "TC" },
    { "name": "Tuvalu", "code": "TV" },
    { "name": "Uganda", "code": "UG" },
    { "name": "Ukraine", "code": "UA" },
    { "name": "United Arab Emirates", "code": "AE" },
    { "name": "United Kingdom", "code": "GB" },
    { "name": "United States", "code": "US" },
    { "name": "United States Minor Outlying Islands", "code": "UM" },
    { "name": "Uruguay", "code": "UY" },
    { "name": "Uzbekistan", "code": "UZ" },
    { "name": "Vanuatu", "code": "VU" },
    { "name": "Venezuela", "code": "VE" },
    { "name": "Viet Nam", "code": "VN" },
    { "name": "Virgin Islands, British", "code": "VG" },
    { "name": "Virgin Islands, U.S.", "code": "VI" },
    { "name": "Wallis and Futuna", "code": "WF" },
    { "name": "Western Sahara", "code": "EH" },
    { "name": "Yemen", "code": "YE" },
    { "name": "Zambia", "code": "ZM" },
    { "name": "Zimbabwe", "code": "ZW" }
  ],
}