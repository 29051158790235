<template>
    <header>
        <div class="header-content header-mobile">
            <div class="header-top container-fluid no-shadow">
                <div class="module container width-5">
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="section-title mb-0">
                            <button class="button button-text">
                                <h5 class="d-flex">
                                    <div class="icon mr-3" @click="$router.go(-1)">
                                        <svg width="25" version="1.0" viewBox="0 0 470 342" xmlns="http://www.w3.org/2000/svg">
                                            <g transform="translate(0 342) scale(.1 -.1)">
                                                <path d="m1630 3407c-27-8-212-187-813-786-523-522-784-789-797-817-26-53-26-135 0-188 28-58 1551-1579 1602-1601 52-21 140-19 186 4 106 55 152 182 103 286-13 27-205 225-590 610l-571 570 3795 10 45 25c24 14 58 45 75 68 27 40 30 51 30 122s-3 82-30 122c-17 23-51 54-75 68l-45 25-3795 10 566 565c311 311 573 581 583 600 46 89 33 178-38 250-63 66-140 85-231 57z"></path>
                                            </g>
                                        </svg>
                                    </div> Copy Premium Wallet
                                </h5>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <section id="main-wrapper">
        <div class="container-fluid section-small">
            <div class="module container width-5">
                <div class="row-list-mb4">
                    <div class="section-par mb20">
                        <h4>+0.000$</h4>
                    </div>
                    <div class="com_select-box select-box-v3">
                        <select name="" id="">
                            <option value="">Transfer to Account</option>
                            <option value="">Sample 1</option>
                            <option value="">Sample 2</option>
                            <option value="">Sample 3</option>
                        </select>
                    </div>
                    <div class="tr-bo-box">
                        <div class="section-title">
                            <h5>All Time Stats</h5>
                        </div>
                        <ul class="row-list-mb2">
                            <li>
                                <div class="tr-bo-set set-small shadow-none">
                                    <div>
                                        <div class="row align-items-center">
                                            <div class="col">
                                                <div class="tb-se-title">
                                                    <div class="icon icon-small bg-purple"></div>
                                                    <div class="section-par par-blue-horizon">
                                                        <h5 class="mb-0">0</h5>
                                                        <p>Total Copiers</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-auto">
                                                <button class="button button-text text-blue-horizon">
                                                    <h3><i class="fa fa-info-circle"></i></h3>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="tr-bo-set set-small shadow-none">
                                    <div>
                                        <div class="row align-items-center">
                                            <div class="col">
                                                <div class="tb-se-title">
                                                    <div class="icon icon-small bg-purple"></div>
                                                    <div class="section-par par-blue-horizon">
                                                        <h5 class="mb-0">0</h5>
                                                        <p>Copied Trades</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-auto">
                                                <button class="button button-text text-blue-horizon">
                                                    <h3><i class="fa fa-info-circle"></i></h3>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="tr-bo-set set-small shadow-none">
                                    <div>
                                        <div class="row align-items-center">
                                            <div class="col">
                                                <div class="tb-se-title">
                                                    <div class="icon icon-small bg-purple"></div>
                                                    <div class="section-par par-blue-horizon">
                                                        <h5 class="mb-0">0</h5>
                                                        <p>Copied Volume</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-auto">
                                                <button class="button button-text text-blue-horizon">
                                                    <h3><i class="fa fa-info-circle"></i></h3>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="tb-se-tag tag-blue-horizon-2 mt-1">Current Trading Account</div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="tr-bo-set set-small shadow-none">
                                    <div>
                                        <div class="row align-items-center">
                                            <div class="col">
                                                <div class="tb-se-title">
                                                    <div class="icon icon-small bg-purple"></div>
                                                    <div class="section-par par-blue-horizon">
                                                        <h5 class="mb-0">0</h5>
                                                        <p>Total Earned</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-auto">
                                                <button class="button button-text text-blue-horizon">
                                                    <h3><i class="fa fa-info-circle"></i></h3>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="tr-bo-set set-small shadow-none">
                                    <div>
                                        <div class="row align-items-center">
                                            <div class="col">
                                                <div class="tb-se-title">
                                                    <div class="icon icon-small bg-purple"></div>
                                                    <div class="section-par par-blue-horizon">
                                                        <h5 class="mb-0">0</h5>
                                                        <p>Pending Amounts</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-auto">
                                                <button class="button button-text text-blue-horizon">
                                                    <h3><i class="fa fa-info-circle"></i></h3>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="tb-se-tag tag-blue-horizon-2 mt-1">Current Trading Account</div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="row-list-mb2">
                        <div class="me-co-banner bg-black bg-gradient-blue-2">
                            <div class="com_banner bp-ab" style="background-image: url('/assets/img/mobile/banner_copy_premium.png'); background-size: auto 100%; background-position: calc(100% + 1px) 50%;"></div>
                            <div class="me-co-banner-main main-height-v2 bp-rel d-flex align-items-center">
                                <div class="row">
                                    <div class="col-8">
                                        <div class="section-par mb-0">
                                            <h4 class="mb-1">Copy Premium</h4>
                                            <h6 class="co-blue-horizon">Copy Premium allows you to get paid by getting copied on your trades</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="fc-su-box ml-0 mr-0">
                            <div class="section-par">
                                <h5 class="mb-1">Your Copiers</h5>
                                <h6 class="co-blue-horizon">No Copiers to Show.</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default{};
</script>