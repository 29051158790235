<template>
    <header>
        <div class="header-content header-mobile">
            <div class="header-top container-fluid ">
                <div class="module container width-5">
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="section-title mb-0">
                            <button class="button button-text">
                                <h5 class="d-flex">
                                    <div class="icon mr-3" @click="$router.go(-1)">
                                        <svg width="25" version="1.0" viewBox="0 0 470 342" xmlns="http://www.w3.org/2000/svg">
                                            <g transform="translate(0 342) scale(.1 -.1)">
                                                <path d="m1630 3407c-27-8-212-187-813-786-523-522-784-789-797-817-26-53-26-135 0-188 28-58 1551-1579 1602-1601 52-21 140-19 186 4 106 55 152 182 103 286-13 27-205 225-590 610l-571 570 3795 10 45 25c24 14 58 45 75 68 27 40 30 51 30 122s-3 82-30 122c-17 23-51 54-75 68l-45 25-3795 10 566 565c311 311 573 581 583 600 46 89 33 178-38 250-63 66-140 85-231 57z"></path>
                                            </g>
                                        </svg>
                                    </div> Trading Accounts
                                </h5>
                            </button>
                        </div>
                        <ul class="he-to-ctrl">
                            <li>
                                <a class="ht-ct-icon" href="javascript:void(0)" @click="createAcc = demo_live">
                                    <svg width="18" version="1.0" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                                        <g transform="translate(0 512) scale(.1 -.1)">
                                            <path d="m2442 5095c-29-15-64-41-77-59-58-76-55-12-55-1166v-1060l-1073-2-1074-3-49-30c-30-19-60-49-79-79-27-45-30-57-30-136s3-91 30-136c19-30 49-60 79-79l49-30 1074-3 1073-2 2-1073 3-1074 30-49c19-30 49-60 79-79 45-27 57-30 136-30s91 3 136 30c30 19 60 49 79 79l30 49 3 1074 2 1073 1073 2 1074 3 49 30c30 19 60 49 79 79 27 45 30 57 30 136s-3 91-30 136c-19 30-49 60-79 79l-49 30-1074 3-1073 2-2 1073-3 1074-30 49c-46 74-109 108-204 112-66 3-83 0-129-23z" />
                                        </g>
                                    </svg>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="module container width-5">
                    <div class="he-mo-nav">
                        <div class="com_draggable">
                            <div class="com_draggable-main">
                                <ul class="pr-nav-list no-wrap">
                                    <li class="item" v-for="item in accTypes" :class="[{'active': demo_live == item }]">
                                        <a href="javascript:void(0)" @click.prevent="demo_live = item">{{item}}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <section id="main-wrapper" style="padding-top: 99px; padding-bottom: 67px;">
        <app-loader v-if="store.loading"></app-loader>
        <div class="container-fluid section-small">
            <div class="module container width-5">
                <ul class="row-list-mb1" v-if="tradingAccounts.length > 0">
                    <li v-for="item, i in tradingAccounts">
                        <div class="tr-bo-set pt-3 pb-3">
                            <div>
                                <!-- <div class="section-title">
                                    <h6 class="co-blue-horizon">Currently Logged In</h6>
                                </div> -->
                                <div class="row">
                                    <div class="col">
                                        <div class="tb-se-title align-items-start">
                                            <div class="icon bg-dark-blue" style="background-image: url('../assets/img/icon_copyrack.png');"></div>
                                            <div class="section-par">
                                                <h6 class="mb-1">Copytrade {{item.currency}}</h6>
                                                <!-- <h5 class="mb-1 fw-500 co-pure-apple">10,000.00 USD</h5> -->
                                                <div class="d-flex">
                                                    <div class="tb-se-tags align-items-center">
                                                        <h6 class="mb-0 mr-2 co-blue-horizon">#{{item.account_id}}</h6>
                                                        <div class="tb-se-tag tag-v2">{{item.platform}}</div>
                                                        <div class="tb-se-tag tag-v2 tag-blue-horizon">{{demo_live}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-auto">
                                        <div class="com_dropdown" :class="[{'selected':selIndex == i}]">
                                            <button class="button button-text text-blue-horizon cr-wa-more more-btn" @click="(selIndex == i)?(selIndex = -1):(selIndex = i)">
                                                <i class="far fa-ellipsis-v"></i>
                                            </button>
                                            <div class="com_dropdown-content width-2 more-btn">
                                                <ul>
                                                    <!-- <li><router-link to="trading-accounts-edit">Edit</router-link></li> -->
                                                    <!-- <li><router-link to="trading-accounts-show-password">Show password</router-link></li> -->
                                                    <li><router-link :to="{'path':'/trading-accounts-change-password',query:{ id: item.id }}">Change password</router-link></li>
                                                    <li><router-link :to="{'path':'/deposit',query:{ id: item.id }}">Deposit</router-link></li>
                                                    <li><router-link :to="{'path':'/withdrawal',query:{ id: item.id }}">Withdrawal</router-link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                <div class="fc-su-box box-medium ml-0 mr-0" v-else>
                    <div class="section-title par-blue-horizon text-center mb-4">
                        <h6 class="mb-1">No {{demo_live}} Trading Account Found</h6>
                        <p>You have currently no {{demo_live}} Trading Account.</p>
                    </div>
                    <div>
                        <a class="button button-quaternary button-wide button-purple" href="javascript:void(0)" @click="createAcc = demo_live">
                            <i class="fal fa-plus mr-2"></i> Create {{demo_live}} Account
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="com_popup" v-if="createAcc">
            <div class="com_popup-cover" style="opacity: 1;"></div>
            <div class="com_popup-main" style="transform: translate(0px, 0px);">
                <div id="popup-create-price-alert" class="popup-content container-fluid">
                    <a class="com_popup-btn btn-close" href="javascript:void(0)" @click="createAcc = ''"><i class="far fa-times"></i></a>
                    <div class="container width-5">
                        <div class="section-title mb40">
                            <h6 class="fw-600">Create {{createAcc}} Account</h6>
                        </div>
                        <Form class="row-list-mb25" @submit="createAccount()">
                            <div class="form-group">
                                <div>
                                    <ul>
                                        <li v-for="item in accTypes">
                                            <label :for="item+' Account'">
                                                <input type="radio" :id="item+' Account'" name="Account Type" :value="item" v-model="createAcc" class="mr-2">{{item}}
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                                <div class="mb-3">
                                    <Field as="select" class="form-control" name="Account Type" v-model="account_type" rules="required">
                                        <option value="" disabled>Select account type</option>
                                        <option value="ECN">ECN</option>
                                        <option value="STP">STP</option>
                                    </Field>
                                    <ErrorMessage name="Account Type" />
                                </div>
                                <div class="mb-3">
                                    <Field as="select" class="form-control" name="Platform" v-model="platform" rules="required" @change="select_currency = ''">
                                        <option value="" disabled>Select Platform</option>
                                        <option value="ACT">ACT</option>
                                        <option value="MT4">MT4</option>
                                        <option value="MT5">MT5</option>
                                    </Field>
                                    <ErrorMessage name="Platform" />
                                </div>
                                <div class="mb-3">
                                    <Field as="select" class="form-control" name="Leverage" v-model="leverage" rules="required">
                                        <option value="" disabled>Select Leverage</option>
                                        <option value="100">100X</option>
                                        <option value="200">200X</option>
                                        <option value="300">300X</option>
                                        <option value="400">400X</option>
                                        <option value="500">500X</option>
                                    </Field>
                                    <ErrorMessage name="Leverage" />
                                </div>
                                <div class="mb-3">
                                    <Field as="select" class="form-control" name="Currency" v-model="select_currency" rules="required">
                                        <option value="" disabled> Select Currency</option>
                                        <option v-for="(value,key) in currencyList" :value="key">{{ value }}</option>
                                    </Field>
                                    <ErrorMessage name="Currency" />
                                </div>
                            </div>
                            <div class="form-group">
                                <button type="submit" class="button button-quaternary button-wide button-purple">Create Account</button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { useMainStore } from '@/store/index'
import { Field, Form, ErrorMessage } from 'vee-validate';
import { useToast } from 'vue-toastification'
export default{
    setup(){
        return { store: useMainStore(), toast: useToast() }
    },
    components: { Field, Form, ErrorMessage },
    data(){
        return{
            demo_live:'Live',
            accTypes:['Live','Demo'],
            createAcc:'',

            select_currency: '',
            account_type: '',
            platform: '',
            leverage: '',
            selIndex : -1,
        }
    },
    computed:{
        tradingAccounts(){
            return (this.store.tradingAccounts.records || []).filter(v=>v.demo_live == this.demo_live.toLowerCase())
        },
        currencyList(){
            if(this.platform == 'MT5'){
                return this.static_vars.cryptoCurrency
            }else if(this.platform == 'MT4' || this.platform == 'ACT'){
                return this.static_vars.currencyList
            }else{
                return this.static_vars.currencyList
            }
        }
    },
    methods:{
        createAccount(){
            this.store.createTradingAccount({
                'currency' :this.select_currency,
                'account_type' : this.account_type,
                'platform' : this.platform,
                'leverage' : this.leverage,
                'demo_live' : (this.createAcc == 'Live'?'1':'0')
            }, true).then(res=>{
                console.log(res)
                this.toast(`${this.createAcc} Account created successfully`, { 'type': 'success' } );
                this.createAcc = ''
                this.getList()
            }).catch(e=>{})
        },
        getList(){
            this.store.getTradingAccountList({
                filter: {},
                sort: [],
                search: '',
                page: 1,
                perPage: 300
            }.true).then(res=>{ /*console.log(res);*/ }).catch(e=>{ })
        }
    },
    mounted(){
        let _that = this;
        $(document).on("click", function (event) {
            if (!$(event.target).closest(".more-btn").length) {
                _that.selIndex = -1;
            }
        });
    },
    created(){
        this.getList()
    },
}
</script>