import axios from 'axios'
import { useToast } from 'vue-toastification'
import { useMainStore } from '@/store'
import router from '@/router'

const toast = useToast()

const handleUnauthorizedToken = () => {
    const store = useMainStore()
    toast('Authentication expired. Please login again', { type: 'error' })
    store.$reset()
    router.replace('/')
}


export const post = (url, params) => {
    return new Promise((resolve, reject) => {
        const store = useMainStore()
        const headers = {}
        if (store.user && store.user.token) {
            headers['Authorization'] = `Bearer ${store.user.token}`
        }
        axios.post(
            url,
            params,
            { headers }
        ).then(response => {
            try {
            if (response.data.info && response.data.info == 'NOK') {
                toast(response.data.msg.msg, {
                    type: 'error'
                })
                reject(response.data)
            } else {
                resolve(response.data.data)
            }
            } catch(error) {
                console.log(error)
            }
        }).catch(error => {
            if (error.response && error.response.status && error.response.status === 401) {
                handleUnauthorizedToken()
                reject(error)
            } else {
                toast('Something went wrong. Please try again', {
                    type: 'error'
                })
                reject(error)
            }
        })
    })
}

export const get = (url,params,isAuth = true) => {
    return new Promise((resolve, reject) => {
        const store = useMainStore()
        const headers = {}
        if (isAuth && store.user && store.user.token) { headers['Authorization'] = `Bearer ${store.user.token}` }
        axios.get(
            url,
            {params}
        ).then(response => {
            if (response.info && response.info == 'NOK') {
                toast(response.data.msg.msg, {
                    type: 'error'
                })
                reject(response.data)
            } else {
                resolve(response.data)
            }
        }).catch(error => {
            if (error.response && error.response.status && error.response.status === 401) {
                handleUnauthorizedToken()
                reject(error)
            } else {
                toast('Something went wrong. Please try again', {
                    type: 'error'
                })
                reject({ ...error })
            }
        })
    })
}