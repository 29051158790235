<template>
    <section class="d-flex align-items-center justify-content-center" id="main-wrapper" style="min-height: 100vh;padding: 40px;">
        <app-loader v-if="store.loading"></app-loader>
        <div class="container-fluid section-small">
            <div class="module container width-5 login-card card box-shadow rounded bg-white" >
                <div class="logo d-flex mx-4 mt-5">
                    <router-link to="/"><img src="/assets/img/logo.png" width="55" alt=""></router-link>
                    <h5 class="ml-4">Login</h5>  
                </div>
                
                <Form class="m-4 mb-5" @submit="submit()">
                    <div class="mb-3">
                        <label for="fname" class="form-label font-weight-bold">User Name:</label>
                        <Field type="text" name="User Name" v-model="email" rules="required" class="form-control"/>
                        <ErrorMessage name="User Name" />
                    </div>
                    
                    <div class="mb-3">
                        <label for="lname" class="form-label font-weight-bold">Password:</label>
                        <Field type="text" name="Password" v-model="password" rules="required" class="form-control"/>
                        <ErrorMessage name="Password" />
                    </div>
                    
                    <div class="mb-3">
                        <input class="btn button-primary px-5" type="submit" value="Login">
                    </div>
                    Don't have user? <router-link to="/register">Register here</router-link>
                </Form>
            </div>
        </div>
    </section>
</template>
<script>
const crypto = require('crypto');
import { Field, Form, ErrorMessage } from 'vee-validate';
import { useMainStore } from '@/store/index'
export default{
    setup(){
        return { store: useMainStore() }
    },
    data(){
        return{
            Loader : false,
            email: "gsachdeva@yopmail.com",
            password: "Abc@123",
            
            code : '',
            gAuthenticatorEnabled : false,
        }
    },
    components: { Field, Form, ErrorMessage },
    methods:{
        submit(){
            this.store.login({
                "email": this.email,
                "password": this.password,
            }, true).then(res=>{ }).catch(e=>{ })
        },
        submit2Fa(){
            const mykey = crypto.createCipher('aes-128-cbc', 'F*V(I)e^E#1108@3');
            let hashedstr = mykey.update(this.password, 'utf8', 'hex')
            hashedstr += mykey.final('hex');
            console.log('0000',hashedstr)
        }
    }
};
</script>