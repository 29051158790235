<template>
    <header>
        <div class="header-content header-mobile">
            <div class="header-top container-fluid ">
                <div class="module container width-5">
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="section-title mb-0">
                            <button class="button button-text">
                                <h5 class="d-flex">
                                    <div class="icon mr-3" @click="$router.go(-1)">
                                        <svg width="25" version="1.0" viewBox="0 0 470 342" xmlns="http://www.w3.org/2000/svg">
                                            <g transform="translate(0 342) scale(.1 -.1)">
                                                <path d="m1630 3407c-27-8-212-187-813-786-523-522-784-789-797-817-26-53-26-135 0-188 28-58 1551-1579 1602-1601 52-21 140-19 186 4 106 55 152 182 103 286-13 27-205 225-590 610l-571 570 3795 10 45 25c24 14 58 45 75 68 27 40 30 51 30 122s-3 82-30 122c-17 23-51 54-75 68l-45 25-3795 10 566 565c311 311 573 581 583 600 46 89 33 178-38 250-63 66-140 85-231 57z"></path>
                                            </g>
                                        </svg>
                                    </div> test
                                </h5>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <section id="main-wrapper" style="padding-top: 50px; padding-bottom: 67px;">
        <div class="container-fluid section-small">
            <div class="module container width-5">
                <div v-if="liveAccount.length > 0">
                    <Form class="row-list-mb25" @submit="depositRequest()">
                        <div class="form-group">
                            {{account_selected}}
                            <TradingAccounts v-model="account_selected" @change="change()"/>
                        </div>
                        <div class="form-group">
                            <button type="submit" class="button button-quaternary button-wide button-purple">Submit</button>
                        </div>
                    </Form>
                </div>
                <div class="fc-su-box box-medium ml-0 mr-0" v-else>
                    <div class="section-title par-blue-horizon text-center mb-4">
                        <h6 class="mb-1">No live Trading Account Found</h6>
                        <p>You have currently no Live Trading Account.</p>
                    </div>
                    <div>
                        <router-link to="/trading-accounts" class="button button-quaternary button-wide button-purple">
                            <i class="fal fa-plus mr-2"></i> Create Account
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { useMainStore } from '@/store/index'
import { Field, Form, ErrorMessage } from 'vee-validate';
import { useToast } from 'vue-toastification'
import TradingAccounts from '@/components/trading-accounts.vue'
export default{
    setup(){
        return { store: useMainStore(), toast: useToast() }
    },
    components: { Field, Form, ErrorMessage, TradingAccounts },
    data(){
        return{
            account_selected : {},
        }
    },
    computed:{
        liveAccount(){
            return (this.store.tradingAccounts.records || []).filter(v=>v.demo_live == 'live')
        },
    },
    methods:{
        change(){
            console.log('---------------->',this.account_selected);
        },
        getList(){
            this.store.getTradingAccountList({
                filter: {},
                sort: [],
                search: '',
                page: 1,
                perPage: 300
            }).then(res=>{
                console.log(res);
                this.freeMargin = res.margin_detail.balance;
            }).catch(e=>{ })
        },
        getTradingAccountDetail(){
            this.store.getTradingAccountDetail({
                'account_id': this.account_selected.account_id,
                'act_account_id': this.account_selected.act_account_id,
                'demo_live': this.account_selected.demo_live,
                'platform': this.account_selected.platform
            }).then(res=>{ }).catch(e=>{})
        },
    },
    created(){
        this.getList()
    },
}
</script>