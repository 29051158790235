<template>
     <header>
        <div class="header-content header-mobile">
            <div class="header-top container-fluid ">
                <div class="module container width-5">
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="section-title mb-0">
                            <h5>Trades</h5>
                        </div>
                        <ul class="he-to-ctrl">
                            <li><a class="ht-ct-icon" href="javascript:void(0)"><i class="far fa-search ml-0"></i></a></li>
                        </ul>
                    </div>
                </div>
                <div class="module container width-5">
                    <div class="he-mo-nav">
                        <div class="com_draggable">
                            <div class="com_draggable-main">
                                <ul class="pr-nav-list no-wrap">
                                    <li class="item" v-for="i,key in isinsTypes" :class="[{'active': selectedType == key }]">
                                        <a href="javascript:void(0)" @click.prevent="selectedType = key">{{key}}</a>
                                    </li>
                                    <!-- <li class="item"><a href="#">Favourites</a></li>
                                    <li class="item"><a href="#">Most traded</a></li>
                                    <li class="item"><a href="#">Real-Stocks USA</a></li>
                                    <li class="item"><a href="#">Real-Stocks Germany</a></li>
                                    <li class="item"><a href="#">Real-Stocks Spain</a></li>
                                    <li class="item"><a href="#">Real-Stocks Hong Kong</a></li>
                                    <li class="item"><a href="#">Real-Stocks France</a></li>
                                    <li class="item"><a href="#">Cryptos</a></li>
                                    <li class="item"><a href="#">ETFs</a></li>
                                    <li class="item"><a href="#">Indices Futures</a></li> -->
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <section id="main-wrapper" style="padding-top: 99px; padding-bottom: 124px;">
        <div class="container-fluid section-small">
            <div class="module container width-5">
                <div class="fc-su-box box-small">
                    <div class="section-title par-blue-horizon">
                        <h5 class="mb-1 fw-500">Tade with real Money</h5>
                        <p>Enter The globe markets and trade with real money</p>
                    </div>
                    <router-link to="/deposit" class="button button-quaternary button-wide button-purple">Add real money</router-link>
                </div>
            </div>
            <div class="module container width-5">
                <ul class="row-list-mb15">
                    <li v-for="item in searchisins">
                        <div class="tr-bo-set">
                            <div>
                                <div class="tb-se-title align-items-start">
                                    <div class="icon"></div>
                                    <div class="section-par">
                                        <h6 class="mb-1">{{item.other.Description}}</h6>
                                        <p class="mb-0 co-pure-apple">{{item.SPREAD}}</p>
                                        <div class="d-flex">
                                            <div class="tb-se-tag">{{item.other.type}}</div>
                                            <!-- <button class="button button-star ml-1"><i class="far fa-star"></i></button> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex align-items-center justify-content-end">
                                <div>
                                    <ul class="tb-se-num-list">
                                        <li>
                                            <span>{{item.B}}</span>
                                            <span class="icon"><i class="fas fa-caret-up"></i></span>
                                        </li>
                                        <li>
                                            <span>{{item.A}}</span>
                                            <span class="icon"><i class="fas fa-caret-down"></i></span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="set-auto pl-1"><button class="button button-text text-blue-horizon cr-wa-more"><i class="far fa-ellipsis-v"></i></button></div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </section>
</template>
<script>
import { Const } from "@/plugins/vue-provider-cache/const.mod";
export default {
    data() {
        return {
            search: '',
            showAll: true,
            selectedType:'Forex',
            isinsTypes:{
                'Forex':'getCurrencies',
                'Commodity':'getCommodityAll',
                'Indices':'getIndicesAll',
                'Stocks':'getStocksAll'
            }
        };
    },
    computed: {
        searchisins() {
            try {
                if (this.search != '') {
                    return Object.values(this.isins).filter(val => val.other.isinid.toLowerCase().includes(this.search.toLowerCase()))
                } else {
                    return Object.values(this.isins).slice(0,(this.showAll?8:200))
                }
            } catch (ex) {
                return [];
            }
        },
        isins() {
            try {
                this.$store.getters[Const.GET_HEARTBEATS]["priceFeed"];
                return this.$store.getters[Const.GET_PROVIDERS]["priceFeed"].iCache[this.isinsTypes[this.selectedType]]();
            } catch (ex) {
                return {};
            }
        },
    },
    methods: {
    },
};
</script>