import { createRouter, createWebHistory } from 'vue-router'
import { useMainStore } from '@/store/index'

/*----------- home ----------*/
import home from '@/views/home/index.vue'
import menu from '@/views/home/menu.vue'
/*----------- trades ----------*/
import my_trades from '@/views/trades/my-trades.vue'
import trades from '@/views/trades/trades.vue'
/*----------- user ----------*/
import messenger_message from '@/views/user/messenger-message.vue'
import messenger from '@/views/user/messenger.vue'
import notifications from '@/views/user/notifications.vue'
import price_alerts from '@/views/user/price-alerts.vue'
import profile from '@/views/user/profile.vue'
import afterlogin from '@/views'
import login from '@/views/user/login.vue'
import register from '@/views/user/register.vue'
import documents from '@/views/user/documents.vue'
/*----------- Academy ----------*/
import academy from '@/views/academy/academy.vue'
import academy_index from '@/views/academy/index.vue'
import academy_articles from '@/views/academy/articles.vue'
import academy_ebooks from '@/views/academy/ebooks.vue'
import academy_videos from '@/views/academy/videos.vue'
import academy_webinar_details from '@/views/academy/webinar-details.vue'
import academy_webinars from '@/views/academy/webinar.vue'
/*----------- wallet ----------*/
import copy_premium_wallet from '@/views/wallet/copy-premium-wallet.vue'
import crypto_wallet_recent_transactions from '@/views/wallet/crypto-wallet-recent-transactions.vue'
import crypto_wallet from '@/views/wallet/crypto-wallet.vue'
/*----------- top-flop ----------*/
import daily_tops_flops from '@/views/top-flop/daily-tops-flops.vue'
/*----------- exchange ----------*/
import exchange from '@/views/exchange'
/*----------- help-center ----------*/
import help_center from '@/views/help-center/help-center.vue'
import help_center_index from '@/views/help-center'
import help_center_faq from '@/views/help-center/faq.vue'
import help_center_call_us from '@/views/help-center/call-us.vue'
/*----------- help-center ----------*/
import manage_funds from '@/views/manage-funds/manage-funds.vue'
/*----------- settings ----------*/
import settings from '@/views/user/settings/settings.vue'
import settings_index from '@/views/user/settings'
import settings_edit_profile from '@/views/user/settings/edit-profile.vue'
import settings_general from '@/views/user/settings/general.vue'
import settings_notification_preferences from '@/views/user/settings/notification-preferences.vue'
import settings_security from '@/views/user/settings/security.vue'
/*----------- traders ----------*/
import top_traders from '@/views/traders/top-traders.vue'
import trading_accounts_change_password from '@/views/traders/trading-accounts-change-password.vue'
import trading_accounts_edit from '@/views/traders/trading-accounts-edit.vue'
import trading_accounts_show_password from '@/views/traders/trading-accounts-show-password.vue'
import trading_accounts from '@/views/traders/trading-accounts.vue'
/*----------- deposit-withdrawal ----------*/
import test from '@/views/deposit-withdrawal/test.vue'
import deposit from '@/views/deposit-withdrawal/deposit.vue'
import depositList from '@/views/deposit-withdrawal/deposit-list.vue'
import withdrawal from '@/views/deposit-withdrawal/withdrawal.vue'
import withdrawalList from '@/views/deposit-withdrawal/withdrawal-list.vue'


const routes = [
  /*----------- home ----------*/
  // { path: '/:pathMatch(.*)*', name: 'home',component: home },
  { path: '/', name: 'login',component: login },
  { path: '/register', name: 'register',component: register },
  { path: '/', component: afterlogin, children:[
    { path: 'index', name: 'index',component: home },
    { path: 'menu', name: 'menu',component: menu },
    /*----------- user ----------*/
    { path: 'messenger-message', name: 'messenger-message',component: messenger_message },
    { path: 'messenger', name: 'messenger',component: messenger },
    { path: 'notifications', name: 'notifications',component: notifications },
    { path: 'price-alerts', name: 'price-alerts',component: price_alerts },
    { path: 'profile', name: 'profile',component: profile },
    { path: 'documents', name: 'documents',component: documents },
    /*----------- trades ----------*/
    { path: 'my-trades', name: 'my-trades',component: my_trades },
    { path: 'trades', name: 'trades',component: trades },
    /*----------- Academy ----------*/
    { path: 'academy', name: 'academy',component: academy },
    { path: 'academy', component: academy_index, children:[
      { path: 'articles', name: 'academy-articles',component: academy_articles },
      { path: 'ebooks', name: 'academy-ebooks',component: academy_ebooks },
      { path: 'videos', name: 'academy-videos',component: academy_videos },
      { path: 'webinars', name: 'academy-webinars',component: academy_webinars },
      { path: 'webinar-details', name: 'academy-webinar-details',component: academy_webinar_details },
    ]},
    /*----------- wallet ----------*/
    { path: 'copy-premium-wallet', name: 'copy-premium-wallet',component: copy_premium_wallet },
    { path: 'crypto-wallet-recent-transactions', name: 'crypto-wallet-recent-transactions',component: crypto_wallet_recent_transactions },
    { path: 'crypto-wallet', name: 'crypto-wallet',component: crypto_wallet },
    /*----------- top-flop ----------*/
    { path: 'daily-tops-flops', name: 'daily-tops-flops',component: daily_tops_flops },
    /*----------- exchange ----------*/
    { path: 'exchange', name: 'exchange',component: exchange },
    /*----------- help-center ----------*/
    { path: 'help-center', name: 'help-center',component: help_center },
    { path: 'help-center', component: help_center_index, children:[
      { path: 'faq', name: 'help-center-faq',component: help_center_faq },
      { path: 'call-us', name: 'help-center-call-us',component: help_center_call_us },
    ]},
    /*----------- manage-funds ----------*/
    { path: 'manage-funds', name: 'manage-funds',component: manage_funds },
    /*----------- Settings ----------*/
    { path: 'settings', name: 'settings',component: settings },
    { path: 'settings', component: settings_index, children:[
      { path: 'edit-profile', name: 'Edit Profile',component: settings_edit_profile },
      { path: 'general', name: 'General',component: settings_general },
      { path: 'notification-preferences', name: 'Notification Preferences',component: settings_notification_preferences },
      { path: 'security', name: 'Security',component: settings_security },
    ]},
    { path: 'top-traders', name: 'top-traders',component: top_traders },
    { path: 'trading-accounts-change-password', name: 'trading-accounts-change-password',component: trading_accounts_change_password },
    { path: 'trading-accounts-edit', name: 'trading-accounts-edit',component: trading_accounts_edit },
    { path: 'trading-accounts-show-password', name: 'trading-accounts-show-password',component: trading_accounts_show_password },
    { path: 'trading-accounts', name: 'trading-accounts',component: trading_accounts },
    { path: 'deposit', name: 'deposit',component: deposit },
    { path: 'deposit-list', name: 'deposit-list',component: depositList },
    { path: 'withdrawal', name: 'withdrawal',component: withdrawal },
    { path: 'withdrawal-list', name: 'withdrawal-list',component: withdrawalList },
    { path: 'test', name: 'test',component: test },
  ]}
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from) {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  }
})
router.beforeEach((to, from, next) => {
  let isAuthenticated = useMainStore().user?true:false;
  if(to.name !== 'login' && to.name !== 'register' && !isAuthenticated){
    next({ name: 'login' })
  }else if((to.name === 'login' || to.name === 'register') && isAuthenticated){
    next({ name: 'index' })
  }else {
    next()
  }
})
export default router
