<template>
    <header>
        <div class="header-content header-mobile">
            <div class="header-top container-fluid ">
                <div class="module container width-5">
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="section-title mb-0">
                            <button class="button button-text">
                                <h5 class="d-flex">
                                    <div class="icon mr-3" @click="$router.go(-1)">
                                        <svg width="25" version="1.0" viewBox="0 0 470 342" xmlns="http://www.w3.org/2000/svg">
                                            <g transform="translate(0 342) scale(.1 -.1)">
                                                <path d="m1630 3407c-27-8-212-187-813-786-523-522-784-789-797-817-26-53-26-135 0-188 28-58 1551-1579 1602-1601 52-21 140-19 186 4 106 55 152 182 103 286-13 27-205 225-590 610l-571 570 3795 10 45 25c24 14 58 45 75 68 27 40 30 51 30 122s-3 82-30 122c-17 23-51 54-75 68l-45 25-3795 10 566 565c311 311 573 581 583 600 46 89 33 178-38 250-63 66-140 85-231 57z"></path>
                                            </g>
                                        </svg>
                                    </div> Edit Account
                                </h5>
                            </button>
                        </div>
                        <div>
                            <button class="button button-text text-purple">
                                <h5>Save</h5>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <section id="main-wrapper" style="padding-top: 50px; padding-bottom: 67px;">
        <div class="container-fluid section-small">
            <div class="module container width-5">
                <div class="tr-bo-set set-list">
                    <div>
                        <label>Account Name</label>
                        <input class="form-control tr-ac-input" type="text" name="" value="Copycrack USD">
                    </div>
                    <div>
                        <div class="row">
                            <div class="col">
                                <label class="mb-0">Account Name</label>
                            </div>
                            <div class="col">
                                <div class="tr-ac-select-title">Demo Account</div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="row">
                            <div class="col">
                                <label class="mb-0">Server Type</label>
                            </div>
                            <div class="col">
                                <div class="tr-ac-select-title">MT5</div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="row">
                            <div class="col">
                                <label class="mb-0">Account Currency</label>
                            </div>
                            <div class="col">
                                <div class="tr-ac-select-title">USD</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default{};
</script>