<template>
    <section id="main-wrapper">
        <div class="container-fluid section-small">
            <div class="module container width-5">
                <div class="row-list-mb15">
                    <div class="se-co-box">
                        <ul class="sc-bo-list">
                            <li><a class="sc-bo-link" href="#">Change Password</a></li>
                            <li>
                                <div class="sc-bo-opt">
                                    <div>Two- factor Authentication</div>
                                    <label class="com_toggle-btn">
                                        <input type="checkbox" name="" value="">
                                        <div class="icon"></div>
                                    </label>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default{};
</script>