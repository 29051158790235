<template>
    <header>
        <div class="header-content header-mobile">
            <div class="header-top container-fluid ">
                <div class="module container width-5">
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="section-title mb-0">
                            <button class="button button-text">
                                <h5 class="d-flex">
                                    <div class="icon mr-3" @click="$router.go(-1)">
                                        <svg width="25" version="1.0" viewBox="0 0 470 342" xmlns="http://www.w3.org/2000/svg">
                                            <g transform="translate(0 342) scale(.1 -.1)">
                                                <path d="m1630 3407c-27-8-212-187-813-786-523-522-784-789-797-817-26-53-26-135 0-188 28-58 1551-1579 1602-1601 52-21 140-19 186 4 106 55 152 182 103 286-13 27-205 225-590 610l-571 570 3795 10 45 25c24 14 58 45 75 68 27 40 30 51 30 122s-3 82-30 122c-17 23-51 54-75 68l-45 25-3795 10 566 565c311 311 573 581 583 600 46 89 33 178-38 250-63 66-140 85-231 57z"></path>
                                            </g>
                                        </svg>
                                    </div> Change Password
                                </h5>
                            </button>
                        </div>
                        <!-- <div>
                            <button class="button button-text text-purple">
                                <h5>Generate</h5>
                            </button>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </header>
    <section id="main-wrapper" style="padding-top: 50px; padding-bottom: 67px;">
        <div class="container-fluid section-small">
            <div class="module container width-5">
                <Form class="tr-bo-set set-list" @submit="verifyOtp()" v-if="transaction_id">
                    <div>
                        <div class="mb-3">
                            <label>Please enter the 6 digit one time passcode shown in the Google Authenticator app</label>
                            <div class="position-relative">
                                <Field class="form-control" type="text" placeholder="Please enter OTP" name="Otp" v-model="otp" rules="required|numeric|min:6"/>
                            </div>
                            <ErrorMessage class="text-danger" name="Otp"/>
                        </div>
                    </div>
                    <div class="form-group">
                        <button type="submit" class="button button-quaternary button-wide button-purple">Submit</button>
                    </div>
                    <div class="form-group">
                        Having Trouble with Two-Factor Authentication Code <a href="javascript:void(0)" v-on:click="resendLink()"><vue-feather type="refresh-cw" size=".93rem" class=" custom-class secondIcon"></vue-feather> Resend Link </a>Click Here to verify with Email
                    </div>
                </Form>
                <div class="withdrawal-congratulations d-flex align-items-center flex-column" v-else-if="congModuleWithdrawal">
                    <div class="comMessage text-center">
                        <!-- <div class="icon mx-auto"><img src="/assets/img/Congratulations.gif" width="100" alt=""/></div> -->
                        <h2 class="f-24 co-green-2">Request Verify</h2> 
                        <h4>Dear {{store.user.profile.namef}},</h4> 
                        <p>your password change request has been processed successfully</p>
                    </div>
                </div>
                <Form class="tr-bo-set set-list" @submit="changePassword()" v-else>
                    <div>
                        <div class="mb-3">
                            <Field type="hidden" name="Account" rules="required" v-if="!account_selected.account_id"/>
                            <TradingAccounts v-model="account_selected" />
                            <ErrorMessage class="text-danger" name="Account"/>
                        </div>
                        <div class="mb-3">
                            <label>Enter your Copycrack Password</label>
                            <div class="position-relative">
                                <Field class="form-control" :type="showPass?'text':'password'" name="Password" v-model="password" placeholder="Enter Here" :rules="{ required: true, regex: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[*.!@$%^&():;<>,.?~_+]).{8,20}$/}"/>
                                <i class="fa input--icon" :class="showPass?'fa-eye-slash':'fa-eye'" @click="showPass ^= true"></i>
                            </div>
                            <ErrorMessage class="text-danger" name="Password"/>
                        </div>
                    </div>
                    <div class="form-group">
                        <button type="submit" class="button button-quaternary button-wide button-purple">Submit</button>
                    </div>
                </Form>
            </div>
        </div>
    </section>
</template>
<script>
import { useMainStore } from '@/store/index'
import { Field, Form, ErrorMessage } from 'vee-validate';
import { useToast } from 'vue-toastification'
import TradingAccounts from '@/components/trading-accounts.vue'
export default{
    setup(){
        return { store: useMainStore(), toast: useToast() }
    },
    components: { Field, Form, ErrorMessage, TradingAccounts },
    data(){
        return{
            account_selected : {},
            showPass : false,
            password : '',
            otp : '',
            transaction_id : '',
            congModuleWithdrawal : false,
        }
    },
    computed:{
        liveAccount(){
            return (this.store.tradingAccounts.records || []).filter(v=>v.demo_live == 'live')
        },
    },
    methods:{
        getList(){
            this.store.getTradingAccountList({
                filter: {},
                sort: [],
                search: '',
                page: 1,
                perPage: 300
            }, true).then(res=>{
                let id = this.$route.query.id;
                if(id){ this.account_selected = this.liveAccount.find(v=>v.id == id); }
            }).catch(e=>{ })
        },
        changePassword(){
            this.store.changeAccPassword({
                'account_id' : this.account_selected.account_id ,
                'act_account_id': this.account_selected.act_account_id,
                'password' : this.password,
                'operation' : 'CHANGE PASSWORD'
            }, true).then(res=>{
                this.transaction_id = res.trans_id
            }).catch(e=>{ })
        },
        verifyOtp(){
            this.store.verifyOtpAccPassword({
                'google_token' : this.otp.toString() ,
                'trans_id' : this.transaction_id.toString()
            }, true).then(res=>{}).catch(e=>{})
        },
        resendLink(){
            this.store.resentChangePasswordOtp({
                'trans_id' : this.transaction_id.toString()
            }, true).then(res=>{
                this.congModuleWithdrawal = true
            }).catch(e=>{ })
        }
    },
    created(){
        this.getList()
    },
}
</script>
<style>
    .my-icon{
        position: relative;
        width: 22px;
        min-width: 22px;
        height: 22px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 10px;
        display: block;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: cover;
    }
</style>