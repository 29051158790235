<template>
    <section id="main-wrapper">
        <div class="container-fluid section-small">
            <div class="module container width-5">
                <div class="section-title mb30">
                    <h5 class="fw-500 co-blue-horizon">E-books</h5>
                </div>
                <ul class="ac-co-list">
                    <li>
                        <a class="ac-ar-set d-flex align-items-center" href="#">
                            <div class="ac-ar-banner banner-small" style="background-image: url('/assets/img/dummy/banner_ebook1.jpg');"></div>
                            <div class="section-par">
                                <h6 class="mb-2 fw-600">Ebook Title Goes here</h6>
                                <p>Andreas Thalassinos | 2021</p>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a class="ac-ar-set d-flex align-items-center" href="#">
                            <div class="ac-ar-banner banner-small" style="background-image: url('/assets/img/dummy/banner_ebook1.jpg');"></div>
                            <div class="section-par">
                                <h6 class="mb-2 fw-600">Ebook Title Goes here</h6>
                                <p>Andreas Thalassinos | 2021</p>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a class="ac-ar-set d-flex align-items-center" href="#">
                            <div class="ac-ar-banner banner-small" style="background-image: url('/assets/img/dummy/banner_ebook1.jpg');"></div>
                            <div class="section-par">
                                <h6 class="mb-2 fw-600">Ebook Title Goes here</h6>
                                <p>Andreas Thalassinos | 2021</p>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a class="ac-ar-set d-flex align-items-center" href="#">
                            <div class="ac-ar-banner banner-small" style="background-image: url('/assets/img/dummy/banner_ebook1.jpg');"></div>
                            <div class="section-par">
                                <h6 class="mb-2 fw-600">Ebook Title Goes here</h6>
                                <p>Andreas Thalassinos | 2021</p>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </section>
</template>
<script>
export default{};
</script>