<template>
  <div>
    <RouterView />
    <Footer></Footer>
    <div class="com_popup" v-if="store.user && parseInt(store.user.account_verified) == 0">
      <div class="com_popup-cover" style="opacity: 1;"></div>
      <div class="com_popup-main" style="transform: translate(0px, 0px);">
        <div id="popup-create-price-alert" class="popup-content container-fluid">
          <div class="container width-5">
            <div class="fc-su-box box-small">
              <div class="section-title mb40">
                <div class="d-flex justify-content-between align-items-center"><h6 class="fw-600">Account Verification </h6>  <a href="#" @click.prevent="logout()" >Log Out<i class="fa fa-sign-out ml-2"></i></a></div>
             
                <p>Please check your email, You would have received one time activation code.</p>
              </div>
              <Form class="row-list-mb25 mb-1" @submit="verifyOtp()">
                <div class="form-group mb-0">
                  <div class="com_select-box select-box-v2">
                    <label>Activation Code</label><br>
                    <Field type="Phone" v-model="otp" name="otp" :rules="'required|numeric|max:6'" class="form-control" /><br>
                    <ErrorMessage name="otp" />
                  </div>
                </div>
                <button type="submit" class="button button-quaternary button-wide button-purple mb-1">Submit</button>
              </Form>
              <p class="btM pt-2 text-center">
                <span>
                  If you haven't received verification email, Click
                  <a href="#" class="resendEvent" @click.prevent="resendOtp()"><i class="fa fa-arrow-right"></i> Resend</a>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ProviderCacheMixins } from "@/plugins/vue-provider-cache/mixin";
// import { Const } from "@/plugins/vue-provider-cache/const.mod";
import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'
import { useMainStore } from '@/store/index'
import { Field, Form, ErrorMessage } from 'vee-validate';
export default{
  setup(){
    return { store: useMainStore() }
  },
  mixins: [ProviderCacheMixins],
  components:{ Header, Footer, Field, Form, ErrorMessage },
  data(){
    return{
      otp:''
    }
  },
  watch:{
    $route(){
      copyrack.init();
    }
  },
  methods:{
    resendOtp(){
      this.store.resendOtp({}).then(res=>{ }).catch(e=>{ })
    },
    verifyOtp(){
      this.store.verifyOtp({"otp": this.otp}).then(res=>{ }).catch(e=>{ })
    },
    logout(){
      this.store.$reset()
      this.$router.replace('/')
      // window.location.href = "/";
    }
  },
  created(){
    this.store.getFreshUser({}).then(res=>{ }).catch(e=>{ })
    /*this.store.getTradingAccountList({filter: {},sort: [],search: '',page: 1,perPage: 300}).then(res=>{ console.log(res); }).catch(e=>{ })*/
  },
  updated(){
    copyrack.init();
    // $('#main-wrapper').css({paddingTop: copyrack.header.height(), paddingBottom: copyrack.footer.height() + copyrack.header.bottom.height()})
  }
}
</script>