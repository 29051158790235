<template>
    <header>
        <div class="header-content header-mobile">
            <div class="header-top container-fluid no-shadow">
                <div class="module container width-5">
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="section-title mb-0">
                            <h5>Menu</h5>
                        </div>
                        <ul class="he-to-ctrl">
                            <li>
                                <a class="ht-ct-icon" href="#"><i class="far fa-search ml-0"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <section id="main-wrapper" style="padding-top: 49px; padding-bottom: 123px;">
        <div class="container-fluid section-small">
            <div class="module container width-5">
                <div class="fs-bo-set">
                    <div class="d-flex align-items-center">
                        <div class="icon"><svg width="18" version="1.0" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                                <g transform="translate(0 512) scale(.1 -.1)">
                                    <path d="m2320 5105c-166-28-298-73-450-151-384-199-661-562-762-998-30-131-37-402-13-551 51-329 238-671 479-875 64-54 72-70 36-70-10 0-74-16-142-35-542-155-1006-431-1234-734-109-146-183-317-219-502-22-118-22-655 1-742 53-206 225-378 430-431 56-14 273-16 2114-16 1832 0 2059 2 2113 16 208 54 377 223 431 431 23 87 23 611 1 739-43 246-172 485-355 658-142 134-366 283-569 379-98 46-108 49-171 45-127-7-196-86-188-212 6-103 31-130 185-208 217-110 363-209 474-319 71-71 111-130 159-232 64-138 74-206 75-492 0-237-1-252-21-290-25-46-45-65-94-91-34-18-100-19-2040-19s-2006 1-2040 19c-49 26-69 45-94 91-20 38-21 53-21 290 1 328 22 425 131 597 151 237 565 487 1024 618 286 82 514 116 875 130 272 11 369 21 487 51 557 140 973 577 1095 1149 26 121 26 459 0 580-78 367-278 681-574 904-172 129-426 236-643 271-115 18-374 18-480 0zm439-411c424-76 774-425 855-854 20-105 20-295 0-400-81-429-425-772-855-855-108-20-290-20-398 0-430 83-774 426-855 855-20 106-20 295 0 400 88 467 490 831 959 868 110 9 185 5 294-14z"></path>
                                </g>
                            </svg></div>
                        <div class="section-par">
                            <p>
                                <span>{{((profile.namef || '')+' '+(profile.namel || '')).trim()}}</span> <br>
                                <span class="co-blue-horizon">User 5101 • MT5 • Demo</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="module container width-5">
                <div class="me-co-banner bg-black bg-gradient-blue-2">
                    <div class="com_banner bp-ab" style="background-image: url('/assets/img/mobile/banner_forex_trading.png'); background-size: auto 100%; background-position: 100% 50%;"></div>
                    <div class="me-co-banner-main bp-rel d-flex align-items-end">
                        <div class="section-par mb-0">
                            <h4 class="mb-1">Forex Trading</h4>
                            <h6 class="co-blue-horizon">Promo</h6>
                            <div class="mt10">
                                <a class="button button-quinary button-white button-line" href="#">Trade Now</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="module container width-5">
                <div class="row-list-mb4">
                    <ul class="hc-se-list">
                        <li class="item">
                            <router-link to="/profile">
                                <div class="icon"><img src="/assets/img/icons/icon_profile.png" alt=""></div> Profile
                            </router-link>
                        </li>
                        <li class="item">
                            <router-link to="/trading-accounts">
                                <div class="icon"><img src="/assets/img/icons/icon_trading_acocunts.png" alt=""></div> Trading Accounts
                            </router-link>
                        </li>
                        <li class="item">
                            <router-link to="/copy-premium-wallet">
                                <div class="icon"><img src="/assets/img/icons/wallet.svg" alt=""></div> Premium Wallet
                            </router-link>
                        </li>
                        <li class="item">
                            <a href="javascript:void(0)">
                                <div class="icon"><img src="/assets/img/icons/icons_coper_dashboard.png" alt=""></div> Copier dashboard
                            </a>
                        </li>
                        <li class="item">
                            <router-link  to="/documents">
                                <div class="icon"><img src="/assets/img/icons/icons_my_documents.png" alt=""></div> My Documents
                            </router-link>
                        </li>
                        <li class="item">
                            <a href="javascript:void(0)">
                                <div class="icon"><img src="/assets/img/icons/icon_trading_central.png" alt=""></div> Trading Central
                            </a>
                        </li>
                        <li class="item">
                            <a href="javascript:void(0)">
                                <div class="icon"><img src="/assets/img/icons/icon_contest.png" alt=""></div> Contests
                            </a>
                        </li>
                        <li class="item">
                            <router-link to="/deposit-list">
                                <div class="icon"><img src="/assets/img/icons/icon_premium_deposit.svg" alt=""></div> Deposit List
                            </router-link>
                        </li>
                        <li class="item">
                            <router-link to="/withdrawal-list">
                                <div class="icon"><img src="/assets/img/icons/icon_premium_wallet.png" alt=""></div> Withdrawal List
                            </router-link>
                        </li>
                    </ul>
                    <ul class="hc-se-list">
                        <li class="item">
                            <router-link to="/settings">
                                <div class="icon"><img src="/assets/img/icons/icon_settings.png" alt=""></div> Settings
                            </router-link>
                        </li>
                        <li class="item">
                            <router-link to="/help-center">
                                <div class="icon"><img src="/assets/img/icons/icon_help_center.png" alt=""></div> Help Center
                            </router-link>
                        </li>
                        <li class="item">
                            <a href="javascript:void(0)">
                                <div class="icon"><img src="/assets/img/icons/icons_support_chat.png" alt=""></div> Support Chat
                            </a>
                        </li>
                        <li class="item co-red">
                            <a class="link-red" href="javascript:void(0)" @click="logout()">
                                <div class="icon"><img src="/assets/img/icons/icon_logout_red.png" alt=""></div> Logout
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { useMainStore } from '@/store/index'
export default{
    setup(){
        return { store: useMainStore() }
    },
    computed:{
        profile(){
            try{
                return (this.store.user.profile || {})
            }catch(e){ return {} }
        }
    },
    methods:{
        logout(){
            this.store.$reset()
            this.$router.replace('/')
            // window.location.href = "/";
        }
    }
};
</script>