<template>
     <header>
        <div class="header-content header-mobile">
            <div class="header-top container-fluid no-shadow">
                <div class="module container width-5">
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="section-title mb-0">
                            <button class="button button-text">
                                <h5 class="d-flex">
                                    <div class="icon mr-3"><svg width="25" version="1.0" viewBox="0 0 470 342" xmlns="http://www.w3.org/2000/svg">
                                            <g transform="translate(0 342) scale(.1 -.1)">
                                                <path d="m1630 3407c-27-8-212-187-813-786-523-522-784-789-797-817-26-53-26-135 0-188 28-58 1551-1579 1602-1601 52-21 140-19 186 4 106 55 152 182 103 286-13 27-205 225-590 610l-571 570 3795 10 45 25c24 14 58 45 75 68 27 40 30 51 30 122s-3 82-30 122c-17 23-51 54-75 68l-45 25-3795 10 566 565c311 311 573 581 583 600 46 89 33 178-38 250-63 66-140 85-231 57z"></path>
                                            </g>
                                        </svg></div> Buy /Sell
                                </h5>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <section id="main-wrapper" style="padding-top: 50px; padding-bottom: 67px;">
        <div class="container-fluid section-small">
            <div class="module container width-5">
                <div class="section-title mb30">
                    <h3 class="mb-2"><strong>Buy BTC</strong></h3>
                    <h5 class="fw-500 co-blue-horizon">1BTC = 192.01202768 BCH</h5>
                </div>
                <div>
                    <div class="ex-co-row">
                        <div class="ex-co-box d-flex align-items-center justify-content-between">
                            <div class="tb-se-title">
                                <div class="icon" style="background-image: url('/assets/img/icon_bitcoin.png');"></div>
                                <div class="section-par">
                                    <h6 class="mb-0">Bitcoin</h6>
                                    <p class="co-blue-horizon">BTC Wallet</p>
                                </div>
                            </div>
                            <div class="section-par text-right">
                                <h6 class="mb-0">0 BTC</h6>
                                <p class="co-blue-horizon">Blance 0.00</p>
                            </div>
                        </div>
                        <div class="row align-items-center">
                            <div class="col"></div>
                            <div class="col d-flex justify-content-center">
                                <!-- <button class="ex-co-btn"><i class="far fa-long-arrow-alt-up"></i></button> -->
                                <button class="ex-co-btn btn-green"><i class="far fa-long-arrow-alt-up"></i></button>
                                <!-- <button class="ex-co-btn btn-red"><i class="far fa-long-arrow-alt-down"></i></button> -->
                            </div>
                            <div class="col d-flex justify-content-end">
                                <div class="ex-co-box d-flex align-items-center justify-content-center">min</div>
                            </div>
                        </div>
                        <div class="ex-co-box d-flex align-items-center justify-content-between">
                            <div class="tb-se-title">
                                <div class="icon" style="background-image: url('/assets/img/icon_bitcoin_cash_v2.png');"></div>
                                <div class="section-par">
                                    <h6 class="mb-0">Bitcoin Cash</h6>
                                    <p class="co-blue-horizon">BCH Wallet</p>
                                </div>
                            </div>
                            <div class="section-par text-right">
                                <h6 class="mb-0">0 BCH</h6>
                                <p class="co-blue-horizon">Blance 0.00</p>
                            </div>
                        </div>
                    </div>
                    <div class="mt30 text-center"><button class="button button-primary width-7 button-blue-lime">Buy BTC with BCH</button></div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default{};
</script>