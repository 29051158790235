<template>
    <div class="com_select-box select-box-v3 mt-4" :class="[{'com_select-box-active':accSelect}]">
        <div class="com_select-box-title accSelect" @click="accSelect ^= true">
            <span v-if="account_selected.platform && account_selected.account_id"><strong>{{account_selected.platform}}:</strong> #{{account_selected.account_id}}</span>
            <span v-else>Select Trading Account</span>
        </div>
        <div class="com_select-box-list">
            <ul>
                <li><strong>Select Trading Account</strong></li>
                <li v-for="item in tradingAccounts" :class="[{'active':item.account_id == account_selected.account_id}]" @click="change(item);">
                    <span><strong>{{item.platform}}:</strong> #{{item.account_id}}</span>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import { useMainStore } from '@/store/index'
export default{
    setup(){
        return { store: useMainStore() }
    },
    data(){
        return{
            accSelect : false,
            account_selected : {},
        }
    },
    watch:{
        'modelValue'(v){
            if(this.account_selected.account_id != v.account_id){
                this.account_selected = v
                this.$emit('change', this.account_selected)
            }
        }
    },
    props:['modelValue'],
    emits: ['update:modelValue','change'],
    computed:{
        tradingAccounts(){
            return (this.store.tradingAccounts.records || []).filter(v=>v.demo_live == 'live')
        },
    },
    methods:{
        change(item){
            this.accSelect = false;
            if(this.account_selected.account_id != item.account_id){
                this.account_selected = item;
                this.$emit('update:modelValue', this.account_selected)
                this.$emit('change', this.account_selected)
            }
        }
    },
    mounted(){
        let _that = this;
        $(document).on("click", function (event) {
            if (!$(event.target).closest(".accSelect").length) {
                _that.accSelect = false;
            }
        });
    }
}
</script>
<style>
    .my-icon{
        position: relative;
        width: 22px;
        min-width: 22px;
        height: 22px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 10px;
        display: block;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: cover;
    }
</style>