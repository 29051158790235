<template>
    <section id="main-wrapper">
        <div class="container-fluid section-small">
            <div class="module container width-5">
                <div class="section-title mb30">
                    <h5 class="fw-500 co-blue-horizon">Webinars</h5>
                </div>
                <ul class="ac-co-list">
                    <li>
                        <router-link class="ac-ar-set" to="/academy/webinar-details">
                            <div class="ac-ar-banner banner-small mb-3" style="background-image: url('/assets/img/dummy/banner_academy3.jpg');"></div>
                            <div class="section-par">
                                <h6 class="fw-600">Lorem ipsum dolor sit amet adipisicing.</h6>
                                <p class="fw-600">22 June 2022 • 22:15 - 22:45</p>
                                <p>Attendees (100)</p>
                            </div>
                        </router-link>
                    </li>
                    <li>
                        <router-link class="ac-ar-set" to="/academy/webinar-details">
                            <div class="ac-ar-banner banner-small mb-3" style="background-image: url('/assets/img/dummy/banner_academy3.jpg');"></div>
                            <div class="section-par">
                                <h6 class="fw-600">Lorem ipsum dolor sit amet adipisicing.</h6>
                                <p class="fw-600">22 June 2022 • 22:15 - 22:45</p>
                                <p>Attendees (100)</p>
                            </div>
                        </router-link>
                    </li>
                    <li>
                        <router-link class="ac-ar-set" to="/academy/webinar-details">
                            <div class="ac-ar-banner banner-small mb-3" style="background-image: url('/assets/img/dummy/banner_academy3.jpg');"></div>
                            <div class="section-par">
                                <h6 class="fw-600">Lorem ipsum dolor sit amet adipisicing.</h6>
                                <p class="fw-600">22 June 2022 • 22:15 - 22:45</p>
                                <p>Attendees (100)</p>
                            </div>
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </section>
</template>
<script>
export default{};
</script>