<template>
    <footer>
        <div class="footer-content footer-mobile">
            <!-- <div class="container width-5 bg-black bg-gradient-blue-3 pd10 pl-3 pr-3">
                <div class="row row-extra-small align-items-center justify-content-center">
                    <div class="col col-sm-6 text-right text-uppercase">
                        <p class="mb-0"><small>Get a Real-Money account for Free and enjoy all NAGA features.</small></p>
                    </div>
                    <div class="col-auto no-wrap">
                        <a class="button button-tertiary" href="#"><span>Upgrade account</span></a>
                    </div>
                </div>
            </div> -->
            <div class="footer-main">
                <div class="container width-5">
                    <ul class="he-to-list group-height">
                        <li class="item" @click.stop.prevent="pagechange('/index')" :class="[{'active':$route.name == 'index'}]">
                            <RouterLink to="/index">
                                <div class="icon gh1">
                                    <div class="com_icon">
                                        <span class="default"><img src="/assets/img/icons/icon_feed.png" alt=""></span>
                                        <span class="hover"><img src="/assets/img/icons/icon_feed_hover.png" alt=""></span>
                                    </div>
                                </div>
                                <div>Feed</div>
                            </RouterLink>
                        </li>
                        <li class="item" @click.stop.prevent="pagechange('/trades')" :class="[{'active':$route.name == 'trades'}]">
                            <RouterLink to="/trades">
                                <div class="icon gh1">
                                    <div class="com_icon">
                                        <span class="default"><img src="/assets/img/icons/icon_trades.png" alt=""></span>
                                        <span class="hover"><img src="/assets/img/icons/icon_trades_hover.png" alt=""></span>
                                    </div>
                                </div>
                                <div>Trades</div>
                            </RouterLink>
                        </li>
                        <li class="item" @click.stop.prevent="pagechange('/my-trades')" :class="[{'active':$route.name == 'my-trades'}]">
                            <RouterLink to="/my-trades">
                                <div class="icon gh1">
                                    <div class="com_icon">
                                        <span class="default"><img src="/assets/img/icons/icon_my_trades.png" alt=""></span>
                                        <span class="hover"><img src="/assets/img/icons/icon_my_trades_hover.png" alt=""></span>
                                    </div>
                                </div>
                                <div>My Trades</div>
                            </RouterLink>
                        </li>
                        <li class="item" @click.stop.prevent="pagechange('/top-traders')" :class="[{'active':$route.name == 'top-traders'}]">
                            <RouterLink to="/top-traders">
                                <div class="icon gh1">
                                    <div class="com_icon">
                                        <span class="default"><img src="/assets/img/icons/icon_top_trades.png" alt=""></span>
                                        <span class="hover"><img src="/assets/img/icons/icon_top_trades_hover.png" alt=""></span>
                                    </div>
                                </div>
                                <div>Top Traders</div>
                            </RouterLink>
                        </li>
                        <li class="item" @click.stop.prevent="pagechange('/menu')" :class="[{'active':$route.name == 'menu'}]">
                            <RouterLink to="/menu">
                                <div class="icon gh1">
                                    <div class="com_icon">
                                        <span class="default"><img src="/assets/img/icons/icon_menu_bar.png" alt=""></span>
                                        <span class="hover"><img src="/assets/img/icons/icon_menu_bar_hover.png" alt=""></span>
                                    </div>
                                </div>
                                <div>Menu</div>
                            </RouterLink>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
import { RouterLink } from 'vue-router';
export default{
    components: { RouterLink },
    methods:{
        pagechange(page){
            // alert(page)
            this.$router.push(page)
        }
    }
}
</script>