export const GLOBAL_BASE_URL = process.env.VUE_APP_API_BASE_URL
export const ZULU_URL = process.env.VUE_APP_ZULUTRADE_BASE_URL

//=============store function api====================
export const EP_LOGIN 							=	`${GLOBAL_BASE_URL}/api/login`
export const EP_LOGIN_2FA 						=	`${GLOBAL_BASE_URL}/api/login/twofa`
export const EP_REGISTER 						=	`${GLOBAL_BASE_URL}/api/signup`
export const EP_OTPVERIFY						=	`${GLOBAL_BASE_URL}/api/otpverify`
export const EP_RESEND_OTP						=	`${GLOBAL_BASE_URL}/api/resendotp`
export const EP_GET_FRESH_USER					=	`${GLOBAL_BASE_URL}/get/fresh/user/data`
export const EP_GET_TRADING_ACCOUNT				=	`${GLOBAL_BASE_URL}/get/trading/account/list`
export const EP_CREATE_TRADING_ACCOUNT 			=	`${GLOBAL_BASE_URL}/create/account`
export const EP_GET_TRADING_ACCOUNT_DETAILS 	=	`${GLOBAL_BASE_URL}/get/account/details`

export const EP_GET_DEPOSIT_LIST				=	`${GLOBAL_BASE_URL}/get/deposit/list`
export const EP_DEPOSIT_REQUEST				 	=	`${GLOBAL_BASE_URL}/deposit/request/operations`
export const EP_WITHDRAWAL_REQUEST_OTP		 	=	`${GLOBAL_BASE_URL}/withdrawal/request/otp`
export const EP_WITHDRAWAL_CHECK_OTP		 	=	`${GLOBAL_BASE_URL}/withdrawal/request`
export const EP_WITHDRAWAL_LIST				 	=	`${GLOBAL_BASE_URL}/get/withdrawal/list`

export const EP_GET_FEEDS					 	=	`${GLOBAL_BASE_URL}/get/trade/list`
export const EP_GET_FEED_COMMENTS			 	=	`${GLOBAL_BASE_URL}/get/comments`
export const EP_ADD_FEED					 	=	`${GLOBAL_BASE_URL}/create/trade`
export const EP_ADD_FEED_COMMENT			 	=	`${GLOBAL_BASE_URL}/add/comment`
export const EP_LIKE_UNLIKE_FEED_COMMENT		=	`${GLOBAL_BASE_URL}/update/like`

export const EP_CHANGE_ACCOUNT_PASSWORD			=	`${GLOBAL_BASE_URL}/account/operations`
export const EP_CONFIRM_CHANGE_ACCOUNT_PASSWORD	=	`${GLOBAL_BASE_URL}/account/change/password`
export const EP_RESEND_TRADE_ACC_PASSWORD		=	`${GLOBAL_BASE_URL}/account/operations/link`

export const EP_GET_DOCUMENTS					=	`${GLOBAL_BASE_URL}/get/document/list`

export const EP_GET_TOP_TRADERS					=	`${ZULU_URL}/zulutrade-client/api/providers/performance/topTraders/search?flavorId=521&accessingFlavorId=1`

