<template>
    <header>
        <div class="header-content header-mobile">
            <div class="header-top container-fluid ">
                <div class="module container width-5">
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="section-title mb-0">
                            <button class="button button-text">
                                <h5 class="d-flex">
                                    <div class="icon mr-3" @click="$router.go(-1)">
                                        <svg width="25" version="1.0" viewBox="0 0 470 342" xmlns="http://www.w3.org/2000/svg">
                                            <g transform="translate(0 342) scale(.1 -.1)">
                                                <path d="m1630 3407c-27-8-212-187-813-786-523-522-784-789-797-817-26-53-26-135 0-188 28-58 1551-1579 1602-1601 52-21 140-19 186 4 106 55 152 182 103 286-13 27-205 225-590 610l-571 570 3795 10 45 25c24 14 58 45 75 68 27 40 30 51 30 122s-3 82-30 122c-17 23-51 54-75 68l-45 25-3795 10 566 565c311 311 573 581 583 600 46 89 33 178-38 250-63 66-140 85-231 57z"></path>
                                            </g>
                                        </svg>
                                    </div> Withdrawal
                                </h5>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <section id="main-wrapper" style="padding-top: 50px; padding-bottom: 67px;">
        <app-loader v-if="store.loading"></app-loader>
        <div class="container-fluid section-small">
            <div class="module container width-5">
                <div v-if="liveAccounts.length > 0" class="fc-su-box box-small">
                    <div class="m-2" v-if="transaction_id">
                        <span>Please enter the 6 digit one time passcode shown in the Google Authenticator app</span>
                        <Form v-if="account_selected.account_id" @submit="confirmOtp()">
                            <div class="m-2 d-flex flex-column">
                                <Field class="form-control" type="number" v-model="otp" name="Otp" rules="required|numeric|min:6"/>
                                <ErrorMessage name="Otp" />
                            </div>
                            <div class="form-group">
                                <button type="submit" class="button button-quaternary button-wide button-purple">Submit</button>
                            </div>
                        </Form>
                    </div>
                    <div class="withdrawal-congratulations d-flex align-items-center flex-column" v-else-if="congModuleWithdrawal">
                        <div class="comMessage text-center">
                            <div class="icon mx-auto"><img src="/assets/img/Congratulations.gif" width="100" alt=""/></div>
                            <h2 class="f-24 co-green-2">Congratulations</h2> 
                            <h4>Dear {{store.user.profile.namef}},</h4> 
                            <p>We have received your withdrawal request , please wait for reply</p>
                        </div>
                    </div>
                    <Form class="row-list-mb25" @submit="withdrawalGetOtp()" v-else>
                        <div class="form-group">
                            <TradingAccounts v-model="account_selected" @change="getTradingAccountDetail()"/>
                            <div v-if="account_selected.account_id">
                                <ul class="m-2 d-flex justify-content-between">
                                    <li>Free Margin: <span class="accBalanceCls">{{parseFloat(freeMargin1 || 0).toFixed(2)}}</span></li>
                                    <li>Account Currency: <span class="accCurrencyCls">{{account_selected.currency}}</span></li>
                                </ul>
                                <p class="m-2 text-danger" v-if="parseInt(freeMargin || 0) <= 0">Insufficient amount balance to withdrawal</p>
                                <div class="m-0 d-flex flex-column mb-3">
                                    <Field class="form-control box-shadow border-0 m-0" type="number" placeholder="Amount" v-model="amount" name="Amount" :rules="(account_selected.currency == 'USD' || account_selected.currency == 'EUR' || account_selected.currency == 'usd' || account_selected.currency == 'eur')? 'required|decimal:2|max:15':'required|decimal:8|max:15'"/>
                                    <ErrorMessage class="text-danger" name="Amount" />
                                    <span class="text-danger" v-show="!(parseFloat(freeMargin - credit) >= parseFloat(amount || 0))">Insufficient amount balance to withdrawal</span>
                                </div>
                                <div class="com_select-box select-box-v3 mb-3" :class="[{'com_select-box-active':currencySelect}]">
                                    <div class="com_select-box-title currencySelect" @click="currencySelect ^= true">
                                        <div class="icon" :style="'background-image: url(/assets/img/crypto_logos/'+withdrawCurrency.toLowerCase()+'.png);'" v-if="withdrawCurrency"></div>
                                        <span v-if="withdrawCurrency">{{withdrawCurrency}}: {{currencyObject[withdrawCurrency]}}</span>
                                        <span v-else>Select Currency</span>
                                    </div>
                                    <div class="com_select-box-list currencySelect">
                                        <ul>
                                            <li><strong>Select your crypto currency</strong></li>
                                            <li class="position-relative" style="display: flex;" v-for="val,key in currencyObject" :class="[{'active':withdrawCurrency == key}]" @click="withdrawCurrency = key;currencySelect = false">
                                                <div class="my-icon" :style="'background-image: url(/assets/img/crypto_logos/'+key.toLowerCase()+'.png);'"></div>
                                                <span>{{key}}: {{val}}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="m-0 d-flex flex-column mb-3">
                                    <Field v-slot="{ field, errors }" v-model="address" name="Address" rules="required|max:60">
                                        <textarea class="form-control box-shadow border-0 m-0" v-bind="field" name="Address" placeholder="Address" />
                                        <div v-if="errors[0]">{{errors[0]}}</div>
                                    </Field>
                                </div>
                            </div>
                        </div>
                        <div class="form-group" v-if="account_selected.account_id">
                            <button type="submit" class="button button-quaternary button-wide button-purple">Submit</button>
                        </div>
                    </Form>
                </div>
                <div class="fc-su-box box-medium ml-0 mr-0" v-else>
                    <div class="section-title par-blue-horizon text-center mb-4">
                        <h6 class="mb-1">No live Trading Account Found</h6>
                        <p>You have currently no Live Trading Account.</p>
                    </div>
                    <div>
                        <router-link to="/trading-accounts" class="button button-quaternary button-wide button-purple">
                            <i class="fal fa-plus mr-2"></i> Create Account
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { useMainStore } from '@/store/index'
import { Field, Form, ErrorMessage } from 'vee-validate';
import { useToast } from 'vue-toastification'
import TradingAccounts from '@/components/trading-accounts.vue'
export default{
    setup(){
        return { store: useMainStore(), toast: useToast() }
    },
    components: { Field, Form, ErrorMessage, TradingAccounts },
    data(){
        return{
            account_selected : {},
            currencySelect : false,
            select_currency: '',
            withdrawCurrency: '',
            freeMargin : 0,
            credit : 0,
            freeMargin1 : 0,
            amount : '',
            address: '',
            transaction_id: '',

            otp: '',
            congModuleWithdrawal: false,
            currencyObject: {
                "BTC": "Bitcoin",
                "LTC": "LiteCoin",
                "ETH": "Etherum (ETH Network)",
                "TUSD": "True USD (ETH Network)",
                "USDC": "USDC (ETH Network)",
                "BCH": "Bitcoin Cash",
                "ALGO": "Algorand",
                "DOGE": "Dogecoin",
                "REP": "Augur",
                "bat": "Basic Attention Token",
                "BSV": "Bitcoin SV",
                "LINK": "Chainlink",
                "BNT": "Bancor",
                "COMP": "Compound",
                "cro": "Crypto.com Coin",
                "USDT": "Tether (ERC 20 Address)",
                "dai": "DAI",
                "enj": "Enjin Coin",
                "eos": "EOS",
                "ZIL": "zilliqa",
                "UNI": "Uniswap"
            },
        }
    },
    computed:{
        liveAccounts(){
            return (this.store.tradingAccounts.records || []).filter(v=>v.demo_live == 'live')
        },
    },
    methods:{
        onCopy(e){
            const _that = this
            this.isCopy = true
            setTimeout(()=>{
                _that.isCopy = false
            },5000)
        },
        onError(e){
            console.log(e);
        },
        getList(){
            this.store.getTradingAccountList({
                filter: {},
                sort: [],
                search: '',
                page: 1,
                perPage: 300
            }, true).then(res=>{
                let id = this.$route.query.id;
                if(id){
                    this.account_selected = this.liveAccounts.find(v=>v.id == id);
                }
            }).catch(e=>{ })
        },
        getTradingAccountDetail(){
            this.store.getTradingAccountDetail({
                'account_id': this.account_selected.account_id,
                'act_account_id': this.account_selected.act_account_id,
                'demo_live': this.account_selected.demo_live,
                'platform': this.account_selected.platform
            }, true).then(res=>{
                this.freeMargin = res.margin_detail.freeMargin.toString();
                this.credit = res.margin_detail.credit.toString();
                this.freeMargin1 = parseFloat(res.margin_detail.freeMargin) - parseFloat(res.margin_detail.credit);
            }).catch(e=>{})
        },
        withdrawalGetOtp(){
            console.log('')
            if((parseFloat(this.amount) <= parseFloat((this.freeMargin)-(this.credit))) && parseFloat(this.amount) > 0 ){
                this.store.withdrawalRequestOtp({
                    'account_id' : this.account_selected.account_id,
                    'act_account_id': this.account_selected.act_account_id,
                    'crypto_new' : this.withdrawCurrency,
                    'address_new' : this.address,
                    'currency' : this.account_selected.currency,
                    'amount' : this.amount,
                    "operation" : "WITHDRAWAL REQUEST"
                }, true).then(res=>{ this.transaction_id = res.trans_id; }).catch(e=>{})
            }
        },
        confirmOtp(){
            this.store.withdrawalConfirmOtp({
                'google_token' : this.otp.toString() ,
                'trans_id' : this.transaction_id.toString()
            }, true).then(v=>{ /*console.log('-------------->',v)*/ this.congModuleWithdrawal =  true }).catch(e=>{})
        }
    },
    mounted(){
        let _that = this;
        $(document).on("click", function (event) {
            if (!$(event.target).closest(".currencySelect").length) {
                _that.currencySelect = false;
            }
        });
    },
    created(){
        this.getList()
    },
}
</script>
<style>
    .my-icon{
        position: relative;
        width: 22px;
        min-width: 22px;
        height: 22px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 10px;
        display: block;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: cover;
    }
</style>