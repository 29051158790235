<template>
    <header>
        <div class="header-content header-mobile">
            <div class="header-top container-fluid ">
                <div class="module container width-5">
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="section-title mb-0">
                            <h5>My Trades</h5>
                        </div>
                        <ul class="he-to-ctrl">
                            <select class="common_select form-control" v-model="account_selected" @change="getTradingDetail()">
                                <option value="" disabled>Select Trading Account</option>
                                <option value="" class="bold" disabled>Demo</option>
                                <optgroup v-for="(data,index) in (store.tradingAccounts.demo || [])" :label="data.key">
                                    <option :value="list" v-for="(list,key) in data.values">{{list.account_id}}</option>
                                </optgroup>
                                <option value="" class="bold" disabled>Live</option>
                                <optgroup v-for="(data,index) in (store.tradingAccounts.live || [])" :label="data.key">
                                    <option :value="list" v-for="(list,key) in data.values">{{list.account_id}}</option>
                                </optgroup>
                            </select>
                        </ul>
                    </div>
                </div>
                <div class="module container width-5">
                    <div class="he-mo-nav">
                        <div class="com_draggable com_tab">
                            <div class="com_draggable-main">
                                <ul class="pr-nav-list list-wide com_tab-nav no-wrap">
                                    <li class="item"><a href="#active-trades">Open Trade</a></li>
                                    <li class="item"><a href="#close-trades">Close Trade</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <section id="main-wrapper" style="padding-top: 99px; padding-bottom: 124px;">
        <app-loader v-if="store.loading"></app-loader>
        <div class="container-fluid section-small">
            <!-- <div class="module container width-5">
                <div class="fc-su-box box-small">
                    <div class="section-title par-blue-horizon">
                        <h5 class="mb-1 fw-500">Tade with real Money</h5>
                        <p>Enter The globe markets and trade with real money</p>
                    </div>
                    <button class="button button-quaternary button-wide button-purple">Add real money</button>
                </div>
            </div> -->
            <div class="module container width-5">
                <div id="active-trades" class="com_tab-content" style="display: block;">
                    <div v-if="account_selected && account_selected.platform == 'MT5'">
                        <ul class="row-list-mb15" v-if="((accDetails.open_trades || {}).csv_array || []).filter(v=>v[1]).length > 0">
                            <li v-for="list,i in ((accDetails.open_trades || {}).csv_array || []).filter(v=>v[1])">
                                <div class="tr-bo-set" @click="(showIndex == i)?(showIndex = -1):(showIndex = i)">
                                    <div>
                                        <div class="tb-se-title align-items-start">
                                            <div class="icon"></div>
                                            <div class="section-par">
                                                <h6 class="mb-1">{{decodeURIComponent(list[2])}}</h6>
                                                <p class="mb-0 co-pure-apple">{{list[9]}}</p>
                                                <div class="d-flex">
                                                    <div class="tb-se-tag">{{(list[3] == 0)?'buy':(list[3] == 1)?'sell':'-'}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center justify-content-end">
                                        <div>
                                            <ul class="tb-se-num-list">
                                                <li>
                                                    <span>{{list[6]}}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <!-- <div class="set-auto pl-1"><button class="button button-text text-blue-horizon cr-wa-more"><i class="far fa-ellipsis-v"></i></button></div> -->
                                </div>
                                <div v-show="showIndex == 1">
                                    <ul>
                                        <li>login: <span>{{list[1]}}</span></li>
                                        <li>order: <span>{{list[0]}}</span></li>
                                        <li>symbol: <span>{{decodeURIComponent(list[2])}}</span></li>
                                        <li>type: <span>{{(list[3] == 0)?'buy':(list[3] == 1)?'sell':'-'}}</span></li>
                                        <li>volume: <span>{{list[4]}}</span></li>
                                        <li>openTime: <span>{{timeFunc(list[5])}}</span></li>
                                        <li>openPrice: <span>{{list[6]}}</span></li>
                                        <li>SL: <span>{{list[7]}}</span></li>
                                        <li>TP: <span>{{list[8]}}</span></li>
                                        <li>commision: <span>{{list[10]}}</span></li>
                                        <li>PNL: <span>{{list[9]}}</span></li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                        <div class="fc-su-box box-medium ml-0 mr-0" v-else>
                            <div class="section-title par-blue-horizon text-center mb-4">
                                <h6 class="mb-1">No record Found</h6>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="account_selected && account_selected.platform == 'MT4'">
                        <ul class="row-list-mb15" v-if="((accDetails.open_trades || {}).csv_array || []).filter(v=>v[1]).length > 0">
                            <li v-for="list,i in ((accDetails.open_trades || {}).csv_array || []).filter(v=>v[1])">
                                <div class="tr-bo-set" @click="(showIndex == i)?(showIndex = -1):(showIndex = i)">
                                    <div>
                                        <div class="tb-se-title align-items-start">
                                            <div class="icon"></div>
                                            <div class="section-par">
                                                <h6 class="mb-1">{{decodeURIComponent(list[2])}}</h6>
                                                <p class="mb-0 co-pure-apple">{{list[9]}}</p>
                                                <div class="d-flex">
                                                    <div class="tb-se-tag">{{list[3].toUpperCase()}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center justify-content-end">
                                        <div>
                                            <ul class="tb-se-num-list">
                                                <li>
                                                    <span>{{list[6]}}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div v-show="showIndex == 1">
                                    <ul>
                                        <li>login: <span>{{list[1]}}</span></li>
                                        <li>order: <span>{{list[0]}}</span></li>
                                        <li>symbol: <span>{{decodeURIComponent(list[2])}}</span></li>
                                        <li>type: <span>{{list[3].toUpperCase()}}</span></li>
                                        <li>volume: <span>{{list[4]/100}}</span></li>
                                        <li>OpenTime: <span>{{timeFunc(list[5])}}</span></li>
                                        <li>OpenPrice: <span>{{list[6]}}</span></li>
                                        <li>SL: <span>{{list[7]}}</span></li>
                                        <li>TP: <span>{{list[8]}}</span></li>
                                        <li>Commision: <span>{{list[10]}}</span></li>
                                        <li>PNL: <span>{{list[9]}}</span></li>
                                        <li>Comment: <span>{{list[11]}}</span></li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                        <div class="fc-su-box box-medium ml-0 mr-0" v-else>
                            <div class="section-title par-blue-horizon text-center mb-4">
                                <h6 class="mb-1">No record Found</h6>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="account_selected && account_selected.platform == 'ACT'">
                        <ul class="row-list-mb15" v-if="((accDetails.open_trades || {}).csv_array || []).length > 0">
                            <li v-for="list,i in ((accDetails.open_trades || {}).csv_array || [])">
                                <div class="tr-bo-set" @click="(showIndex == i)?(showIndex = -1):(showIndex = i)">
                                    <div>
                                        <div class="tb-se-title align-items-start">
                                            <div class="icon"></div>
                                            <div class="section-par">
                                                <h6 class="mb-1">{{list.Symbol}}</h6>
                                                <p class="mb-0 co-pure-apple">{{list.Price}}</p>
                                                <div class="d-flex">
                                                    <div class="tb-se-tag">{{(list.Side == 1)?'buy':(list.Side == 0)?'sell':'-'}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center justify-content-end">
                                        <div>
                                            <ul class="tb-se-num-list">
                                                <li>
                                                    <span>{{list.Quantity}}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div v-show="showIndex == 1">
                                    <ul>
                                        <li>TradeId <span>{{list.TradeID}}</span></li>
                                        <li>AccountID <span>{{list.AccountID}}</span></li>
                                        <li>Symbol <span>{{list.Symbol}}</span></li>
                                        <li>Type <span>{{(list.Side == 1)?'buy':(list.Side == 0)?'sell':'-'}}</span></li>
                                        <li>Quantity <span>{{list.Quantity}}</span></li>
                                        <li>Price <span>{{list.Price}}</span></li>
                                        <li>OpenTime <span>{{timeFuncDate(list.OpenTime)}}</span></li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                        <div class="fc-su-box box-medium ml-0 mr-0" v-else>
                            <div class="section-title par-blue-horizon text-center mb-4">
                                <h6 class="mb-1">No record Found</h6>
                            </div>
                        </div>
                    </div>
                    <div class="fc-su-box box-medium ml-0 mr-0" v-else>
                        <div class="section-title par-blue-horizon text-center mb-4">
                            <h6 class="mb-1">Select Trading Account</h6>
                        </div>
                    </div>
                </div>
                <div id="close-trades" class="com_tab-content">
                    <div v-if="account_selected && account_selected.platform == 'MT4'">
                        <ul class="row-list-mb15" v-if="((accDetails.history || {}).csv_array || []).filter(v=>v[1]).length > 0">
                            <li v-for="list,i in ((accDetails.history || {}).csv_array || []).filter(v=>v[1])">
                                <div class="tr-bo-set" @click="(showIndex == i)?(showIndex = -1):(showIndex = i)">
                                    <div>
                                        <div class="tb-se-title align-items-start">
                                            <div class="icon"></div>
                                            <div class="section-par">
                                                <h6 class="mb-1">{{decodeURIComponent(list[2])}}</h6>
                                                <p class="mb-0 co-pure-apple">{{list[3]}}</p>
                                                <div class="d-flex">
                                                    <div class="tb-se-tag">{{(list[12] == 0)?'buy':(list[12] == 1)?'sell':(list[12] == 6)?'deposit':'-'}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center justify-content-end">
                                        <div>
                                            <ul class="tb-se-num-list">
                                                <li>
                                                    <span>{{list[6]/100}}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div v-show="showIndex == 1">
                                    <ul>
                                        <li>login: <span>{{list[0]}}</span></li>
                                        <li>order: <span>{{list[1]}}</span></li>
                                        <li>symbol: <span>{{decodeURIComponent(list[2])}}</span></li>
                                        <li>volume: <span>{{list[6]/100}}</span></li>
                                        <li>openPrice: <span>{{list[3]}}</span></li>
                                        <li>openTime: <span>{{timeFunc(list[7])}}</span></li>
                                        <li>commision: <span>{{list[10]}}</span></li>
                                        <li>type: <span>{{(list[12] == 0)?'buy':(list[12] == 1)?'sell':(list[12] == 6)?'deposit':'-'}}</span></li>
                                        <li>SL: <span>{{list[13]}}</span></li>
                                        <li>TP: <span>{{list[14]}}</span></li>
                                        <li>Close Price: <span>{{list[4]}}</span></li>
                                        <li>Close Time: <span>{{(list[12] !== 6)?timeFunc(list[8]):'-'}}</span></li>
                                        <li>Swap: <span>{{list[15]}}</span></li>
                                        <li>PNL: <span>{{list[5]}}</span></li>
                                        <li>comment: <span></span>{{decodeURIComponent(list[9])}}</li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                        <div class="fc-su-box box-medium ml-0 mr-0" v-else>
                            <div class="section-title par-blue-horizon text-center mb-4">
                                <h6 class="mb-1">No record Found</h6>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="account_selected && account_selected.platform == 'MT5'">
                        <ul class="row-list-mb15" v-if="((accDetails.history || {}).csv_array || []).filter(v=>v[1]).length > 0">
                            <li v-for="list,i in ((accDetails.history || {}).csv_array || []).filter(v=>v[1])">
                                <div class="tr-bo-set" @click="(showIndex == i)?(showIndex = -1):(showIndex = i)">
                                    <div>
                                        <div class="tb-se-title align-items-start">
                                            <div class="icon"></div>
                                            <div class="section-par">
                                                <h6 class="mb-1">{{decodeURIComponent(list[3])}}</h6>
                                                <p class="mb-0 co-pure-apple">{{list[4]}}</p>
                                                <div class="d-flex">
                                                    <div class="tb-se-tag">{{(list[13] == 0)?'buy':(list[13] == 1)?'sell':(list[13] == 2)?'deposit':'-'}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center justify-content-end">
                                        <div>
                                            <ul class="tb-se-num-list">
                                                <li>
                                                    <span>{{list[9]}}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div v-show="showIndex == 1">
                                    <ul>
                                        <li>login: <span>{{list[0]}}</span></li>
                                        <li>deal: <span>{{list[1]}}</span></li>
                                        <li>symbol: <span>{{decodeURIComponent(list[3])}}</span></li>
                                        <li>type: <span>{{(list[13] == 0)?'buy':(list[13] == 1)?'sell':(list[13] == 2)?'deposit':'-'}}</span></li>
                                        <li>Volume: <span>{{list[9]}}</span></li>
                                        <li>openTime: <span>{{(list[13] !== 2)?timeFunc(list[12]):'-'}}</span></li>
                                        <li>price: <span>{{list[4]}}</span></li>
                                        <li>commission: <span>{{list[6]}}</span></li>
                                        <li>swap: <span>{{list[24]}}</span></li>
                                        <li>pnl: <span>{{list[5]}}</span></li>
                                        <li>comment: <span>{{decodeURIComponent(list[25])}}</span></li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                        <div class="fc-su-box box-medium ml-0 mr-0" v-else>
                            <div class="section-title par-blue-horizon text-center mb-4">
                                <h6 class="mb-1">No record Found</h6>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="account_selected && account_selected.platform == 'ACT'">
                        <ul class="row-list-mb15" v-if="((accDetails.history || {}).csv_array || []).length > 0">
                            <li v-for="list,i in ((accDetails.history || {}).csv_array || [])">
                                <div class="tr-bo-set" @click="(showIndex == i)?(showIndex = -1):(showIndex = i)">
                                    <div>
                                        <div class="tb-se-title align-items-start">
                                            <div class="icon"></div>
                                            <div class="section-par">
                                                <h6 class="mb-1">{{list.INSTRUMENT_NAME}}</h6>
                                                <p class="mb-0 co-pure-apple">{{list.OPEN_RATE}}</p>
                                                <div class="d-flex">
                                                    <div class="tb-se-tag">{{list.TRADE_ID}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center justify-content-end">
                                        <div>
                                            <ul class="tb-se-num-list">
                                                <li>
                                                    <span>{{list.CLOSE_AMOUNT}}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div v-show="showIndex == 1">
                                    <ul>
                                        <li>login: <span>{{list.TRADER}}</span></li>
                                        <li>order: <span>{{list.TRADE_ID}}</span></li>
                                        <li>symbol: <span>{{list.INSTRUMENT_NAME}}</span></li>
                                        <li>volume: <span>{{list.CLOSE_AMOUNT}}</span></li>
                                        <li>openPrice: <span>{{list.OPEN_RATE}}</span></li>
                                        <li>openTime: <span>{{timeFunctionAlign(list.OPEN_TIME)}}</span></li>
                                        <li>Close Price: <span>{{list.CLOSE_RATE}}</span></li>
                                        <li>Close Time: <span>{{timeFunctionAlign(list.CLOSE_TIME)}}</span></li>
                                        <li>PNL: <span>{{list.NET_PNL}}</span></li>
                                        <li>comment: <span>-</span></li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                        <div class="fc-su-box box-medium ml-0 mr-0" v-else>
                            <div class="section-title par-blue-horizon text-center mb-4">
                                <h6 class="mb-1">No record Found</h6>
                            </div>
                        </div>
                    </div>
                    <div class="fc-su-box box-medium ml-0 mr-0" v-else>
                        <div class="section-title par-blue-horizon text-center mb-4">
                            <h6 class="mb-1">Select Trading Account</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { useMainStore } from '@/store/index'
import moment from 'moment'
// import OpenTrade from './trade-list/open-tade.vue'
// import CloseTrade from './trade-list/close-tade.vue'
export default{
    setup(){
        return { store: useMainStore() }
    },
    data(){
        return{
            account_selected : '',
            accDetails:{},
            showIndex: -1
        }
    },
    methods:{
        getList(){
            this.store.getTradingAccountList({
                filter: {},
                sort: [],
                search: '',
                page: 1,
                perPage: 300
            }, !this.store.tradingAccounts.totalRecords).then(res=>{ }).catch(e=>{ })
        },
        getTradingDetail(){
            this.store.getTradingAccountDetail({
                'account_id': this.account_selected.account_id,
                'act_account_id': this.account_selected.act_account_id,
                'demo_live': this.account_selected.demo_live,
                'platform': this.account_selected.platform
            }, true).then(res=>{ this.accDetails = res; }).catch(e=>{})
        },
        timeFunc(getData){
            return moment.unix(getData).format("DD-MM-YYYY, HH:mm:ss");
        },
        timeFuncDate(getData){
            return moment(getData).format("DD-MM-YYYY, HH:mm:ss");
        },
    },
    created(){
        this.getList()
    },
}
</script>