<template>
    <header>
        <div class="header-content header-mobile">
            <div class="header-top container-fluid ">
                <div class="module container width-5">
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="section-title mb-0">
                            <button class="button button-text">
                                <h5 class="d-flex">
                                    <div class="icon mr-3" @click="$router.go(-1)">
                                        <svg width="25" version="1.0" viewBox="0 0 470 342" xmlns="http://www.w3.org/2000/svg">
                                            <g transform="translate(0 342) scale(.1 -.1)">
                                                <path d="m1630 3407c-27-8-212-187-813-786-523-522-784-789-797-817-26-53-26-135 0-188 28-58 1551-1579 1602-1601 52-21 140-19 186 4 106 55 152 182 103 286-13 27-205 225-590 610l-571 570 3795 10 45 25c24 14 58 45 75 68 27 40 30 51 30 122s-3 82-30 122c-17 23-51 54-75 68l-45 25-3795 10 566 565c311 311 573 581 583 600 46 89 33 178-38 250-63 66-140 85-231 57z"></path>
                                            </g>
                                        </svg>
                                    </div> Deposit
                                </h5>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <section id="main-wrapper" style="padding-top: 50px; padding-bottom: 67px;">
        <app-loader v-if="store.loading"></app-loader>
        <div class="container-fluid section-small">
            <div class="module container width-5">
                <div v-if="liveAccount.length > 0" class="fc-su-box box-small" >
                    <Form class="row-list-mb25" @submit="depositRequest()" v-if="step == 1">
                        <div class="form-group">
                            <p>Please select the Currency you would like to make the Deposit in</p>
                            <div class="com_select-box select-box-v3" :class="[{'com_select-box-active':currencySelect}]">
                                <div class="com_select-box-title currencySelect" @click="currencySelect ^= true">
                                    <div class="icon" :style="'background-image: url(/assets/img/crypto_logos/'+depositCurrency.toLowerCase()+'.png);'" v-if="depositCurrency"></div>
                                    <span>{{depositCurrency}}: {{currencyObject[depositCurrency]}}</span>
                                </div>
                                <div class="com_select-box-list currencySelect">
                                    <ul>
                                        <li class="position-relative" style="display: flex;" v-for="val,key in currencyObject" :class="[{'active':depositCurrency == key}]" @click="depositCurrency = key;currencySelect = false">
                                            <div class="my-icon" :style="'background-image: url(/assets/img/crypto_logos/'+key.toLowerCase()+'.png);'"></div>
                                            <span>{{key}}: {{val}}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <Field type="hidden" name="Account" rules="required" v-if="!account_selected.account_id"/>
                            <TradingAccounts class="mb-3" v-model="account_selected" @change="getTradingAccountDetail()"/>
                            <ErrorMessage name="Account"/>
                            <ul v-if="account_selected.account_id" class="d-flex justify-content-between m-2">
                                <li>Current Balance: <span class="accBalanceCls">{{freeMargin}}</span></li>
                                <li>Account Currency: <span class="accCurrencyCls">{{account_selected.currency}}</span></li>
                            </ul>
                            <div class="d-flex flex-column" v-if="account_selected.account_id">
                                <Field class="form-control" placeholder="Enter Amount.." type="number" v-model="amount" name="Amount" rules="required"/>
                                <ErrorMessage name="Amount" />
                            </div>
                        </div>
                        <div class="form-group">
                            <button type="submit" class="button button-quaternary button-wide button-purple">Submit</button>
                        </div>
                    </Form>
                    <div class="white-box" v-else>
                        <h2 class="sub_title text-center f-16">Please Deposit {{ currencyObject[depositCurrency] }} at below given address</h2>
                        
                        <div class="deposit_topprice_sec">
                            <div class="d-flex m-2">
                                <input class="form-control mr-2" type="text" :value="address" placeholder="address">
                                <button type="button" v-clipboard:copy="address" v-clipboard:success="onCopy" v-clipboard:error="onError"  class="border btn p-0 px-2 pt-1 button-purple">
                                    <vue-feather type="copy"></vue-feather>
                                </button>
                            </div>
                            <span v-if="isCopy" class="green f-12">Copied!</span>
                            <p id="memoid" v-if="memoId">Memo ID: {{memoId}}</p>
                            <!-- <h2>{{address}}</h2> -->
                        </div>
                        <div class="box_space text-center">
                            <div class="deposit_lastsec text-center">
                                <div class="deposit_balsec">
                                    <ul>
                                        <li class="border-bottom mb-2"><span>Received </span>
                                            <p id="received">Waiting... {{depositCurrency}}</p>
                                        </li>
                                        <li class="border-bottom mb-2"><span>Credited </span>
                                            <p id="credited">Waiting... {{account_selected.currency}}</p>
                                        </li>
                                        <li><span>Account Balance </span>
                                            <p id="accBalance"> {{freeMargin}}</p>
                                        </li>
                                    </ul>
                                </div>
                                <div class="crypto_addsec">
                                    <p>
                                        Once you make the deposit to above given <strong>{{ currencyObject[depositCurrency] }}</strong> address, we will auto deposit it in your trading account <strong>{{ account_selected.account_id }}</strong>
                                    </p>
                                     
                                    <p>
                                        <button class="button button-quaternary button-midium button-purple"> We are Tracking It <i class="fa fa-smile"></i> </button>
                                    </p>
                                    <p><span>This page will auto refresh every 30 seconds. You have 30 mins to complete the transfer. Once we receive your transaction, we will auto deposit it to your trading account. You DO NOT have to stay on this page. You can navigate away from this page and we will keep monitoring it for you for 30 mins and will update your trading account once we receive your funds.<br>You will be able to check all your previous and current deposit by visiting <router-link to="/deposit-list">deposit history page</router-link></span></p>
                                </div>
                            </div>  
                        </div>
                    </div>
                </div>
                <div class="fc-su-box box-medium ml-0 mr-0" v-else>
                    <div class="section-title par-blue-horizon text-center mb-4">
                        <h6 class="mb-1">No live Trading Account Found</h6>
                        <p>You have currently no Live Trading Account.</p>
                    </div>
                    <div>
                        <router-link to="/trading-accounts" class="button button-quaternary button-wide button-purple">
                            <i class="fal fa-plus mr-2"></i> Create Account
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { useMainStore } from '@/store/index'
import { Field, Form, ErrorMessage } from 'vee-validate';
import { useToast } from 'vue-toastification'
import TradingAccounts from '@/components/trading-accounts.vue'
export default{
    setup(){
        return { store: useMainStore(), toast: useToast() }
    },
    components: { Field, Form, ErrorMessage, TradingAccounts },
    data(){
        return{
            step : 1,
            select_currency: '',
            currencySelect : false,
            depositCurrency: 'BTC',
            account_selected : {},
            freeMargin : 0,
            amount : '',
            memoId : '',
            address: '',
            isCopy: false,
            currencyObject: {
                "BTC": "Bitcoin",
                "LTC": "LiteCoin",
                "ETH": "Etherum (ETH Network)",
                "TUSD": "True USD (ETH Network)",
                "USDC": "USDC (ETH Network)",
                "BCH": "Bitcoin Cash",
                "ALGO": "Algorand",
                "DOGE": "Dogecoin",
                "REP": "Augur (ETH Network - ERC 20 Token)",
                "bat": "Basic Attention Token (ETH Network - ERC 20 Token)",
                "BSV": "Bitcoin SV",
                "LINK": "Chainlink (ETH Network - ERC 20 Token)",
                "BNT": "Bancor (ETH Network - ERC 20 Token)",
                "COMP": "Compound (ETH Network - ERC 20 Token)",
                "cro": "Crypto.com Coin (ETH Network - ERC 20 Token)",
                "USDT": "Tether (ERC 20 Address)",
                "dai": "DAI (ETH Network - ERC 20 Token)",
                "enj": "Enjin Coin (ETH Network - ERC 20 Token)",
                "eos": "EOS",
                "ZIL": "zilliqa (ETH Network - ERC 20 Token)",
                "UNI": "Uniswap (ETH Network - ERC 20 Token)"
            },
        }
    },
    computed:{
        liveAccount(){
            return (this.store.tradingAccounts.records || []).filter(v=>v.demo_live == 'live')
        },
    },
    methods:{
        onCopy(e){
            const _that = this
            this.isCopy = true
            setTimeout(()=>{
                _that.isCopy = false
            },5000)
        },
        onError(e){
            console.log(e);
        },
        getList(){
            this.store.getTradingAccountList({
                filter: {},
                sort: [],
                search: '',
                page: 1,
                perPage: 300
            }, true).then(res=>{
                let id = this.$route.query.id;
                if(id){ this.account_selected = this.liveAccount.find(v=>v.id == id); }
            }).catch(e=>{ })
        },
        getTradingAccountDetail(){
            this.store.getTradingAccountDetail({
                'account_id': this.account_selected.account_id,
                'act_account_id': this.account_selected.act_account_id,
                'demo_live': this.account_selected.demo_live,
                'platform': this.account_selected.platform
            }, true).then(res=>{ this.freeMargin = res.margin_detail.balance; }).catch(e=>{})
        },
        depositRequest(){
            this.store.depositRequest({
                'account_id': this.account_selected.account_id,
                'amount': this.amount,
                "currency": this.depositCurrency
            }, true).then(res=>{
                if(this.depositCurrency == 'eos'){
                    let actual_address = res.address.split('?')
                    res.address = actual_address[0]
                    let memoId = actual_address[1].split('=')
                    this.memoId = memoId[1]
                }
                this.step = 2
                this.address = res.address
            })
        }
    },
    mounted(){
        let _that = this;
        $(document).on("click", function (event) {
            if (!$(event.target).closest(".currencySelect").length) {
                _that.currencySelect = false;
            }
        });
    },
    created(){
        this.getList()
    },
}
</script>
<style>
    .my-icon{
        position: relative;
        width: 22px;
        min-width: 22px;
        height: 22px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 10px;
        display: block;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: cover;
    }
</style>