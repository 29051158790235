<template>
    <header>
        <div class="header-content header-mobile">
            <div class="header-top container-fluid no-shadow">
                <div class="module container width-5">
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="section-title mb-0">
                            <h5>Help Center</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <section id="main-wrapper" style="padding-top: 49px; padding-bottom: 123px;">
        <div class="container-fluid section-small">
            <div class="module container width-5">
                <div class="ac-co-box">
                    <ul>
                        <li><router-link to="/help-center/faq">FAQ</router-link></li>
                        <li><a href="javascript:void(0)">Chat with us</a></li>
                        <li><router-link to="/help-center/call-us">Call us</router-link></li>
                        <li><a href="javascript:void(0)">Submit a ticket</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default{};
</script>