<template>
    <header>
        <div class="header-content header-mobile">
            <div class="header-top container-fluid ">
                <div class="module container width-5">
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="section-title mb-0">
                            <h5>Documents</h5>
                        </div>
                        <ul class="he-to-ctrl">
                            <li>
                                <a class="ht-ct-icon" href="javascript:void(0)">
                                    <svg width="18" version="1.0" viewBox="0 0 511 512" xmlns="http://www.w3.org/2000/svg">
                                        <g transform="translate(0 512) scale(.1 -.1)">
                                            <path d="m93 5094c-25-14-54-44-69-70-27-45-27-46-21-207 14-391 156-777 406-1102 88-115 189-211 678-646 271-241 510-462 531-491 46-62 92-150 118-228 18-52 19-120 24-1131l5-1076 30-48c41-66 97-95 181-95h64l632 423c545 363 636 428 655 461 22 40 22 44 25 705 4 653 5 667 26 739 28 91 72 181 124 250 21 29 260 250 531 490 476 424 592 535 682 652 180 234 311 522 370 814 21 103 25 151 25 296 0 198-7 220-84 265l-43 25h-2424-2424l-42-26zm4602-416c-31-198-117-419-233-598-103-159-169-225-682-683-278-247-533-482-567-521-118-135-202-308-246-501-18-83-20-135-24-685l-5-595-375-250c-206-137-379-251-384-253s-10 387-11 875c-3 868-4 879-25 955-58 206-143 357-276 497-29 30-270 249-537 486-291 260-511 464-551 511-185 220-317 503-353 757l-7 47h2141 2142l-7-42z" />
                                        </g>
                                    </svg>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="module container width-5" v-if="!store.docList.length">
                    <div class="he-mo-nav">
                        <div class="com_draggable">
                            <div class="com_draggable-main">
                                <ul class="pr-nav-list list-wide no-wrap">
                                    <li class="item" v-for="v,i in tabs" :class="[{'active':i==tab}]">
                                        <a href="javascript:void(0)" @click="tab = i">{{v}}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <section id="main-wrapper" style="padding-top: 99px; padding-bottom: 124px;">
        <app-loader v-if="store.loading"></app-loader>
        <div class="container-fluid section-small">
            <div class="module container width-5">
                <ul class="row-list-mb15">
                    <!-- <li v-for="v,i in selectedTab">
                        <div class="tr-bo-set">
                            <div class="row-list-mb1">
                                <div class="row">
                                    <div class="col">
                                        <div class="d-flex align-items-center theme" :class="[{'theme-gold': (v.overallStats.zuluRank == 1), 'theme-silver': (v.overallStats.zuluRank == 2),'theme-bronze': (v.overallStats.zuluRank == 3)}]">
                                            <div class="tt-bo-tag mr-3">#{{v.overallStats.zuluRank}}</div>
                                            <div class="tb-se-title">
                                                <div>
                                                    <img class="icon tb-ic-main" style="background-color: #fff;" width="250"
                                                        :src="zululink+'/webservices/Image.ashx?type=user&size=XL&id='+v.profile.zuluAccountId+'&ignore=false'"
                                                        :alt="v.profile.name"
                                                        :title="v.profile.name"
                                                        >
                                                </div>
                                                <div class="section-par">
                                                    <h6 class="fw-600">{{v.profile.name}}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-auto">
                                        <div class="com_btn-row align-items-center">
                                            <div class="pr-1"><button class="button button-secondary button-wide button-small">
                                                    <div class="icon">
                                                        <svg width="18" version="1.0" viewBox="0 0 470 512" xmlns="http://www.w3.org/2000/svg">
                                                            <g transform="translate(0 512) scale(.1 -.1)">
                                                                <path d="m1713 5105c-203-55-365-219-418-422-22-86-22-2880 0-2966 53-203 218-369 421-422 51-13 215-15 1269-15 1051 0 1219 2 1270 15 213 55 385 234 430 450 22 105 22 2805 0 2910-43 205-199 377-397 440l-79 25-1222-1c-1015 0-1231-3-1274-14z"></path>
                                                                <path d="m460 4044c-202-43-374-201-436-399l-25-80 6-3120 29-70c61-147 151-246 288-315 42-21 101-43 130-49 37-8 478-11 1498-9l1445 3 70 29c142 59 242 149 310 277 58 111 65 155 65 416v232l-1047 4c-1043 3-1048 3-1132 25-178 47-326 136-452 270-83 88-125 153-174 269-72 167-68 100-72 1381l-4 1152-217-1c-146 0-238-5-282-15z"></path>
                                                            </g>
                                                        </svg>
                                                    </div>
                                                </button></div>
                                            <div><button class="button button-text text-blue-horizon cr-wa-more"><i class="far fa-ellipsis-v"></i></button></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="section-par par-blue-horizon">
                                    <h6 class="mb-1">Profit: <span class="co-pure-apple">{{ v.profile.baseCurrencySymbol }}{{ store.loc(timeFrame(v).totalProfitMoney || 0) }}</span></h6>
                                    <p>{{v.overallStats.followers}} Autocopiers • {{v.overallStats.roiAnnualized}} % Win Ratio</p>
                                </div>
                            </div>
                        </div>
                    </li> -->
                </ul>
            </div>
        </div>
    </section>
</template>
<script>
import { useMainStore } from '@/store/index'
const ZULU_URL = process.env.VUE_APP_ZULUTRADE_BASE_URL
export default{
    setup(){
        return { store: useMainStore() }
    },
    data(){
        return{
            tab: 0,
            zululink: ZULU_URL,
            tabs:['Individual Account','Corporate Account']
        }
    },
    computed:{ },
    methods:{
        getTopTradersList() {
            this.store.getDocList({}, !this.store.docList.length).then(res => { console.log('------->',res) }).catch(e=>{ })
        },
    },
    created() {
        this.getTopTradersList()
    }
};
</script>
<style>
    .tt-bo-tag.mr-3 {
        width: 46px;
    }
</style>