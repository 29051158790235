<template>
    <header>
        <div class="header-content header-mobile">
            <div class="header-top container-fluid no-shadow">
                <div class="module container width-5">
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="section-title mb-0">
                            <button class="button button-text">
                                <h5 class="d-flex">
                                    <div class="icon mr-3">
                                        <svg width="25" version="1.0" viewBox="0 0 470 342" xmlns="http://www.w3.org/2000/svg">
                                            <g transform="translate(0 342) scale(.1 -.1)">
                                                <path d="m1630 3407c-27-8-212-187-813-786-523-522-784-789-797-817-26-53-26-135 0-188 28-58 1551-1579 1602-1601 52-21 140-19 186 4 106 55 152 182 103 286-13 27-205 225-590 610l-571 570 3795 10 45 25c24 14 58 45 75 68 27 40 30 51 30 122s-3 82-30 122c-17 23-51 54-75 68l-45 25-3795 10 566 565c311 311 573 581 583 600 46 89 33 178-38 250-63 66-140 85-231 57z"></path>
                                            </g>
                                        </svg>
                                    </div> Messenger
                                </h5>
                            </button>
                        </div>
                        <ul class="he-to-ctrl">
                            <li><a class="ht-ct-icon" href="#"><i class="far fa-search ml-0"></i></a></li>
                            <li>
                                <a class="ht-ct-icon" href="#">
                                    <svg width="18" version="1.0" viewBox="0 0 511 512" xmlns="http://www.w3.org/2000/svg">
                                        <g transform="translate(0 512) scale(.1 -.1)">
                                            <path d="m93 5094c-25-14-54-44-69-70-27-45-27-46-21-207 14-391 156-777 406-1102 88-115 189-211 678-646 271-241 510-462 531-491 46-62 92-150 118-228 18-52 19-120 24-1131l5-1076 30-48c41-66 97-95 181-95h64l632 423c545 363 636 428 655 461 22 40 22 44 25 705 4 653 5 667 26 739 28 91 72 181 124 250 21 29 260 250 531 490 476 424 592 535 682 652 180 234 311 522 370 814 21 103 25 151 25 296 0 198-7 220-84 265l-43 25h-2424-2424l-42-26zm4602-416c-31-198-117-419-233-598-103-159-169-225-682-683-278-247-533-482-567-521-118-135-202-308-246-501-18-83-20-135-24-685l-5-595-375-250c-206-137-379-251-384-253s-10 387-11 875c-3 868-4 879-25 955-58 206-143 357-276 497-29 30-270 249-537 486-291 260-511 464-551 511-185 220-317 503-353 757l-7 47h2141 2142l-7-42z" />
                                        </g>
                                    </svg>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <section id="main-wrapper" style="padding-top: 50px; padding-bottom: 67px;">
        <div class="container-fluid section-small pt-0 pb-0">
            <div class="module container width-5">
                <div>
                    <div class="pt10 pb10">
                        <div class="tr-bo-set set-small">
                            <div>
                                <div class="fs-bo-set">
                                    <div class="d-flex align-items-center">
                                        <div class="icon"><svg width="23" version="1.0" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                                                <g transform="translate(0 512) scale(.1 -.1)">
                                                    <path d="m2320 5105c-166-28-298-73-450-151-384-199-661-562-762-998-30-131-37-402-13-551 51-329 238-671 479-875 64-54 72-70 36-70-10 0-74-16-142-35-542-155-1006-431-1234-734-109-146-183-317-219-502-22-118-22-655 1-742 53-206 225-378 430-431 56-14 273-16 2114-16 1832 0 2059 2 2113 16 208 54 377 223 431 431 23 87 23 611 1 739-43 246-172 485-355 658-142 134-366 283-569 379-98 46-108 49-171 45-127-7-196-86-188-212 6-103 31-130 185-208 217-110 363-209 474-319 71-71 111-130 159-232 64-138 74-206 75-492 0-237-1-252-21-290-25-46-45-65-94-91-34-18-100-19-2040-19s-2006 1-2040 19c-49 26-69 45-94 91-20 38-21 53-21 290 1 328 22 425 131 597 151 237 565 487 1024 618 286 82 514 116 875 130 272 11 369 21 487 51 557 140 973 577 1095 1149 26 121 26 459 0 580-78 367-278 681-574 904-172 129-426 236-643 271-115 18-374 18-480 0zm439-411c424-76 774-425 855-854 20-105 20-295 0-400-81-429-425-772-855-855-108-20-290-20-398 0-430 83-774 426-855 855-20 106-20 295 0 400 88 467 490 831 959 868 110 9 185 5 294-14z"></path>
                                                </g>
                                            </svg></div>
                                        <div class="section-par par-blue-horizon">
                                            <h6 class="mb-0 co-dark-blue">Channel Name Goes Here</h6>
                                            <p>200 members online</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="com_box-scroller-parent ml-pull-15 mr-pull-15 ml-pull-md-0 mr-pull-md-0">
                        <div class="me-co-col">
                            <div class="com_box-scroller com_messenger-scroller">
                                <div class="me-ch-list">
                                    <div>
                                        <div class="fs-bo-set">
                                            <div class="d-flex align-items-end">
                                                <div class="icon"><svg width="23" version="1.0" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                                                        <g transform="translate(0 512) scale(.1 -.1)">
                                                            <path d="m2320 5105c-166-28-298-73-450-151-384-199-661-562-762-998-30-131-37-402-13-551 51-329 238-671 479-875 64-54 72-70 36-70-10 0-74-16-142-35-542-155-1006-431-1234-734-109-146-183-317-219-502-22-118-22-655 1-742 53-206 225-378 430-431 56-14 273-16 2114-16 1832 0 2059 2 2113 16 208 54 377 223 431 431 23 87 23 611 1 739-43 246-172 485-355 658-142 134-366 283-569 379-98 46-108 49-171 45-127-7-196-86-188-212 6-103 31-130 185-208 217-110 363-209 474-319 71-71 111-130 159-232 64-138 74-206 75-492 0-237-1-252-21-290-25-46-45-65-94-91-34-18-100-19-2040-19s-2006 1-2040 19c-49 26-69 45-94 91-20 38-21 53-21 290 1 328 22 425 131 597 151 237 565 487 1024 618 286 82 514 116 875 130 272 11 369 21 487 51 557 140 973 577 1095 1149 26 121 26 459 0 580-78 367-278 681-574 904-172 129-426 236-643 271-115 18-374 18-480 0zm439-411c424-76 774-425 855-854 20-105 20-295 0-400-81-429-425-772-855-855-108-20-290-20-398 0-430 83-774 426-855 855-20 106-20 295 0 400 88 467 490 831 959 868 110 9 185 5 294-14z"></path>
                                                        </g>
                                                    </svg></div>
                                                <div>
                                                    <p class="mb-2">Person's Name 2</p>
                                                    <div class="me-ch-bubble section-par">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do <a href="#">eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim reprehenderit in voluptate velit esse</a></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="fs-bo-set">
                                            <div class="d-flex align-items-end">
                                                <div class="icon"><svg width="23" version="1.0" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                                                        <g transform="translate(0 512) scale(.1 -.1)">
                                                            <path d="m2320 5105c-166-28-298-73-450-151-384-199-661-562-762-998-30-131-37-402-13-551 51-329 238-671 479-875 64-54 72-70 36-70-10 0-74-16-142-35-542-155-1006-431-1234-734-109-146-183-317-219-502-22-118-22-655 1-742 53-206 225-378 430-431 56-14 273-16 2114-16 1832 0 2059 2 2113 16 208 54 377 223 431 431 23 87 23 611 1 739-43 246-172 485-355 658-142 134-366 283-569 379-98 46-108 49-171 45-127-7-196-86-188-212 6-103 31-130 185-208 217-110 363-209 474-319 71-71 111-130 159-232 64-138 74-206 75-492 0-237-1-252-21-290-25-46-45-65-94-91-34-18-100-19-2040-19s-2006 1-2040 19c-49 26-69 45-94 91-20 38-21 53-21 290 1 328 22 425 131 597 151 237 565 487 1024 618 286 82 514 116 875 130 272 11 369 21 487 51 557 140 973 577 1095 1149 26 121 26 459 0 580-78 367-278 681-574 904-172 129-426 236-643 271-115 18-374 18-480 0zm439-411c424-76 774-425 855-854 20-105 20-295 0-400-81-429-425-772-855-855-108-20-290-20-398 0-430 83-774 426-855 855-20 106-20 295 0 400 88 467 490 831 959 868 110 9 185 5 294-14z"></path>
                                                        </g>
                                                    </svg></div>
                                                <div>
                                                    <p class="mb-2">Person's Name 2</p>
                                                    <div class="me-ch-bubble section-par">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="text-center fw-600 co-blue-horizon">Friday June 17,2022</div>
                                    <div class="me-ch-set set-right">
                                        <div class="me-ch-bubble bubble-green section-par">cillum dolore eu fugiat nulla pariatur.</div>
                                    </div>
                                    <div>
                                        <div class="fs-bo-set">
                                            <div class="d-flex align-items-end">
                                                <div class="icon"><svg width="23" version="1.0" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                                                        <g transform="translate(0 512) scale(.1 -.1)">
                                                            <path d="m2320 5105c-166-28-298-73-450-151-384-199-661-562-762-998-30-131-37-402-13-551 51-329 238-671 479-875 64-54 72-70 36-70-10 0-74-16-142-35-542-155-1006-431-1234-734-109-146-183-317-219-502-22-118-22-655 1-742 53-206 225-378 430-431 56-14 273-16 2114-16 1832 0 2059 2 2113 16 208 54 377 223 431 431 23 87 23 611 1 739-43 246-172 485-355 658-142 134-366 283-569 379-98 46-108 49-171 45-127-7-196-86-188-212 6-103 31-130 185-208 217-110 363-209 474-319 71-71 111-130 159-232 64-138 74-206 75-492 0-237-1-252-21-290-25-46-45-65-94-91-34-18-100-19-2040-19s-2006 1-2040 19c-49 26-69 45-94 91-20 38-21 53-21 290 1 328 22 425 131 597 151 237 565 487 1024 618 286 82 514 116 875 130 272 11 369 21 487 51 557 140 973 577 1095 1149 26 121 26 459 0 580-78 367-278 681-574 904-172 129-426 236-643 271-115 18-374 18-480 0zm439-411c424-76 774-425 855-854 20-105 20-295 0-400-81-429-425-772-855-855-108-20-290-20-398 0-430 83-774 426-855 855-20 106-20 295 0 400 88 467 490 831 959 868 110 9 185 5 294-14z"></path>
                                                        </g>
                                                    </svg></div>
                                                <div>
                                                    <p class="mb-2">Person's Name 2</p>
                                                    <div class="me-ch-bubble section-par">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do <a href="#">eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim reprehenderit in voluptate velit esse</a></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="fs-bo-set">
                                            <div class="d-flex align-items-end">
                                                <div class="icon"><svg width="23" version="1.0" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                                                        <g transform="translate(0 512) scale(.1 -.1)">
                                                            <path d="m2320 5105c-166-28-298-73-450-151-384-199-661-562-762-998-30-131-37-402-13-551 51-329 238-671 479-875 64-54 72-70 36-70-10 0-74-16-142-35-542-155-1006-431-1234-734-109-146-183-317-219-502-22-118-22-655 1-742 53-206 225-378 430-431 56-14 273-16 2114-16 1832 0 2059 2 2113 16 208 54 377 223 431 431 23 87 23 611 1 739-43 246-172 485-355 658-142 134-366 283-569 379-98 46-108 49-171 45-127-7-196-86-188-212 6-103 31-130 185-208 217-110 363-209 474-319 71-71 111-130 159-232 64-138 74-206 75-492 0-237-1-252-21-290-25-46-45-65-94-91-34-18-100-19-2040-19s-2006 1-2040 19c-49 26-69 45-94 91-20 38-21 53-21 290 1 328 22 425 131 597 151 237 565 487 1024 618 286 82 514 116 875 130 272 11 369 21 487 51 557 140 973 577 1095 1149 26 121 26 459 0 580-78 367-278 681-574 904-172 129-426 236-643 271-115 18-374 18-480 0zm439-411c424-76 774-425 855-854 20-105 20-295 0-400-81-429-425-772-855-855-108-20-290-20-398 0-430 83-774 426-855 855-20 106-20 295 0 400 88 467 490 831 959 868 110 9 185 5 294-14z"></path>
                                                        </g>
                                                    </svg></div>
                                                <div>
                                                    <p class="mb-2">Person's Name 2</p>
                                                    <div class="me-ch-bubble section-par">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="text-center fw-600 co-blue-horizon">Friday June 17,2022</div>
                                    <div class="me-ch-set set-right">
                                        <div class="me-ch-bubble bubble-green section-par">cillum dolore eu fugiat nulla pariatur.</div>
                                    </div>
                                    <div class="text-center fw-600 co-blue-horizon">8:30 PM</div>
                                </div>
                            </div>
                        </div>
                        <div class="me-co-col col-bottom com_messenger-form">
                            <div class="mc-bo-row">
                                <div class="mc-bo-text" contenteditable="true">Type a Message</div>
                                <div class="mc-bo-ctrl pt10 pb10">
                                    <div><button class="mc-bo-btn"><img src="/assets/img/icon_smile.png" width="24" alt=""></button></div>
                                    <div><button class="mc-bo-btn btn-attach"><svg width="22" version="1.0" viewBox="0 0 512 488" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M347.5 1.5c-24.3 3.4-45.4 12-66.5 27.3C272.2 35.2 33.4 273 23.6 285.3c-7 8.7-15.3 23.9-18.4 33.7C1 332.4.5 336.2.5 354.5c0 16.5.3 18.8 2.8 28 10.2 36.8 37.6 65.6 73.2 76.8 12.3 3.9 19.8 5 33.5 5 24.2 0 42-5.4 63-19.1 7.2-4.7 24.5-21.5 114.9-111.6 58.5-58.4 109.7-109.9 113.8-114.5 11.9-13.5 17.4-25.3 19.2-41 3.7-33.3-15.8-64.9-46.7-75.7-22.4-7.8-48.6-3.9-66.6 9.8-7.5 5.7-212.7 211.2-214.7 215-2.1 4.1-2.5 12-.8 16.5 3.4 9 16.5 14.1 25.2 9.7 1.8-.9 50.5-48.7 108.2-106.3C343.6 129.2 334.2 137.5 350 137.5c7.1 0 9.3.4 13.3 2.5 7.6 3.8 12.3 8.5 15.8 15.6 5.2 10.6 5 21-.7 30.9-1.3 2.2-52.6 54.2-114.1 115.5-121.1 120.9-115.9 116.1-132.8 121.7-7 2.3-9.7 2.7-21.5 2.7-15 .1-20.2-1-32.1-6.9-18.5-9.1-31.7-24.9-38-45.5-3.4-10.7-3.4-28.3 0-39 2.9-9.5 6.7-17 12.4-24.5 2.5-3.3 59.6-60.9 126.9-128.1 94.2-94 124-123.1 129.6-126.8 43-28.2 96.5-23.8 133.1 10.9 19.4 18.4 30.6 41.3 33 67.8 1.7 17.5-1.7 37.4-8.9 53.4-8 17.6-6.1 15.5-138.1 147.8C192 471.6 202.1 460.6 203.2 472c1.2 12.2 14.8 19.5 26.3 14.1 2.8-1.3 41.6-39.6 127.1-125.2 95.9-96.2 124.4-125.2 128.9-131.8 11.4-16.5 20.1-36.4 24.2-56.1 2.6-12.1 2.6-45 0-57-10.3-48.4-43.5-87.8-89.1-106-22-8.7-49.2-11.9-73.1-8.5z" />
                                            </svg></button></div>
                                    <div><button class="mc-bo-btn btn-send"><svg width="18" version="1.0" viewBox="0 0 512 478" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M475.5.4c-.5.3-17.2 5.7-37 12.1C365.6 36.2 305.1 58 125.6 125.4 1.3 172.1 5.5 170.3 1.4 178.8.3 181.3-.1 184.2.2 189c.4 7.9 3.1 12.7 8.9 15.9 2 1.2 20 7 40 13s51.9 15.7 70.9 21.6c23.6 7.3 35.4 10.5 37.3 10.1 1.5-.3 35-20.9 74.5-45.8 127.8-80.5 149.2-93.7 150.6-93.2 1.8.7 2.6 2.7 1.8 4.1-.4.6-40.5 37.5-89.2 82.2-48.7 44.6-89.5 82.5-90.7 84.2-2.7 3.7-2.9 7.8-.8 11.8.8 1.6 8.3 13.4 16.8 26.2 24.4 37.2 54.4 83.7 76.9 119.4 11.5 18.1 22.1 33.9 23.5 35 6.5 5 14.2 5.7 21.9 2.1 2.9-1.3 6.4-4 7.9-6 1.5-1.9 5.6-11.1 9.2-20.3 3.6-9.3 17.3-44.5 30.5-78.3 59.1-151.4 80.6-209.2 107.1-288.5 13.9-41.3 14.1-42.1 14.1-51 .1-7.7-.3-9.7-2.4-13.8-3-5.9-9.6-12.2-15.8-14.9C489 .9 477.9-.5 475.5.4z" />
                                                <path d="M148.4 285.4l-3.4 3.4v30.8c0 17 .5 46.5 1 65.5.7 28.4 1.2 35.3 2.6 38.8 3.6 8.9 14.9 14.7 24.2 12.2 4.5-1.3 7.3-3.6 33-27.6 17.3-16.2 19.2-18.8 19.2-27 0-7.2 2.1-3.5-33.9-58.5-12.8-19.5-24.5-36.7-26.2-38.3-2.4-2.2-3.9-2.7-8-2.7-4.5 0-5.5.4-8.5 3.4z" />
                                            </svg></button></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default{};
</script>