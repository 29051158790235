<template>
    <section id="main-wrapper">
        <div class="container-fluid section-small">
            <div class="module container width-5">
                <div class="row-list-mb15">
                    <div class="se-co-box">
                        <ul class="sc-bo-list">
                            <li>
                                <div class="sc-bo-opt">
                                    <div>Ask me always if I want to close my trade</div>
                                    <label class="com_toggle-btn">
                                        <input type="checkbox" name="" value="" checked>
                                        <div class="icon"></div>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div class="sc-bo-opt">
                                    <div>Auto play videos on News Feed</div>
                                    <label class="com_toggle-btn">
                                        <input type="checkbox" name="" value="" checked>
                                        <div class="icon"></div>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div class="sc-bo-opt">
                                    <div>Don't show open trades on my feed</div>
                                    <label class="com_toggle-btn">
                                        <input type="checkbox" name="" value="">
                                        <div class="icon"></div>
                                    </label>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default{};
</script>