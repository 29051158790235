<template>
    <header>
        <div class="header-content header-mobile">
            <div class="header-top container-fluid no-shadow"></div>
        </div>
    </header>
    <section id="main-wrapper" style="padding-top: 30px; padding-bottom: 67px;">
        <app-loader v-if="store.loading"></app-loader>
        <div class="container-fluid section-small">
            <div class="module container width-5">
                <div class="pr-co-box ml-pull-15 mr-pull-15 ml-pull-md-0 mr-pull-md-0">
                    <div class="pc-bo-row row-top">
                        <div class="com_banner bp-ab" style="background-image: url('/assets/img/dummy/profile_cover.jpg');"></div>
                        <div class="pr-bo-banner d-flex align-items-center justify-content-center text-center co-white bp-rel">
                            <div class="row-list-mb15 row-list-md-mb3">
                                <div class="sp-ba-icon icon-large mr-0 position-relative">
                                    <svg width="33" version="1.0" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                                        <g transform="translate(0 512) scale(.1 -.1)">
                                            <path d="m2320 5105c-166-28-298-73-450-151-384-199-661-562-762-998-30-131-37-402-13-551 51-329 238-671 479-875 64-54 72-70 36-70-10 0-74-16-142-35-542-155-1006-431-1234-734-109-146-183-317-219-502-22-118-22-655 1-742 53-206 225-378 430-431 56-14 273-16 2114-16 1832 0 2059 2 2113 16 208 54 377 223 431 431 23 87 23 611 1 739-43 246-172 485-355 658-142 134-366 283-569 379-98 46-108 49-171 45-127-7-196-86-188-212 6-103 31-130 185-208 217-110 363-209 474-319 71-71 111-130 159-232 64-138 74-206 75-492 0-237-1-252-21-290-25-46-45-65-94-91-34-18-100-19-2040-19s-2006 1-2040 19c-49 26-69 45-94 91-20 38-21 53-21 290 1 328 22 425 131 597 151 237 565 487 1024 618 286 82 514 116 875 130 272 11 369 21 487 51 557 140 973 577 1095 1149 26 121 26 459 0 580-78 367-278 681-574 904-172 129-426 236-643 271-115 18-374 18-480 0zm439-411c424-76 774-425 855-854 20-105 20-295 0-400-81-429-425-772-855-855-108-20-290-20-398 0-430 83-774 426-855 855-20 106-20 295 0 400 88 467 490 831 959 868 110 9 185 5 294-14z"></path>
                                        </g>
                                    </svg>
                                    <div class="position-absolute" style="top: 0;right: 0;"><vue-feather type="edit"></vue-feather></div>
                                </div>
                                <div class="section-par">
                                    <h3>{{((profile.namef || '')+' '+(profile.namel || '')).trim()}}</h3>
                                </div>
                            </div>
                        </div>
                        <div class="pr-bo-tags tags-visible top-left">
                            <button class="sp-ba-btn btn-small btn-black mob-icon-only" @click="$router.go(-1)">
                                <div class="icon">
                                    <svg width="25" version="1.0" viewBox="0 0 470 342" xmlns="http://www.w3.org/2000/svg">
                                        <g transform="translate(0 342) scale(.1 -.1)">
                                            <path d="m1630 3407c-27-8-212-187-813-786-523-522-784-789-797-817-26-53-26-135 0-188 28-58 1551-1579 1602-1601 52-21 140-19 186 4 106 55 152 182 103 286-13 27-205 225-590 610l-571 570 3795 10 45 25c24 14 58 45 75 68 27 40 30 51 30 122s-3 82-30 122c-17 23-51 54-75 68l-45 25-3795 10 566 565c311 311 573 581 583 600 46 89 33 178-38 250-63 66-140 85-231 57z" />
                                        </g>
                                    </svg>
                                </div>
                            </button>
                        </div>
                        <!-- <div class="pr-bo-tags tags-visible top-right">
                            <button class="sp-ba-btn btn-small mob-icon-only">
                                <span class="icon">
                                    <svg width="25" version="1.0" viewBox="0 0 512 476" xmlns="http://www.w3.org/2000/svg">
                                        <g transform="translate(0 476) scale(.1 -.1)">
                                            <path d="m600 4740c-282-73-509-301-580-584-20-77-20-115-20-1596 0-1724-7-1580 86-1770 41-82 61-108 148-195 86-86 115-108 196-148 189-94 53-87 1708-87h1455l43 25c63 37 94 95 94 175s-31 138-94 175l-43 25h-396-395l-230 297c-128 166-226 302-222 308s330 421 724 922l717 913 495-548c342-378 507-554 536-569 91-50 215-20 269 65l29 46v941c0 1069 4 1011-84 1190-101 203-296 359-520 415-78 20-115 20-1960 19-1826 0-1883-1-1956-19zm3765-390c95-14 165-49 231-114 65-66 100-136 114-231 5-41 10-312 10-643v-573l-395 438c-218 241-414 450-437 466-55 36-150 38-209 5-30-16-222-255-816-1010-427-543-779-988-782-988-3 1-125 156-271 346-298 388-304 394-410 394-104 0-113-8-373-345-127-165-240-317-250-337-25-50-24-134 2-186 37-73 132-122 210-107s105 39 242 217c73 95 141 183 152 195l19 22 874-1134-715-3c-453-2-745 1-795 8-104 13-174 46-242 114-65 66-100 136-114 231-13 95-13 2795 0 2890 14 95 49 165 114 231 64 63 138 101 222 113 86 13 3532 13 3619 1z"></path>
                                            <path d="m1235 4021c-205-60-367-225-420-428-20-75-19-229 1-306 53-206 225-378 430-431 79-20 228-21 307 0 208 54 377 223 431 431 21 78 21 228 0 306-54 208-223 377-431 431-82 21-240 20-318-3zm253-410c67-34 102-93 102-171 0-111-79-190-190-190s-190 79-190 190 77 189 188 190c33 0 67-7 90-19z"></path>
                                            <path d="m4180 1861c-30-10-74-28-98-40-54-28-495-452-528-507-34-58-34-151 1-210 37-62 95-94 174-94 37 0 75 6 94 16 18 9 91 75 164 147l132 130 6-1158 23-43c34-64 99-102 176-102s134 27 172 82l29 41 10 1180 123-129c150-157 175-174 261-174 81 0 139 31 176 94 33 57 35 154 4 205-37 61-430 461-493 503-33 21-88 47-124 58-83 26-224 26-302 1z"></path>
                                        </g>
                                    </svg>
                                </span>
                            </button>
                            <button class="sp-ba-btn btn-small mob-icon-only">
                                <span class="icon">
                                    <svg width="25" version="1.0" viewBox="0 0 480 448" xmlns="http://www.w3.org/2000/svg">
                                        <g transform="translate(0 448) scale(.1 -.1)">
                                            <path d="m328 4461c-145-47-269-174-312-320-14-49-16-203-16-1422 0-1314 1-1370 19-1428 46-147 172-272 320-315 44-13 109-16 338-16h283v-427c0-409 1-429 20-461 29-48 75-72 138-72 29 0 63 5 75 11 12 7 286 223 608 480l587 469h1010c963 0 1013 1 1071 19 147 46 272 172 315 320 14 49 16 203 16 1421s-2 1372-16 1421c-43 148-168 274-315 320-59 18-125 19-2072 18-1923 0-2015-1-2069-18zm4016-311c49-11 85-37 111-81 20-34 20-52 20-1349s0-1315-20-1349c-26-44-62-70-111-81-22-6-483-10-1060-10h-1021l-35-24c-19-13-238-186-486-385-249-198-454-361-457-361s-5 150-5 333c0 311-1 334-20 364-41 69-34 67-445 73-350 5-372 6-405 26-48 28-78 75-85 137-3 29-5 624-3 1322 3 1249 3 1271 23 1304 25 42 62 71 106 81 48 11 3845 12 3893 0z" />
                                            <path d="m1032 3180c-48-30-72-75-72-140s24-110 72-140c32-20 53-20 1368-20s1336 0 1368 20c48 30 72 75 72 140s-24 110-72 140c-32 20-53 20-1368 20s-1336 0-1368-20z" />
                                            <path d="m1032 2540c-48-30-72-75-72-140s24-110 72-140c32-19 52-20 728-20s696 1 728 20c48 30 72 75 72 140s-24 110-72 140c-32 19-52 20-728 20s-696-1-728-20z" />
                                        </g>
                                    </svg>
                                </span>
                            </button>
                        </div> -->
                    </div>
                    <div class="pc-bo-row">
                        <div class="pb-ro-row">
                            <div class="pb-ro-col col-short d-flex align-items-center">
                                <a class="pb-ro-set" href="#">
                                    <div class="icon">
                                        <svg width="18" version="1.0" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                                            <g transform="translate(0 512) scale(.1 -.1)">
                                                <path d="m2320 5105c-166-28-298-73-450-151-384-199-661-562-762-998-30-131-37-402-13-551 51-329 238-671 479-875 64-54 72-70 36-70-10 0-74-16-142-35-542-155-1006-431-1234-734-109-146-183-317-219-502-22-118-22-655 1-742 53-206 225-378 430-431 56-14 273-16 2114-16 1832 0 2059 2 2113 16 208 54 377 223 431 431 23 87 23 611 1 739-43 246-172 485-355 658-142 134-366 283-569 379-98 46-108 49-171 45-127-7-196-86-188-212 6-103 31-130 185-208 217-110 363-209 474-319 71-71 111-130 159-232 64-138 74-206 75-492 0-237-1-252-21-290-25-46-45-65-94-91-34-18-100-19-2040-19s-2006 1-2040 19c-49 26-69 45-94 91-20 38-21 53-21 290 1 328 22 425 131 597 151 237 565 487 1024 618 286 82 514 116 875 130 272 11 369 21 487 51 557 140 973 577 1095 1149 26 121 26 459 0 580-78 367-278 681-574 904-172 129-426 236-643 271-115 18-374 18-480 0zm439-411c424-76 774-425 855-854 20-105 20-295 0-400-81-429-425-772-855-855-108-20-290-20-398 0-430 83-774 426-855 855-20 106-20 295 0 400 88 467 490 831 959 868 110 9 185 5 294-14z"></path>
                                            </g>
                                        </svg>
                                    </div>
                                    <div class="section-par">
                                        <h6>Verify Profile <span class="co-dark-blue pl-2">2/4</span></h6>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="module container width-5">
                <div class="row row-list-mb2">
                    <!-- <div class="col-12">
                        <h6 class="mb-1 co-blue-horizon d-flex justify-content-between">
                            <span>Risk Score</span>
                            <span class="co-dark-blue">9/10</span>
                        </h6>
                        <div class="com_gradient-bar mt-2">
                            <div class="com_gradient-bar-indicator" style="width: 100%;"></div>
                        </div>
                        <div class="row row-extra-small mt-2">
                            <div class="col"><button class="button button-secondary button-size-2 button-wide text-uppercase">Auto Copy</button></div>
                            <div class="col"><button class="button button-secondary button-size-2 button-wide button-blue button-line text-uppercase">Follow</button></div>
                        </div>
                    </div> -->
                    <div class="col-6">
                        <div class="section-par co-dark-blue">
                            <h6 class="mb-1 co-blue-horizon">Email</h6>
                            <h6>{{store.user.email}}</h6>
                        </div>
                    </div>
                    <div class="col-6 text-right">
                        <div class="section-par co-dark-blue">
                            <h6 class="mb-1 co-blue-horizon">Country</h6>
                            <h6>{{store.user.country}}</h6>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="section-par co-dark-blue">
                            <h6 class="mb-1 co-blue-horizon">Mobile No.</h6>
                            <h6>{{store.user.mobile_no}}</h6>
                        </div>
                    </div>
                    <div class="col-6 text-right">
                        <div class="section-par co-dark-blue">
                            <h6 class="mb-1 co-blue-horizon">Pan No.</h6>
                            <h6>{{store.user.pan_no || '-'}}</h6>
                        </div>
                    </div>
                    <!-- <div class="col-6">
                        <div class="section-par co-dark-blue">
                            <h6 class="mb-1 co-blue-horizon">Visitors</h6>
                            <h6>0</h6>
                        </div>
                    </div>
                    <div class="col-6 text-right">
                        <div class="section-par co-dark-blue">
                            <h6 class="mb-1 co-blue-horizon">Copying</h6>
                            <h6>0</h6>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="section-par co-dark-blue">
                            <h6 class="mb-1 co-blue-horizon">About Me</h6>
                            <h6>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud </h6>
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="module container width-5">
                <div class="row-list-mb15">
                    <!-- <div class="fc-su-box bd-top pt-0 pb-0 pl-0 pr-0 overflow-hidden">
                        <div class="com_draggable">
                            <div class="com_draggable-main">
                                <ul class="pr-nav-list no-wrap">
                                    <li class="item active"><a href="#">Feed</a></li>
                                    <li class="item"><a href="#">Stats</a></li>
                                    <li class="item"><a href="#">Track Record</a></li>
                                    <li class="item"><a href="#">VIP Levels</a></li>
                                    <li class="item"><a href="#">Trades</a></li>
                                </ul>
                            </div>
                        </div>
                    </div> -->
                    <div class="fc-su-box">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="section-title mb-0">
                                <h5>Activity</h5>
                            </div>
                            <!-- <div class="pr-co-nav">
                                <ul>
                                    <li class="active"><a href="#">All</a></li>
                                    <li><a href="#">Post</a></li>
                                    <li><a href="#">Trades</a></li>
                                </ul>
                            </div> -->
                        </div>
                    </div>
                    <div class="fc-su-box box-small">
                        <div class="fc-su-box box-simple">
                            <div class="com_user-row">
                                <div class="icon" @click="$refs.document.click()">
                                    <i class="fas fa-paperclip f-22"></i>
                                    <input class="d-none" type="file" accept="image/*" ref="document"  @change="getFile($event)">
                                </div>
                                <div>
                                    <div class="form-control com_user-row">
                                        <input type="text" placeholder="Share something" v-model="postDisc" class="f-14 border-0" >
                                        <i class="fas fa-paper-plane f-22" style="width:20px;" v-if="postDisc" @click="newPost()"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="alert alert-warning" v-if="fileSel.name">
                              <strong>File:</strong> {{fileSel.name}}
                              <button type="button" class="close" @click="clearFile()">&times;</button>
                            </div>
                        </div>
                    </div>
                    <div class="fc-su-box" v-for="item,i in (store.userFeedsList.records || [])">
                        <div class="fs-bo-set">
                            <div class="d-flex align-items-center">
                                <div class="icon">
                                    <img src="/assets/img/user-ico-white.svg">
                                </div>
                                <div>
                                    <p class="mb-0">
                                        <span class="fw-500">{{((item.namef || '')+' '+(item.namel || '')).trim() || 'Unknown Uses'}}</span> <br>
                                        <span class="co-blue-horizon">Suggested <span>•</span> {{fromNow(item.created)}}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="section-par" :class="[{'mb-0':!item.document}]">
                            <p>{{item.title}}</p>
                        </div> -->
                        <div class="position-fixed all-0 container width-5" v-if="showImg == i">
                            <div @click="showImg = -1"><vue-feather type="x"></vue-feather></div>
                            <img :src="global_url+'/get/docs?type=post&id='+item.user_id+'&doc='+item.document">
                        </div>
                        <div class="fc-su-banner" v-if="item.document" :style="'background-image: url('+global_url+'/get/docs?type=post&id='+item.user_id+'&doc='+item.document+');'" @click="showImg = i"></div>
                        <div class="section-par">
                            <p v-if="showMore == item.trade_id">{{item.description}}</p>
                            <p v-else>{{item.description.slice(0,200)}}</p>
                        </div>
                        <button class="button button-text text-jade-dust" v-if="item.description.length > 200" @click="(showMore == item.trade_id)?(showMore = -1):(showMore = item.trade_id)">{{(showMore == item.trade_id)?'Less':'More'}}...</button>
                        <!-- <button class="button button-text text-blue-horizon">See Translation</button> -->
                        <div class="mb-pull-10">
                            <div class="d-flex justify-content-between">
                                <a class="pb-op-set" href="javascript:void(0)">
                                    <div class="icon icon-solid"><i class="fa fa-heart"></i></div> {{item.likes_count}}
                                </a>
                                <div><button class="button button-text text-blue-horizon" @click="getComment(item)">{{item.comment_count}} Comments</button></div>
                            </div>
                            <hr class="mt-1 mb-1">
                            <div class="com_user-opt">
                                <div class="item">
                                    <a href="javascript:void(0)" @click="likeUnlike(item,'post')">
                                        <div class="icon"><i class="fa-heart" :class="(item.is_like)?'fa':'far'" :style="(item.is_like)?'color: var(--jade-dust);':''"></i></div>{{(item.is_like)?'Unlike':'Like'}}
                                    </a>
                                </div>
                                <div class="item">
                                    <a href="javascript:void(0)" @click="(isComment == item.trade_id)?(isComment = ''):(isComment = item.trade_id)">
                                        <div class="icon"><i class="far fa-comment-alt"></i></div>Comment
                                    </a>
                                </div>
                                <div class="item">
                                    <a href="javascript:void(0)">
                                        <div class="icon"><i class="fa fa-share"></i></div>Share
                                    </a>
                                </div>
                            </div>
                            <hr class="mt-1 mb-1" v-if="showComment == item.trade_id && item.comment && item.comment.totalRecords">
                            <div class="fc-su-box" v-if="showComment == item.trade_id && item.comment && item.comment.totalRecords">
                                <div class="fs-bo-set" v-for="com in item.comment.records">
                                    <div class="d-flex align-items-center w-100">
                                        <div class="icon"><img src="/assets/img/user-ico-white.svg"></div>
                                        <div class="com_user-row w-100"> 
                                            <p class="mb-0">
                                                <span class="fw-500 mr-1">{{((com.namef || '')+' '+(com.namel || '')).trim() || 'Unknown Uses'}}</span>
                                                <span class="co-blue-horizon">{{fromNow(com.created)}}</span><br>
                                                <p>{{com.comment}}</p>
                                            </p>
                                            <!-- <div style="width: 10px;">
                                                <i class="far fa-heart position-absolute"></i>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-control com_user-row" v-show="isComment == item.trade_id">
                                <input type="text" placeholder="Comment here.." class="f-14 border-0" :ref="'comment'+item.trade_id">
                                <i class="fas fa-paper-plane" style="font-size: 26px;width: 26px;" @click="addComment(item.trade_id)"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { useMainStore } from '@/store/index'
import { Field, Form, ErrorMessage } from 'vee-validate';
import moment from 'moment'
import { GLOBAL_BASE_URL } from '@/api/endpoints.js'
export default{
    setup(){
        return { store: useMainStore() }
    },
    data(){
        return{
            loading: false,
            page:1,
            limit: 10,
            postDisc:'',
            fileSel:{},
            showComment:'',
            showMore: '',
            isComment: '',
            showImg: -1,
            global_url: GLOBAL_BASE_URL,
        }
    },
    components: { Field, Form, ErrorMessage },
    computed:{
        profile(){ return (this.store.user.profile || {}) }
    },
    methods:{
        getList(){
            this.store.getFeeds({
                "page":this.page,
                "perPage":this.limit,
                "user_id" : this.store.user.profile.user_id,
                "sort" : {
                    "field" : "trade_ideas.view_count",
                    "type" : "DESC",
                }
            }, true, 'user').then(res=>{ }).catch(e=>{ })
        },
        fromNow(d){
            return moment(d).fromNow();
        },
        likeUnlike(item,type){
            if(this.loading){ return }
            this.loading = true;
            this.store.likeDislikeFeed({
                "type" : type/*"COMMENT"*/,
                "is_like" : (item.is_like?0:1),
                "user_id" : this.store.user.profile.user_id,
                "target_id": (item.trade_id || item.trade_idea_id)
            }, false, 'user').then(res=>{ this.loading = false; }).catch(e=>{ this.loading = false; })
        },
        addComment(trade_id){
            let text = this.$refs['comment'+trade_id][0].value
            this.store.addFeedComment({
                "trade_idea_id" : trade_id,
                "parent_id" : 0,
                "user_id" : this.store.user.profile.user_id,
                "comment" : text
            }, true, 'user').then(res=>{
                this.isComment = ''
                this.$refs['comment'+trade_id][0].value = ''
            }).catch(e=>{})
        },
        getComment(item){
            if(item.comment_count){
                if(this.showComment != item.trade_id){
                    this.showComment = item.trade_id;
                    this.store.getFeedComments({
                        "trade_id":item.trade_id,
                        "page":1,
                        "perPage":10
                    }, true, 'user').then(res=>{  }).catch(e=>{});
                }else{
                    this.showComment = ''
                }
            }
        }, 
        getFile(e){
            if(e.target.files[0]){
                this.fileSel.file = e.target.files[0];
                this.fileSel.name = e.target.files[0].name;
            }else{
                this.clearFile()
            }
        },
        clearFile(){
            this.fileSel = {}
            this.$refs.document.value = ""
        },
        newPost(){
            let newForm = new FormData()
            newForm.append('user_id', this.store.user.profile.user_id)
            newForm.append('title', 'New Post')
            newForm.append('description', this.postDisc)
            newForm.append('category', 'post')
            newForm.append('type', 'Analysis')
            newForm.append('classification', 'Trend Analysis')
            newForm.append('position', 'Sell')
            newForm.append('share_facebook', 1)
            newForm.append('share_twitter', 1)
            newForm.append('share_instagram', 1)
            newForm.append('is_public', 1)
            newForm.append('status', 1)
            newForm.append('tags', 'RT,GBH,URD')
            newForm.append('time_interval', '1 Month')
            newForm.append('symbol', 'USD')
            if(this.fileSel.name){
                newForm.append('document[]', this.fileSel.file, this.fileSel.name)
            }
            this.store.addFeed(newForm, true, 'user').then(res=>{
                this.postDisc = ''
                this.clearFile()
            }).catch(e=>{console.log(e);})
        }
    },
    created(){
        this.getList()
    },
};
</script>
<style>
    p{
        word-break: break-word; 
    }
</style>