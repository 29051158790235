<template>
    <section id="main-wrapper">
        <div class="container-fluid section-small com_mobile-main-height d-flex align-items-center">
            <div class="module container width-5">
                <div>
                    <div class="d-flex justify-content-center mt20 mb20 text-center">
                        <div class="cr-wa-icon">
                            <svg width="42" version="1.0" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                                <g transform="translate(0 512) scale(.1 -.1)">
                                    <path d="m379 5097c-175-60-306-192-359-366-18-56-20-93-20-340 0-304 1-309 66-352 27-18 53-19 382-19h352v-1155-1155h-352c-329 0-355-1-382-19-46-31-66-69-66-131s20-100 66-131c27-18 53-19 382-19h352v-155-155h-352c-483 0-448 29-448-371 0-247 2-284 20-340 53-174 184-306 359-366l66-22 1701-1c1664 0 1703 0 1765 20 174 53 306 184 366 359 21 62 22 83 23 349v282h363c338 0 364 1 391 19 46 31 66 69 66 131s-20 100-66 131c-27 18-53 19-391 19h-363v1683l-1 1682-22 66c-61 180-196 309-376 363-49 14-220 16-1755 16l-1701-1-66-22zm3471-306c61-32 87-57 118-116l27-50 3-1657 2-1658h-377-377l70 73c96 97 104 111 104 177 0 62-20 100-66 131-37 24-129 26-164 3-37-24-407-392-441-438-24-32-29-49-29-96s5-64 29-96c34-46 404-414 441-438 35-23 127-21 164 3 46 31 66 69 66 131 0 66-8 80-104 177l-70 73h378 378l-4-257-3-258-30-54c-30-54-61-83-125-117-34-18-90-19-1414-22-1316-2-1378-2-1371 15 45 107 45 112 45 614v479h387 387l-70-73c-96-97-104-111-104-177 0-96 54-150 151-150 46 0 63 5 95 29 46 34 414 404 438 441 10 15 16 45 16 81 0 46-5 63-29 95-34 46-404 414-441 438-35 23-127 21-164-3-46-31-66-69-66-131 0-66 8-80 104-178l70-72h-387-387v1479c0 1419-1 1482-19 1538-10 32-22 66-26 76-7 17 55 17 1371 15l1379-3 45-24zm-3197-2c58-29 85-56 115-114 23-45 26-64 30-202l5-153h-253-253l5 153c4 138 7 157 30 202 30 57 56 84 113 113 56 29 151 29 208 1zm147-4096c0-157-8-206-44-266-45-75-118-117-203-117-102 0-174 44-221 135-23 45-26 64-30 203l-5 152h252 251v-107z" />
                                    <path d="m1466 4491c-46-31-66-69-66-131s20-100 66-131c28-18 62-19 834-19s806 1 834 19c46 31 66 69 66 131s-20 100-66 131c-28 18-62 19-834 19s-806-1-834-19z" />
                                    <path d="m1466 3891c-46-31-66-69-66-131s20-100 66-131c28-18 67-19 1084-19s1056 1 1084 19c46 31 66 69 66 131s-20 100-66 131c-28 18-67 19-1084 19s-1056-1-1084-19z" />
                                    <path d="m1466 3291c-46-31-66-69-66-131s20-100 66-131c28-18 67-19 1084-19s1056 1 1084 19c46 31 66 69 66 131s-20 100-66 131c-28 18-67 19-1084 19s-1056-1-1084-19z" />
                                    <path d="m1966 2691c-46-31-66-69-66-131s20-100 66-131c28-18 62-19 834-19s806 1 834 19c46 31 66 69 66 131s-20 100-66 131c-28 18-62 19-834 19s-806-1-834-19z" />
                                </g>
                            </svg>
                        </div>
                    </div>
                    <div class="section-title text-center co-blue-horizon mt40">
                        <h5 class="mb-2">You have no transactions yet</h5>
                        <p>Your transactions will be shown here as soon as you make one.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default{};
</script>