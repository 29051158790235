<template>
    <section class="d-flex align-items-center justify-content-center" id="main-wrapper" style="min-height: 100vh;padding: 40px;">
        <app-loader v-if="store.loading"></app-loader>
        <div class="container-fluid section-small">
            <div class="module container width-5 login-card card box-shadow rounded bg-white" >
                <div class="logo d-flex mx-4 mt-5">
                    <router-link to="/"><img src="/assets/img/logo.png" width="55" alt=""></router-link>
                    <h5 class="ml-4">Register</h5>  
                </div>
                <Form class="m-4 mb-5" @submit="submit()">
                    <div class="mb-3">
                        <label class="form-label font-weight-bold">First Name:</label>
                        <Field type="text" name="First Name" v-model="fname" :rules="'required|max:20'" class="form-control"/>
                        <ErrorMessage name="First Name" />
                    </div>
                   
                    <div class="mb-3">
                        <label class="form-label font-weight-bold">Last Name:</label>
                        <Field type="text" name="Last Name" v-model="lname" :rules="'required|max:20'" class="form-control"/>
                        <ErrorMessage name="Last Name" />
                    </div>
                   
                    <div class="mb-3">
                        <label class="form-label font-weight-bold">Email:</label>
                        <Field type="text" name="Email" v-model="email" :rules="'required|email'" class="form-control"/>
                        <ErrorMessage name="Email" />
                    </div>
                   
                    <div class="mb-3">
                        <label class="form-label font-weight-bold">Password:</label>
                        <Field type="text" name="Password" v-model="password" :rules="{ required: true, min:6, max:12, regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])\S{6,12}$/}" class="form-control"/>
                        <ErrorMessage as="div" name="Password" v-slot="{ message }">
                          <span v-if="message.includes('not valid')">Your password must contain minimum 6  and maximum 12 characters, and include at least 1 number, 1 uppercase character and 1 special character (!@#$%^)</span>
                          <span v-else>{{ message }}</span>
                        </ErrorMessage>
                    </div>
                   
                    <div class="mb-3">
                        <label class="form-label font-weight-bold">Country:</label>
                        <Field as="select" name="Country" v-model="country" rules="required" class="form-control">
                            <option value="">Select Country</option>
                            <option v-for="item in static_vars.countriesList" :value="item.name">{{item.name}}</option>
                        </Field>
                        <ErrorMessage name="Country" />
                    </div>
                   
                    <div class="mb-3">
                        <label class="form-label font-weight-bold">Phone Number:</label>
                        <Field type="Phone" v-model="phone_no" name="phone" :rules="'required|numeric|max:10'" class="form-control"/>
                        <ErrorMessage name="phone" />
                    </div>
                   
                    <div class="mb-3">
                        <input class="btn button-primary  px-5" type="submit" value="Login">
                    </div>
                    Allready have account! <router-link to="/">Login</router-link>
                </Form>
            </div>
        </div>
    </section>
</template>
<script>
const crypto = require('crypto');
import { Field, Form, ErrorMessage } from 'vee-validate';
import { useMainStore } from '@/store/index'
export default{
    setup(){
        return { store: useMainStore() }
    },
    data(){
        return{
            pageLoader : false,
            fname: '',
            lname: '',
            email: '',
            password: '',
            country: '',
            phone_no: '',
            trading_platform:'',
            account_type:'',
            
            submitted: false,
            term_agree : false,
            inputtype1 : 'password',

            response_msg : '',
            msg_type : '',
        }
    },
    components: { Field, Form, ErrorMessage },
    methods:{
        submit(){
            let data = {
                "namef": this.fname,
                "namel": this.lname,
                "email": this.email,
                "password": this.password,
                "country": this.country,
                "mobile_no": this.phone_no
            }
            this.store.register(data, true).then(res=>{ }).catch(e=>{ })
        },
        submit2Fa(){
            const mykey = crypto.createCipher('aes-128-cbc', 'F*V(I)e^E#1108@3');
            let hashedstr = mykey.update(this.password, 'utf8', 'hex')
            hashedstr += mykey.final('hex');
            console.log('0000',hashedstr)
        }
    }
};
</script>