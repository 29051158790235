<template>
    <section id="main-wrapper">
        <div class="container-fluid section-small">
            <div class="module container width-5">
                <div class="row-list-mb3">
                    <div class="ma-fu-form com_call-country">
                        <div class="form-group">
                            <label class="gh1">Country</label>
                            <div class="com_select-box se-bo-lang gh2">
                                <select class="com_call-country-select" name="" id="">
                                    <option value="+623 2332452 3242" data-icon="/assets/img/icon_uk.png">Choose Country</option>
                                    <option value="+623 2332452 3243" data-icon="/assets/img/icon_real_stocks_france.png">sample 1</option>
                                    <option value="+623 2332452 3244" data-icon="/assets/img/icon_real_stocks_germany.png">sample 2</option>
                                    <option value="+623 2332452 3245" data-icon="/assets/img/icon_real_stocks_hongkong.png">sample 3</option>
                                </select>
                            </div>
                            <div class="section-par mt-3">
                                <h6 class="co-dark-blue">
                                    <a class="link link-inherit com_call-country-label" href="tel:+623 2332452 3242">+623 2332452 3242</a>
                                </h6>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="section-title">
                            <h6 class="co-dark-blue">Contact info</h6>
                        </div>
                        <ul class="hc-ca-list">
                            <li>
                                <div class="section-par">
                                    <p class="mb-1 co-blue-horizon">Live Chat Support</p>
                                    <h6><a class="link" href="#">Start Conversation</a></h6>
                                </div>
                            </li>
                            <li>
                                <div class="section-par">
                                    <p class="mb-1 co-blue-horizon">Email</p>
                                    <h6><a class="link" href="mailto:service@naga-global.com">service@naga-global.com</a></h6>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default{};
</script>