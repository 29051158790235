<template>
    <header>
        <div class="header-content header-mobile">
            <div class="header-top container-fluid no-shadow">
                <div class="module container width-5">
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="section-title mb-0">
                            <h5>Academy</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <section id="main-wrapper" style="padding-top: 49px; padding-bottom: 123px;">
        <div class="container-fluid section-small">
            <div class="module container width-5">
                <div class="ac-co-box">
                    <ul>
                        <li><router-link to="/academy/articles">Articles</router-link></li>
                        <li><router-link to="/academy/videos">Videos</router-link></li>
                        <li><router-link to="/academy/videos">Web Trader Tutorials</router-link></li>
                        <li><router-link to="/academy/webinars">Webinars</router-link></li>
                        <li><router-link to="/academy/webinars">Seminars</router-link></li>
                        <li><router-link to="/academy/ebooks">Tutorials</router-link></li>
                        <li><router-link to="/academy/ebooks">eBooks</router-link></li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default{};
</script>