<template>
    <tr v-if="type && type == 'table'">
        <td class="noRecordFound "  :colspan="colspan"> 
           <img src="assets/images/nodataFound.svg" alt="Icon" title="No data Found">
            <p class="data-details">No data found</p>
        </td>
    </tr>
    <div class="notFoundData text-center p-4" v-else>
        <img src="assets/images/nodataFound.svg" alt="Icon" title="No data Found">
        <p class="data-details">No data found</p>
    </div>
</template>
<script>
    export default {
        data(){
            return {
            }
        },
        props : ['type','colspan']
    }
</script>


