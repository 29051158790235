<template>
    <header>
        <div class="header-content header-mobile">
            <div class="header-top container-fluid ">
                <div class="module container width-5">
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="section-title mb-0">
                            <button class="button button-text">
                                <h5 class="d-flex">
                                    <div class="icon mr-3" @click="$router.go(-1)">
                                        <svg width="25" version="1.0" viewBox="0 0 470 342" xmlns="http://www.w3.org/2000/svg">
                                            <g transform="translate(0 342) scale(.1 -.1)">
                                                <path d="m1630 3407c-27-8-212-187-813-786-523-522-784-789-797-817-26-53-26-135 0-188 28-58 1551-1579 1602-1601 52-21 140-19 186 4 106 55 152 182 103 286-13 27-205 225-590 610l-571 570 3795 10 45 25c24 14 58 45 75 68 27 40 30 51 30 122s-3 82-30 122c-17 23-51 54-75 68l-45 25-3795 10 566 565c311 311 573 581 583 600 46 89 33 178-38 250-63 66-140 85-231 57z"></path>
                                            </g>
                                        </svg>
                                    </div> Withdrawal List
                                </h5>
                            </button>
                        </div>
                        <ul class="he-to-ctrl">
                            <li>
                                <router-link to="/deposit" class="ht-ct-icon">
                                    <svg width="18" version="1.0" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                                        <g transform="translate(0 512) scale(.1 -.1)">
                                            <path d="m2442 5095c-29-15-64-41-77-59-58-76-55-12-55-1166v-1060l-1073-2-1074-3-49-30c-30-19-60-49-79-79-27-45-30-57-30-136s3-91 30-136c19-30 49-60 79-79l49-30 1074-3 1073-2 2-1073 3-1074 30-49c19-30 49-60 79-79 45-27 57-30 136-30s91 3 136 30c30 19 60 49 79 79l30 49 3 1074 2 1073 1073 2 1074 3 49 30c30 19 60 49 79 79 27 45 30 57 30 136s-3 91-30 136c-19 30-49 60-79 79l-49 30-1074 3-1073 2-2 1073-3 1074-30 49c-46 74-109 108-204 112-66 3-83 0-129-23z" />
                                        </g>
                                    </svg>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <section id="main-wrapper" style="padding-top: 50px; padding-bottom: 67px;">
        <app-loader v-if="store.loading"></app-loader>
        <div class="container-fluid section-small">
            <div class="module container width-5">
                <ul class="row-list-mb1" v-if="withdrawalList.length > 0">
                    <li v-for="item, i in withdrawalList">
                        <div class="tr-bo-set pt-3 pb-3">
                            <div>
                                <!-- <div class="section-title">
                                    <h6 class="co-blue-horizon">Currently Logged In</h6>
                                </div> -->
                                <div class="row">
                                    <div class="col">
                                        <div class="tb-se-title align-items-start">
                                            <div class="icon bg-dark-blue" style="background-image: url('../assets/img/icon_copyrack.png');"></div>
                                            <div class="section-par">
                                                <h6 class="mb-1">{{item.currency}}</h6>
                                                <h5 class="mb-1 fw-500 co-pure-apple">{{item.amount}}</h5>
                                                <div class="d-flex">
                                                    <div class="tb-se-tags align-items-center">
                                                        <h6 class="mb-0 mr-2 co-blue-horizon">#{{item.withdrawal_from_trading_id}}</h6>
                                                        <!-- <div class="tb-se-tag tag-v2">{{item.platform}}</div> -->
                                                        <div class="tb-se-tag tag-v2 tag-blue-horizon">{{item.withdrawal_success_status}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-auto">
                                        <div class="com_dropdown">
                                            <button class="button button-text text-blue-horizon cr-wa-more more-btn" @click="(selIndex == i)?(selIndex = -1):(selIndex = i)">
                                                <i class="far" :class="'fa-chevron-'+(selIndex == i?'up':'down')"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-1" v-show="selIndex == i">
                                	<div class="d-flex justify-content-between">
                                		<span>Trading ID:</span>
                                		<span>{{item.withdrawal_from_trading_id}}</span>
                                	</div>
                                	<div class="d-flex justify-content-between">
                                		<span>Currency:</span>
                                		<span>{{item.currency}}</span>
                                	</div>
                                	<div class="d-flex justify-content-between">
                                		<span>Amount:</span>
                                		<span>{{item.amount}}</span>
                                	</div>
                                	<div class="d-flex justify-content-between">
                                		<span>Payment Mode:</span>
                                		<span>{{item.withdrawal_method}}</span>
                                	</div>
                                	<div class="d-flex justify-content-between">
                                		<span>Payment Status:</span>
                                		<span>{{item.withdrawal_success_status}}</span>
                                	</div>
                                	<div class="d-flex justify-content-between">
                                		<span>Date:</span>
                                		<span>{{formatDate(item.created)}}</span>
                                	</div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                <div class="fc-su-box box-medium ml-0 mr-0" v-else>
                    <div class="section-title par-blue-horizon text-center mb-4">
                        <h6 class="mb-1">No Transaction Found</h6>
                    </div>
                    <div>
                        <router-link to="/withdrawal" class="button button-quaternary button-wide button-purple">
                            <i class="fal fa-plus mr-2"></i> Withdrawal
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { useMainStore } from '@/store/index'
import { Field, Form, ErrorMessage } from 'vee-validate';
import { useToast } from 'vue-toastification'
import moment from 'moment'
export default{
    setup(){
        return { store: useMainStore(), toast: useToast() }
    },
    components: { Field, Form, ErrorMessage },
    data(){
        return{
            demo_live:'Live',

            selIndex : -1,
            search : '',
            page : 1,
            pageLimit : 10,
        }
    },
    computed:{
    	withdrawalList(){
    		return this.store.withdrawalList.records || []
    	}
    },
    methods:{
        formatDate(d){
            return moment(d).format('DD MMMM YYYY h:mm:ss')
        },
        getList(){
            this.store.getWithdrawalList({
                filter: {},
                sort: [],
                search: this.search,
                page: this.page,
                perPage: parseInt(this.pageLimit),
            }, true).then(res=>{ console.log(res); }).catch(e=>{ })
        }
    },
    mounted(){
        /*let _that = this;
        $(document).on("click", function (event) {
            if (!$(event.target).closest(".more-btn").length) {
                _that.selIndex = -1;
            }
        });*/
    },
    created(){
        this.getList()
    },
}
</script>