<template>
    <header>
        <app-loader v-if="store.loading"></app-loader>
        <div class="header-content header-mobile">
            <div class="header-top container-fluid ">
                <div class="module container width-5">
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="logo"><router-link to="/"><img src="/assets/img/logo.png" width="55" alt=""></router-link></div>
                        <ul class="he-to-ctrl">
                            <li><a class="ht-ct-icon" href="javascript:void(0)"><i class="far fa-search ml-0"></i></a></li>
                            <li>
                                <router-link class="ht-ct-icon" to="/notifications">
                                    <div class="icon">
                                        <i class="fas fa-bell f-22"></i>
                                    </div>
                                    <div class="icon-tag">3</div>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="module container width-5 mt-4">
                    <div class="fc-su-box box-simple">
                        <div class="com_user-row">
                            <div class="icon" @click="$refs.document.click()">
                                <i class="fas fa-paperclip f-22"></i>
                                <input class="d-none" type="file" accept="image/*" ref="document"  @change="getFile($event)">
                            </div>
                            <div>
                                <div class="form-control com_user-row">
                                    <input type="text" placeholder="Share something" v-model="postDisc" class="f-14 border-0" >
                                    <i class="fas fa-paper-plane f-22" style="width:20px;" v-if="postDisc" @click="newPost()"></i>
                                </div>
                            </div>
                        </div>
                        <div class="alert alert-warning" v-if="fileSel.name">
                          <strong>File:</strong> {{fileSel.name}}
                          <button type="button" class="close" @click="clearFile()">&times;</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <section id="main-wrapper" style="padding-top: 139px; padding-bottom: 67px;">
        <div class="container-fluid section-small">
            <div class="module container width-5">
                <div class="fc-su-box box-small">
                    <div class="section-title par-blue-horizon">
                        <h5 class="mb-1 fw-500">Tade with real Money</h5>
                        <p>Enter The globe markets and trade with real money</p>
                    </div>
                    <router-link to="/deposit" class="button button-quaternary button-wide button-purple">Add real money</router-link>
                </div>
            </div>
            <!-- <div class="module container width-5">
                <div class="fe-co-ribbon bg-black bg-gradient-blue ml-pull-15 mr-pull-15 ml-pull-md-0 mr-pull-md-0">
                    <div class="d-flex align-items-center justify-content-between">
                        <div>
                            <div class="fc-ri-nums">
                                <div class="com_draggable">
                                    <div class="com_draggable-main">
                                        <ul class="hb-nu-list">
                                            <li>Amc <span class="co-pure-apple">14.29%</span></li>
                                            <li>Jinkosolar <span class="co-pure-apple">14.29%</span></li>
                                            <li>Haidilao international holding <span class="co-pure-apple">14.29%</span></li>
                                            <li>Amc <span class="co-pure-apple">14.29%</span></li>
                                            <li>Jinkosolar <span class="co-pure-apple">14.29%</span></li>
                                            <li>Haidilao international holding <span class="co-pure-apple">14.29%</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-short no-wrap"><button class="button button-quinary button-line button-white">See more</button></div>
                    </div>
                </div>
            </div> -->
            <div class="module container width-5">
                <div class="row-list-mb3">
                    <!-- <div>
                        <div class="section-title">
                            <h5>Feature <span>Posts</span></h5>
                        </div>
                        <div class="com_post-slider">
                            <div class="nav-1" data-sneaky-slider="type1">
                                <div class="item" v-for="item in 8">
                                    <div class="com_post-box">
                                        <div class="po-bo-banner" data-aspect-ratio="212,155">
                                            <a class="com_banner bp-ab" href="javascript:void(0)"></a>
                                            <a class="pb-ba-tag top-left" href="javascript:void(0)">
                                                <div class="icon"> <img src="/assets/img/logo.png" alt=""></div>
                                                <p>Copycrack Articles</p>
                                            </a>
                                        </div>
                                        <div class="po-bo-par">
                                            <div class="section-par">
                                                <p><a class="link link-inherit" href="javascript:void(0)">Lorem ipsum dolor sit amet, consectetur adipisicing elitse</a></p>
                                            </div>
                                            <div class="mt20">
                                                <ul class="pb-ba-opt">
                                                    <li>
                                                        <div class="icon"><i class="fal fa-heart"></i></div> 2
                                                    </li>
                                                    <li>
                                                        <div class="icon"><i class="fal fa-comment-alt"></i></div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <div class="fc-su-box" v-for="item,i in (store.feedsList.records || [])">
                        <div class="fs-bo-set">
                            <div class="d-flex align-items-center">
                                <div class="icon">
                                    <img src="/assets/img/user-ico-white.svg">
                                </div>
                                <div>
                                    <p class="mb-0">
                                        <span class="fw-500">{{((item.namef || '')+' '+(item.namel || '')).trim() || 'Unknown Uses'}}</span> <br>
                                        <span class="co-blue-horizon">Suggested <span>•</span> {{fromNow(item.created)}}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="section-par" :class="[{'mb-0':!item.document}]">
                            <p>{{item.title}}</p>
                        </div> -->
                        <div class="position-fixed all-0 container width-5" v-if="showImg == i">
                            <div @click="showImg = -1"><vue-feather type="x"></vue-feather></div>
                            <img :src="global_url+'/get/docs?type=post&id='+item.user_id+'&doc='+item.document">
                        </div>
                        <div class="fc-su-banner" v-if="item.document" :style="'background-image: url('+global_url+'/get/docs?type=post&id='+item.user_id+'&doc='+item.document+');'" @click="showImg = i"></div>
                        <div class="section-par">
                            <p v-if="showMore == item.trade_id">{{item.description}}</p>
                            <p v-else>{{item.description.slice(0,200)}}</p>
                        </div>
                        <button class="button button-text text-jade-dust" v-if="item.description.length > 200" @click="(showMore == item.trade_id)?(showMore = -1):(showMore = item.trade_id)">{{(showMore == item.trade_id)?'Less':'More'}}...</button>
                        <!-- <button class="button button-text text-blue-horizon">See Translation</button> -->
                        <div class="mb-pull-10">
                            <div class="d-flex justify-content-between">
                                <a class="pb-op-set" href="javascript:void(0)">
                                    <div class="icon icon-solid"><i class="fa fa-heart"></i></div> {{item.likes_count}}
                                </a>
                                <div><button class="button button-text text-blue-horizon" @click="getComment(item)">{{item.comment_count}} Comments</button></div>
                            </div>
                            <hr class="mt-1 mb-1">
                            <div class="com_user-opt">
                                <div class="item">
                                    <a href="javascript:void(0)" @click="likeUnlike(item,'post')">
                                        <div class="icon"><i class="fa-heart" :class="(item.is_like)?'fa':'far'" :style="(item.is_like)?'color: var(--jade-dust);':''"></i></div>{{(item.is_like)?'Unlike':'Like'}}
                                    </a>
                                </div>
                                <div class="item">
                                    <a href="javascript:void(0)" @click="(isComment == item.trade_id)?(isComment = ''):(isComment = item.trade_id)">
                                        <div class="icon"><i class="far fa-comment-alt"></i></div>Comment
                                    </a>
                                </div>
                                <div class="item">
                                    <a href="javascript:void(0)">
                                        <div class="icon"><i class="fa fa-share"></i></div>Share
                                    </a>
                                </div>
                            </div>
                            <hr class="mt-1 mb-1" v-if="showComment == item.trade_id && item.comment && item.comment.totalRecords">
                            <div class="fc-su-box" v-if="showComment == item.trade_id && item.comment && item.comment.totalRecords">
                                <div class="fs-bo-set" v-for="com in item.comment.records">
                                    <div class="d-flex align-items-center w-100">
                                        <div class="icon"><img src="/assets/img/user-ico-white.svg"></div>
                                        <div class="com_user-row w-100"> 
                                            <p class="mb-0">
                                                <span class="fw-500 mr-1">{{((com.namef || '')+' '+(com.namel || '')).trim() || 'Unknown Uses'}}</span>
                                                <span class="co-blue-horizon">{{fromNow(com.created)}}</span><br>
                                                <p>{{com.comment}}</p>
                                            </p>
                                            <!-- <div style="width: 10px;">
                                                <i class="far fa-heart position-absolute"></i>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-control com_user-row" v-show="isComment == item.trade_id">
                                <input type="text" placeholder="Comment here.." class="f-14 border-0" :ref="'comment'+item.trade_id">
                                <i class="fas fa-paper-plane" style="font-size: 26px;width: 26px;" @click="addComment(item.trade_id)"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { useMainStore } from '@/store/index'
import { Field, Form, ErrorMessage } from 'vee-validate';
import moment from 'moment'
import { GLOBAL_BASE_URL } from '@/api/endpoints.js'
export default{
    setup(){
        return { store: useMainStore() }
    },
    data(){
        return{
            loading: false,
            page:1,
            limit: 10,
            postDisc:'',
            fileSel:{},
            showComment:'',
            showMore: '',
            isComment: '',
            showImg: -1,
            global_url: GLOBAL_BASE_URL,
        }
    },
    components: { Field, Form, ErrorMessage },
    methods:{
        getList(){
            this.store.getFeeds({
                "page":this.page,
                "perPage":this.limit,
                "sort" : {
                    "field" : "trade_ideas.view_count",
                    "type" : "DESC"
                }
            },true).then(res=>{ }).catch(e=>{ })
        },
        fromNow(d){
            return moment(d).fromNow();
        },
        likeUnlike(item,type){
            if(this.loading){ return }
            this.loading = true;
            this.store.likeDislikeFeed({
                "type" : type/*"COMMENT"*/,
                "is_like" : (item.is_like?0:1),
                "user_id" : this.store.user.profile.user_id,
                "target_id": (item.trade_id || item.trade_idea_id)
            }).then(res=>{ this.loading = false; }).catch(e=>{ this.loading = false; })
        },
        addComment(trade_id){
            let text = this.$refs['comment'+trade_id][0].value
            this.store.addFeedComment({
                "trade_idea_id" : trade_id,
                "parent_id" : 0,
                "user_id" : this.store.user.profile.user_id,
                "comment" : text
            },true).then(res=>{
                this.isComment = ''
                this.$refs['comment'+trade_id][0].value = ''
            }).catch(e=>{})
        },
        getComment(item){
            if(item.comment_count){
                if(this.showComment != item.trade_id){
                    this.showComment = item.trade_id;
                    this.store.getFeedComments({
                        "trade_id":item.trade_id,
                        "page":1,
                        "perPage":10
                    },true).then(res=>{}).catch(e=>{});
                }else{
                    this.showComment = ''
                }
            }
        }, 
        getFile(e){
            if(e.target.files[0]){
                this.fileSel.file = e.target.files[0];
                this.fileSel.name = e.target.files[0].name;
            }else{
                this.clearFile()
            }
        },
        clearFile(){
            this.fileSel = {}
            this.$refs.document.value = ""
        },
        newPost(){
            let newForm = new FormData()
            newForm.append('user_id', this.store.user.profile.user_id)
            newForm.append('title', 'New Post')
            newForm.append('description', this.postDisc)
            newForm.append('category', 'post')
            newForm.append('type', 'Analysis')
            newForm.append('classification', 'Trend Analysis')
            newForm.append('position', 'Sell')
            newForm.append('share_facebook', 1)
            newForm.append('share_twitter', 1)
            newForm.append('share_instagram', 1)
            newForm.append('is_public', 1)
            newForm.append('status', 1)
            newForm.append('tags', 'RT,GBH,URD')
            newForm.append('time_interval', '1 Month')
            newForm.append('symbol', 'USD')
            if(this.fileSel.name){
                newForm.append('document[]', this.fileSel.file, this.fileSel.name)
            }
            this.store.addFeed(newForm,true).then(res=>{
                this.postDisc = ''
                this.clearFile()
            }).catch(e=>{})
        }
    },
    created(){
        this.getList()
    },
}
</script>
<style>
    p{
        word-break: break-word; 
    }
</style>