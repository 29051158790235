<template>
    <section id="main-wrapper">
        <div class="container-fluid section-small">
            <div class="module container width-5">
                <div class="row row-small row-list-mb15 group-height">
                    <div class="col-12">
                        <a class="hc-fa-set gh1" href="javascript:void(0)">
                            <h6><strong>Getting Started</strong></h6>
                        </a>
                    </div>
                    <div class="col-12">
                        <a class="hc-fa-set gh1" href="javascript:void(0)">
                            <h6><strong>Community &amp; Account</strong></h6>
                        </a>
                    </div>
                    <div class="col-12">
                        <a class="hc-fa-set gh1" href="javascript:void(0)">
                            <h6><strong>Deposits</strong></h6>
                        </a>
                    </div>
                    <div class="col-12">
                        <a class="hc-fa-set gh1" href="javascript:void(0)">
                            <h6><strong>Withdrawals</strong></h6>
                        </a>
                    </div>
                    <div class="col-12">
                        <a class="hc-fa-set gh1" href="javascript:void(0)">
                            <h6><strong>Trading</strong></h6>
                        </a>
                    </div>
                    <div class="col-12">
                        <a class="hc-fa-set gh1" href="javascript:void(0)">
                            <h6><strong>Copy trading</strong></h6>
                        </a>
                    </div>
                    <div class="col-12">
                        <a class="hc-fa-set gh1" href="javascript:void(0)">
                            <h6><strong>Account Verification</strong></h6>
                        </a>
                    </div>
                    <div class="col-12">
                        <a class="hc-fa-set gh1" href="javascript:void(0)">
                            <h6><strong>Settings &amp; Security</strong></h6>
                        </a>
                    </div>
                    <div class="col-12">
                        <a class="hc-fa-set gh1" href="javascript:void(0)">
                            <h6><strong>Our Partners</strong></h6>
                        </a>
                    </div>
                    <div class="col-12">
                        <a class="hc-fa-set gh1" href="javascript:void(0)">
                            <h6><strong>CC Pay</strong></h6>
                        </a>
                    </div>
                    <div class="col-12">
                        <a class="hc-fa-set gh1" href="javascript:void(0)">
                            <h6><strong>Regulations &amp; Legal</strong></h6>
                        </a>
                    </div>
                    <div class="col-12">
                        <a class="hc-fa-set gh1" href="javascript:void(0)">
                            <h6><strong>UK-Based Clients Only</strong></h6>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default{};
</script>