import { defineStore } from 'pinia'
import * as API from '@/api/rx-factory'
import * as ENDPOINTS from '@/api/endpoints'
import router from '@/router'
import { useToast } from 'vue-toastification'
import moment from 'moment'

const toast = useToast()

export const useMainStore = defineStore('main', {
  persist: {
    key: 'storekey',
    storage: window.localStorage,
    paths: ['user'],
  },
  state: () => ({
    loading: false,
    user: null,
    tradingAccounts:{},
    depositList:{},
    withdrawalList:{},
    feedsList: {},
    userFeedsList: {},
    topTrdaersList:[],
    docList:[],
  }),
  getters: { },
  actions: {
    loc(v){
        let num = new Number(v)
        return num.toLocaleString();
    },
    /*------------ Login Register -----------*/
      login(params = {}, loader = false) {
        return new Promise((resolve, reject) => {
          if (loader) { this.loading = true; }
          API.post(ENDPOINTS.EP_LOGIN, params).then(response => {
            this.loading = false;
            this.user = response.user;
            router.replace('/index')
            // window.location.replace('/index')
            resolve(response);
          }).catch(error => {
            this.loading = false;
            reject(error);
          })
        })
      },
      register(params = {}, loader = false) {
        return new Promise((resolve, reject) => {
          if (loader) { this.loading = true; }
          API.post(ENDPOINTS.EP_REGISTER, params).then(response => {
            this.loading = false;
            this.user = response;
            router.replace('/index')
            resolve(response);
          }).catch(error => {
            this.loading = false;
            reject(error);
          })
        })
      },
      verifyOtp(params = {}, loader = false) {
        return new Promise((resolve, reject) => {
          if (loader) { this.loading = true; }
          API.post(ENDPOINTS.EP_OTPVERIFY, params).then(response => {
            this.loading = false;
            this.user.account_verified = 1;
            resolve(response);
          }).catch(error => {
            this.loading = false;
            reject(error);
          })
        })
      },
      resendOtp(params = {}, loader = false) {
        return new Promise((resolve, reject) => {
          if (loader) { this.loading = true; }
          API.post(ENDPOINTS.EP_RESEND_OTP, params).then(response => {
            this.loading = false;
            let type:any = { 'type': 'success' }
            toast('OTP resend successfully', type )
            resolve(response);
          }).catch(error => {
            this.loading = false;
            reject(error);
          })
        })
      },
      getFreshUser(params = {}, loader = false) {
        return new Promise((resolve, reject) => {
          if (loader) { this.loading = true; }
          API.post(ENDPOINTS.EP_GET_FRESH_USER, params).then(response => {
            this.loading = false;
            this.user = response.user;
            resolve(response);
          }).catch(error => {
            this.loading = false;
            reject(error);
          })
        })
      },
    /*------------ End -----------*/

    /*----------- Trading Account -----------*/
      getTradingAccountList(params = {}, loader = false) {
        return new Promise((resolve, reject) => {
          if (loader) { this.loading = true; }
          API.post(ENDPOINTS.EP_GET_TRADING_ACCOUNT, params).then(response => {
            this.loading = false;
            this.tradingAccounts = response
            resolve(response);
          }).catch(error => {
            this.loading = false;
            this.tradingAccounts = {}
            reject(error);
          })
        })
      },
      createTradingAccount(params = {}, loader = false) {
        return new Promise((resolve, reject) => {
          if (loader) { this.loading = true; }
          API.post(ENDPOINTS.EP_CREATE_TRADING_ACCOUNT, params).then(response => {
            this.loading = false;
            resolve(response);
          }).catch(error => {
            this.loading = false;
            reject(error);
          })
        })
      },
      getTradingAccountDetail(params = {}, loader = false) {
        return new Promise((resolve, reject) => {
          if (loader) { this.loading = true; }
          API.post(ENDPOINTS.EP_GET_TRADING_ACCOUNT_DETAILS, params).then(response => {
            this.loading = false;
            resolve(response);
          }).catch(error => {
            this.loading = false;
            reject(error);
          })
        })
      },
      changeAccPassword(params = {}, loader = false) {
        return new Promise((resolve, reject) => {
          if (loader) { this.loading = true; }
          API.post(ENDPOINTS.EP_CHANGE_ACCOUNT_PASSWORD, params).then(response => {
            this.loading = false;
            resolve(response);
          }).catch(error => {
            this.loading = false;
            reject(error);
          })
        })
      },
      verifyOtpAccPassword(params = {}, loader = false) {
        return new Promise((resolve, reject) => {
          if (loader) { this.loading = true; }
          API.post(ENDPOINTS.EP_CONFIRM_CHANGE_ACCOUNT_PASSWORD, params).then(response => {
            this.loading = false;
            resolve(response);
          }).catch(error => {
            this.loading = false;
            reject(error);
          })
        })
      },
      resentChangePasswordOtp(params = {}, loader = false) {
        return new Promise((resolve, reject) => {
          if (loader) { this.loading = true; }
          API.post(ENDPOINTS.EP_RESEND_TRADE_ACC_PASSWORD, params).then(response => {
            this.loading = false;
            let type:any = { 'type': 'success' }
            toast('OTP resend successfully', type )
            resolve(response);
          }).catch(error => {
            this.loading = false;
            reject(error);
          })
        })
      },
    /*------------ End -----------*/

    /*----------- deposit-withdrawal -----------*/
      depositRequest(params = {}, loader = false) {
        return new Promise((resolve, reject) => {
          if (loader) { this.loading = true; }
          API.post(ENDPOINTS.EP_DEPOSIT_REQUEST, params).then(response => {
            this.loading = false;
            resolve(response);
          }).catch(error => {
            this.loading = false;
            reject(error);
          })
        })
      },
      getDepositList(params = {}, loader = false) {
        return new Promise((resolve, reject) => {
          if (loader) { this.loading = true; }
          API.post(ENDPOINTS.EP_GET_DEPOSIT_LIST, params).then(response => {
            this.loading = false;
            this.depositList = response;
            resolve(response);
          }).catch(error => {
            this.loading = false;
            reject(error);
          })
        })
      },
      withdrawalRequestOtp(params = {}, loader = false) {
        return new Promise((resolve, reject) => {
          if (loader) { this.loading = true; }
          API.post(ENDPOINTS.EP_WITHDRAWAL_REQUEST_OTP, params).then(response => {
            this.loading = false;
            resolve(response);
          }).catch(error => {
            this.loading = false;
            reject(error);
          })
        })
      },
      withdrawalConfirmOtp(params = {}, loader = false) {
        return new Promise((resolve, reject) => {
          if (loader) { this.loading = true; }
          API.post(ENDPOINTS.EP_WITHDRAWAL_CHECK_OTP, params).then(response => {
            this.loading = false;
            resolve(response);
          }).catch(error => {
            this.loading = false;
            reject(error);
          })
        })
      },
      getWithdrawalList(params = {}, loader = false) {
        return new Promise((resolve, reject) => {
          if (loader) { this.loading = true; }
          API.post(ENDPOINTS.EP_WITHDRAWAL_LIST, params).then(response => {
            this.loading = false;
            this.withdrawalList = response;
            resolve(response);
          }).catch(error => {
            this.loading = false;
            reject(error);
          })
        })
      },
    /*------------ End -----------*/

    /*----------- Feed Posts -----------*/
      getFeeds(params = {}, loader = false, from = 'all') {
        return new Promise((resolve, reject) => {
          if (loader && !( from == 'all'?this.feedsList.totalRecords:this.userFeedsList.totalRecords) ) { this.loading = true; }
          API.post(ENDPOINTS.EP_GET_FEEDS, params).then(response => {
            this.loading = false;
            if(from == 'all'){ this.feedsList = response; }
            else{ this.userFeedsList = response; }
            resolve(response);
          }).catch(error => {
            this.loading = false;
            reject(error);
          })
        })
      },
      getFeedComments(params:any = {}, loader = false, from = 'all') {
        return new Promise((resolve, reject) => {
          if (loader) { this.loading = true; }
          API.post(ENDPOINTS.EP_GET_FEED_COMMENTS, params).then(response => {
            this.loading = false;
            if(from == 'all'){
              let i = this.feedsList.records.map(e => e.trade_id).indexOf(params.trade_id)
              if(i != -1){
                this.feedsList.records[i]['comment'] = response
              }
            }else{
              let i = this.userFeedsList.records.map(e => e.trade_id).indexOf(params.trade_id)
              if(i != -1){
                this.userFeedsList.records[i]['comment'] = response
              }
            }
            resolve(response);
          }).catch(error => {
            this.loading = false;
            reject(error);
          })
        })
      },
      addFeed(params = {}, loader = false, from = 'all') {
        return new Promise((resolve, reject) => {
          if (loader) { this.loading = true; }
          API.post(ENDPOINTS.EP_ADD_FEED, params).then(response => {
            this.loading = false;
            if(response.length > 0){
              if(from == 'all'){
                if(!this.feedsList.records){ this.feedsList['records'] = [] }
                if(!this.feedsList.totalRecords){ this.feedsList['totalRecords'] = 0 }
                this.feedsList.records = response.concat(this.feedsList.records)
                this.feedsList.totalRecords += response.length
                resolve(response);
              }else{
                if(!this.userFeedsList.records){ this.userFeedsList['records'] = [] }
                if(!this.userFeedsList.totalRecords){ this.userFeedsList['totalRecords'] = 0 }
                this.userFeedsList.records = response.concat(this.userFeedsList.records)
                this.userFeedsList.totalRecords += response.length
                resolve(response);
              }
            }
          }).catch(error => {
            this.loading = false;
            reject(error);
          })
        })
      },
      addFeedComment(params:any = {}, loader = false, from = 'all') {
        return new Promise((resolve, reject) => {
          if (loader) { this.loading = true; }
          API.post(ENDPOINTS.EP_ADD_FEED_COMMENT, params).then(response => {
            this.loading = false;
            if(from == 'all'){
              let i = this.feedsList.records.map(e => e.trade_id).indexOf(params.trade_idea_id)
              if(i != -1){
                this.feedsList.records[i]['comment_count'] += 1
              }
            }else{
              let i = this.userFeedsList.records.map(e => e.trade_id).indexOf(params.trade_idea_id)
              if(i != -1){
                this.userFeedsList.records[i]['comment_count'] += 1
              }
            }
            resolve(response);
          }).catch(error => {
            this.loading = false;
            reject(error);
          })
        })
      },
      likeDislikeFeed(params:any = {}, loader = false, from = 'all') {
        return new Promise((resolve, reject) => {
          if (loader) { this.loading = true; }
          if(from == 'all'){
            let i = this.feedsList.records.map(e => e.trade_id).indexOf(params.target_id)
            if(i != -1){
              this.feedsList.records[i]['is_like'] = params.is_like
              this.feedsList.records[i]['likes_count'] += params.is_like?1:-1
            }
          }else{
            let i = this.userFeedsList.records.map(e => e.trade_id).indexOf(params.target_id)
            if(i != -1){
              this.userFeedsList.records[i]['is_like'] = params.is_like
              this.userFeedsList.records[i]['likes_count'] += params.is_like?1:-1
            }
          }
          API.post(ENDPOINTS.EP_LIKE_UNLIKE_FEED_COMMENT, params).then(response => {
            this.loading = false;
            resolve(response);
          }).catch(error => {
            this.loading = false;
            reject(error);
          })
        })
      },
    /*------------ End -----------*/

    /*----------- Top Traders-----------*/
      callTopTradersList(params = {}, loader = false) {
        if (loader) { this.loading = true; }
        return new Promise((resolve, reject) => {
          API.get(ENDPOINTS.EP_GET_TOP_TRADERS,{},false)
            .then((response) => {
              this.loading = false;
              this.topTrdaersList = response;
              resolve(response);
            })
            .catch((error) => {
              this.loading = false;
              reject(error);
            });
        });
      },
    /*------------ End -----------*/

    /*----------- Documents section-----------*/
      getDocList(params = {}, loader = false) {
        if (loader) { this.loading = true; }
        return new Promise((resolve, reject) => {
          API.get(ENDPOINTS.EP_GET_DOCUMENTS,{},false)
            .then((response) => {
              this.loading = false;
              this.docList = response;
              resolve(response);
            })
            .catch((error) => {
              this.loading = false;
              reject(error);
            });
        });
      },
    /*------------ End -----------*/
  }
})

/*Promise.all([getTrades(), getProfile()]).then( response => {
  response[0]
})*/
