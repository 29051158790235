<template>
    <header>
        <div class="header-content header-mobile">
            <div class="header-top container-fluid ">
                <div class="module container width-5">
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="section-title mb-0">
                            <button class="button button-text">
                                <h5 class="d-flex">
                                    <div class="icon mr-3" @click="$router.go(-1)">
                                        <svg width="25" version="1.0" viewBox="0 0 470 342" xmlns="http://www.w3.org/2000/svg">
                                            <g transform="translate(0 342) scale(.1 -.1)">
                                                <path d="m1630 3407c-27-8-212-187-813-786-523-522-784-789-797-817-26-53-26-135 0-188 28-58 1551-1579 1602-1601 52-21 140-19 186 4 106 55 152 182 103 286-13 27-205 225-590 610l-571 570 3795 10 45 25c24 14 58 45 75 68 27 40 30 51 30 122s-3 82-30 122c-17 23-51 54-75 68l-45 25-3795 10 566 565c311 311 573 581 583 600 46 89 33 178-38 250-63 66-140 85-231 57z"></path>
                                            </g>
                                        </svg>
                                    </div> Show Password
                                </h5>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <section id="main-wrapper" style="padding-top: 50px; padding-bottom: 67px;">
        <div class="container-fluid section-small">
            <div class="module container width-5">
                <div class="tr-bo-set set-list">
                    <div>
                        <div class="tr-ac-in-box">
                            <div class="d-flex align-items-center justify-content-between">
                                <h6 class="mb-0">GMUshfgas</h6>
                                <button class="button button-text text-jade-dust">
                                    <div class="icon"><svg width="18" version="1.0" viewBox="0 0 470 512" xmlns="http://www.w3.org/2000/svg">
                                            <g transform="translate(0 512) scale(.1 -.1)">
                                                <path d="m1713 5105c-203-55-365-219-418-422-22-86-22-2880 0-2966 53-203 218-369 421-422 51-13 215-15 1269-15 1051 0 1219 2 1270 15 213 55 385 234 430 450 22 105 22 2805 0 2910-43 205-199 377-397 440l-79 25-1222-1c-1015 0-1231-3-1274-14z"></path>
                                                <path d="m460 4044c-202-43-374-201-436-399l-25-80 6-3120 29-70c61-147 151-246 288-315 42-21 101-43 130-49 37-8 478-11 1498-9l1445 3 70 29c142 59 242 149 310 277 58 111 65 155 65 416v232l-1047 4c-1043 3-1048 3-1132 25-178 47-326 136-452 270-83 88-125 153-174 269-72 167-68 100-72 1381l-4 1152-217-1c-146 0-238-5-282-15z"></path>
                                            </g>
                                        </svg></div>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="section-par par-blue-horizon">
                        <p>keep this passwaord somewhere safe, you will not be able to see it again</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default{};
</script>