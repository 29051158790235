<template>
    <section id="main-wrapper">
        <div class="container-fluid section-small">
            <div class="module container width-5">
                <div class="row-list-mb15">
                    <div class="se-co-box">
                        <ul class="sc-bo-list">
                            <li>
                                <div class="sc-bo-opt">
                                    <div>Lorem ipsum dolor sit</div>
                                    <label class="com_toggle-btn">
                                        <input type="checkbox" name="" value="" checked>
                                        <div class="icon"></div>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div class="sc-bo-opt">
                                    <div>amet, consectetur </div>
                                    <label class="com_toggle-btn">
                                        <input type="checkbox" name="" value="" checked>
                                        <div class="icon"></div>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div class="sc-bo-opt">
                                    <div>adiisicing elit, sed do </div>
                                    <label class="com_toggle-btn">
                                        <input type="checkbox" name="" value="">
                                        <div class="icon"></div>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div class="sc-bo-opt">
                                    <div>eiusmod tempor incididunt </div>
                                    <label class="com_toggle-btn">
                                        <input type="checkbox" name="" value="" checked>
                                        <div class="icon"></div>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div class="sc-bo-opt">
                                    <div>ut labore et dolore magna </div>
                                    <label class="com_toggle-btn">
                                        <input type="checkbox" name="" value="" checked>
                                        <div class="icon"></div>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div class="sc-bo-opt">
                                    <div>aliqua. Ut enim ad minim </div>
                                    <label class="com_toggle-btn">
                                        <input type="checkbox" name="" value="" checked>
                                        <div class="icon"></div>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div class="sc-bo-opt">
                                    <div>veniam, quis nostrud </div>
                                    <label class="com_toggle-btn">
                                        <input type="checkbox" name="" value="" checked>
                                        <div class="icon"></div>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div class="sc-bo-opt">
                                    <div>exercitation ullamco </div>
                                    <label class="com_toggle-btn">
                                        <input type="checkbox" name="" value="" checked>
                                        <div class="icon"></div>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div class="sc-bo-opt">
                                    <div>laboris nisi ut aliquip ex ea</div>
                                    <label class="com_toggle-btn">
                                        <input type="checkbox" name="" value="" checked>
                                        <div class="icon"></div>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div class="sc-bo-opt">
                                    <div>commodo consequat. Duis</div>
                                    <label class="com_toggle-btn">
                                        <input type="checkbox" name="" value="" checked>
                                        <div class="icon"></div>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div class="sc-bo-opt">
                                    <div>aute irure dolor in </div>
                                    <label class="com_toggle-btn">
                                        <input type="checkbox" name="" value="" checked>
                                        <div class="icon"></div>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div class="sc-bo-opt">
                                    <div>reprehenderit in voluptate </div>
                                    <label class="com_toggle-btn">
                                        <input type="checkbox" name="" value="" checked>
                                        <div class="icon"></div>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div class="sc-bo-opt">
                                    <div>velit esse cillum dolore eu </div>
                                    <label class="com_toggle-btn">
                                        <input type="checkbox" name="" value="" checked>
                                        <div class="icon"></div>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div class="sc-bo-opt">
                                    <div>fugiat nulla pariatur. </div>
                                    <label class="com_toggle-btn">
                                        <input type="checkbox" name="" value="" checked>
                                        <div class="icon"></div>
                                    </label>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default{};
</script>