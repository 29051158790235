<template>
    <section id="main-wrapper">
        <div class="container-fluid section-small">
            <div class="module container width-5">
                <div class="section-title mb30">
                    <h5 class="fw-500 co-blue-horizon">Videos</h5>
                </div>
                <ul class="ac-co-list">
                    <li>
                        <a class="ac-ar-set" href="#">
                            <div class="com_vid-thumb thumb-small mb-3">
                                <div class="com_vid-icon icon-medium icon-play">
                                    <div class="one"><i class="fa fa-play"></i></div>
                                    <div class="two"><i class="fa fa-play"></i></div>
                                </div>
                            </div>
                            <div class="section-par">
                                <h6 class="fw-600">Lorem ipsum dolor sit amet, adipisicing elit.</h6>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut l</p>
                                <p>Posted • June 1 2022</p>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a class="ac-ar-set" href="#">
                            <div class="com_vid-thumb thumb-small mb-3">
                                <div class="com_vid-icon icon-medium icon-play">
                                    <div class="one"><i class="fa fa-play"></i></div>
                                    <div class="two"><i class="fa fa-play"></i></div>
                                </div>
                            </div>
                            <div class="section-par">
                                <h6 class="fw-600">Lorem ipsum dolor sit amet, adipisicing elit.</h6>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut l</p>
                                <p>Posted • June 1 2022</p>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        </section>
</template>
<script>
export default{};
</script>