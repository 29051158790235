<template>
    <section id="main-wrapper">
        <div class="container-fluid section-small">
            <div class="module container width-5">
                <div class="section-title mb30">
                    <h5 class="fw-500 co-blue-horizon">Articles</h5>
                </div>
                <ul class="ac-co-list">
                    <li>
                        <a class="ac-ar-set" href="#">
                            <div class="ac-ar-banner banner-small mb-3" style="background-image: url('/assets/img/dummy/banner_academy1.jpg');"></div>
                            <div class="section-par">
                                <h6 class="fw-600">Lorem ipsum dolor sit amet, adipisicing elit.</h6>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut l</p>
                                <p>Posted • June 1 2022</p>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a class="ac-ar-set" href="#">
                            <div class="ac-ar-banner banner-small mb-3" style="background-image: url('/assets/img/dummy/banner_academy2.jpg');"></div>
                            <div class="section-par">
                                <h6 class="fw-600">Lorem ipsum dolor sit amet, adipisicing elit.</h6>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut l</p>
                                <p>Posted • June 1 2022</p>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </section>
</template>
<script>
export default{};
</script>